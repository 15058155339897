import { Box, Button, Stack, Typography } from "@mui/material";
import { icons } from "../../../Assets/AssetHelper";
import EmailTap from "./EmailTap";
import SelectBox from "../../../Components/Atoms/Select/Select";

export default function DeleteAccountForm({ formik }) {

  return (
    <form onSubmit={formik?.handleSubmit} style={{ height: "100%" }}>
      <Stack
        sx={{
          boxShadow: { xs: "none", md: "0px 0px 24px 12px rgba(194, 194, 194, 0.10)" },
          height: "100%",
          py: { xs: 0, md: 5 },
        }}
      >
        <Stack
          sx={{
            width: { xs: "100%", md: "90%" },
            mx: "auto",
            height: "100%",
          }}
          gap={{ xs: 1, md: 5 }}
        >
          <Stack alignItems={{ xs: "start", md: "center" }} gap={1}>
            <Box
              component="img"
              src={icons.DeleteAccount}
              sx={{
                width: { xs: "112px", md: "150px" },
                height: { xs: "112px", md: "150px" },
                fontSize: "25px",
              }}
            />
            <Typography
              sx={{ fontSize: { xs: "20px", md: "32px" }, fontFamily: "Jost", fontWeight: "500" }}
            >
              Delete account
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "24px" },
                lineHeight: { xs: "18px", md: "31px" },
                textAlign: { xs: "start", md: "center" },
                fontWeight: 400,
              }}
            >
              By deleting this account you will lose all your <br />
              information you will not able to use it again this <br /> can&apos;t be undone
            </Typography>
          </Stack>
          <Stack>
          <EmailTap formik={formik}  />
          <Typography sx={{ textAlign: "center", fontSize: { xs: "12px", md: "16px" }, marginY: "25px" }}>
              If you have more than one account with the above credentials and want to delete all or just one of them, please
              select the accounts you want to delete
            </Typography>
            <SelectBox
              items={[
                { id: "1", name: "All Accounts", value: "all" },
                { id: "2", name: "Customer", value: "customer" },
                { id: "3", name: "Dive Club", value: "dive_club" },
                { id: "4", name: "Dive Expert", value: "dive_expert" },
                { id: "5", name: "Dive Center", value: "dive_center" },
              ]}
              name="user_type"
              onchange={formik.handleChange}
              value={formik?.values?.user_type}
              label="Select User"
            />
          </Stack>
          <Button
            variant="contained"
            type="submit"
            disabled={!formik.isValid || !formik.dirty}
            sx={{ bgcolor: "#F6B119", "&:hover": { bgcolor: "#F6B119" }, width: "100%", mt: "auto" }}
          >
            Confirm
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}
