import { Grid, Typography } from "@mui/material";
import ReactCodeInput from "react-verification-code-input";
import Countdown from "react-countdown";

import { images } from "../../../../Assets/AssetHelper";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useEffect, useState } from "react";

const VerificationCode = ({
  t,
  setOtp,
  verifyOtp,
  countdown,
  setCountdown,
  SendOtp,
  type,
  searchParams,
}) => {
  const [data, setData] = useState({ date: Date.now(), delay: 120000 });
  const wantedDelay = 120000;
  // console.log('hello ver code');
  //[START] componentDidMount
  //Code runs only one time after each reloading
  useEffect(() => {
    const savedDate = localStorage.getItem("end_date");
    if (savedDate != null && !isNaN(savedDate)) {
      const currentTime = Date.now();
      const delta = parseInt(savedDate, 10) - currentTime;

      //Do you reach the end?
      if (delta > wantedDelay) {
        //Yes we clear our saved end date
        if (localStorage.getItem("end_date").length > 0)
          localStorage.removeItem("end_date");
      } else {
        //No update the end date
        setData({ date: currentTime, delay: delta });
      }
    }
  }, []);
  //[END] componentDidMount

  useEffect(() => {
    if (countdown) {
      setTimeout(() => {
        setCountdown(false);
      }, 120000);
    }
  }, [countdown]);

  return (
    <>
      {type === "new_account" && (
        <>
          <Grid container item xs={12} justifyContent="center" mb={5} mt={10}>
            <img src={images.VerificationCode} alt="verification" />
          </Grid>
          <Grid container item xs={12} justifyContent="center" mb={2.5}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "30px",
              }}
            >
              {t("Signup.code")}
            </Typography>
          </Grid>
        </>
      )}

      <Grid container item xs={12} flexDirection="column" justifyContent="center" alignItems="center" gap={"5px"} mb={2.5}>
        <Typography sx={{fontWeight: "400", fontSize: "18px", color: "#272726"}}>
          {t("Signup.verification_code_msg")} <br />
        </Typography>
        
        <Typography sx={{fontWeight: "600", fontSize: "18px", color: "#272726"}}>{
          type !== "new_account"
          ? window.location.href.split("email=")?.[1]?.split("&")[0]
          : localStorage.getItem("newAccountEmail")
        }</Typography>
        
      </Grid>

      <Grid container item xs={12} justifyContent="center" mb={4}>
        <ReactCodeInput
          fields={6}
          autoFocus={true}
          className="verif-code"
          onChange={(value) => setOtp(value)}
        />
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "18px",
            color: "#848383",
          }}
        >
          {t("Signup.resend")}
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="center" mb={5}>
        {countdown ? (
          <Countdown
            autoStart={countdown}
            date={data.date + data.delay}
            renderer={({ minutes, seconds, completed }) => {
              if (completed) {
                setCountdown(false);
                return "";
              } else {
                return (
                  <span style={{ color: "#f6b119" }}>
                    {minutes}:{seconds}
                  </span>
                );
              }
            }}
            onStart={() => {
              //Save the end date
              if (localStorage.getItem("end_date") == null)
                localStorage.setItem(
                  "end_date",
                  JSON.stringify(data.date + data.delay)
                );
            }}
            onComplete={() => {
              if (localStorage.getItem("end_date") != null)
                localStorage.removeItem("end_date");
            }}
          />
        ) : (
          <Typography
            style={{ cursor: "pointer", color: "#f6b119" }}
            onClick={() => {
              if (type === "new_account") SendOtp();
              else {
                const email = window.location.href
                  .split("email=")?.[1]
                  ?.split("&");
                SendOtp(email[0], searchParams.get("user_type"));
              }
              setCountdown(true);
              setData({ date: Date.now(), delay: 120000 });
            }}
          >
            {t("Signup.send")}
          </Typography>
        )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={12}
        justifyContent="center"
        alignItems="center"
      >
        <PrimaryButton
          // text={type === "new_account" ? t("Signup.done") : t("Signup.next")}
          text={t("Signup.done")}
          classBtn="orange"
          click={() => {
            if (type === "new_account") verifyOtp();
            else {
              const email = window.location.href
                .split("email=")?.[1]
                ?.split("&");
              verifyOtp(email?.[0], searchParams.get("user_type"));
            }
          }}
          sx={{
            fontSize: {
              xs: "20px",
              md: "24px",
            },
            borderRadius: {
              xs: "100px",
              md: "10px",
            },
            height: {
              xs: "72px",
              md: "72px",
            },
            width: {
              xs: "400px",
              md: "400px",
            },
          }}
        />
      </Grid>
    </>
  );
};

export default VerificationCode;
