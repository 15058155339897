import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ProfileTemplate from "../../../Templates/Main/Profile/Profile.Template.jsx";
import { UpdateProfileAPI, DeleteProfileAPI } from "../../../Contexts/APIs/Profile/Profile";
import { ChangePasswordAPI } from "../../../Contexts/APIs/Auth/Password/ChangePassword";
import { CountryAPI } from "../../../Contexts/APIs/Lookups/Country";
import { CityAPI } from "../../../Contexts/APIs/Lookups/City";
import { AreaAPI } from "../../../Contexts/APIs/Lookups/Area";
import { SocialMediaAPI } from "../../../Contexts/APIs/Lookups/SocialMedia";
import { CurrencyAPI } from "../../../Contexts/APIs/Lookups/Currency";
import { LanguageAPI } from "../../../Contexts/APIs/Lookups/Language";
import { OrganizationsAPI } from "../../../Contexts/APIs/Certificates/Organizations";
import { OrganizationLevelsAPI } from "../../../Contexts/APIs/Certificates/OrganizationLevels";
import { createCertificateAPI } from "../../../Contexts/APIs/Certificates/Certificate";
import AccountDeletedModal from "../../../Components/Organisms/Modals/Profile/AccountDeletedModal.jsx";
import { GetDocumentsAPI } from "../../../Contexts/APIs/Lookups/SystemConfigurations.js";
import { LevelsAPI } from "../../../Contexts/APIs/Lookups/Levels.js";
import { BulkLogoutAPI } from "../../../Contexts/APIs/Auth/BulkLogout.js";
import { PointsActionsAPI, PointsCongigAPI } from "../../../Contexts/APIs/Lookups/Points.js";
import Loader from "../../../Components/Atoms/Loader/Loader.js";
import { Firebase, signOutFirebase } from "../../../Contexts/Firebase/Firebase.js";
import Cookies from "js-cookie";

const ProfilePage = ({
  t,
  language,
  onLanguageHandle,
  setIsAuth,
  languages,
  Logout,
  profileData,
  getProfileData,
  setProfileData,
}) => {
  const navigate = useNavigate();

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [userLanguages, setUserLanguages] = useState([]);
  const [social, setSocial] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [organizationLevels, setOrganizationLevels] = useState([]);
  const [open, setOpen] = useState(false);
  const [covid, setCovid] = useState("");
  
  const [levels, setLevels] = useState([]);
  const [pointsConfig, setPointsConfig] = useState([]);
  const [pointsActions, setPointsActions] = useState([]);
  const [pointsRate, setPointsRate] = useState("");
  const [openLoader, setOpenLoader] = useState(false);

  const handleClose = async () => {
    localStorage.removeItem("firebase_auth_token");
    Cookies.remove("lastFirebaseRequestEpoch");
    localStorage.clear();
    await Firebase.SignoutFirebase();
    localStorage.setItem("USER_TYPE", "guest");
    localStorage.setItem("lang", "en");
    navigate("/");
    setOpen(false);
  };

  /* Profile Functions */

  const deleteProfile = async (data) => {
    const { res, err } = await DeleteProfileAPI(data);
    if (res) setOpen(true);
    if (err) toast.error(err);
  };

  const updateProfile = async (data) => {
    setOpenLoader(true);
    const { res, err } = await UpdateProfileAPI(profileData?.id, data);
    if (res) {
      await setProfileData(res?.data?.user);
      setOpenLoader(false);
      return res;
    }
    if (err) toast.error(err);
    setOpenLoader(false);
  };

  const changePassword = async (data, action) => {
    const { res, err } = await ChangePasswordAPI(data, action);
    if (res) return res?.message;
    if (err) toast.error(err);
  };

  const BulkLogout = async (data) => {
    const { res, err } = await BulkLogoutAPI(data);
    await Firebase.SignoutFirebase();
    localStorage.removeItem("ActiveToken");
    // if (res) toast.success(res?.message)
    if (err) toast.error(err);
  };

  /* Countries */
  const getCountries = async (filter) => {
    const { res, err } = await CountryAPI({
      page_size: 10,
      ...filter,
    });
    if (res) {
      const array = [];
      res?.data?.countries?.forEach((country) => {
        array.push({
          id: country.id,
          name: country.name,
        });
      });
      setCountries(array);
    }
    if (err) toast.error(err);
  };

  /* Levels */
  const getLevels = async () => {
    const { res, err } = await LevelsAPI();
    if (res) setLevels(res?.data?.levels);
    if (err) toast.error(err);
  };

  /* Cities */
  const getCities = async (filter) => {
    const { res, err } = await CityAPI(filter);
    if (res) {
      const array = [];
      res?.data?.cities?.forEach((city) => {
        array.push({
          id: city.id,
          name: city.name,
        });
      });
      setCities(array);
    }
    if (err) toast.error(err);
  };

  /* Areas */
  const getAreas = async (filter) => {
    const { res, err } = await AreaAPI(filter);
    if (res) {
      const array = [];
      res?.data?.areas?.forEach((area) => {
        array.push({
          id: area.id,
          name: area.name,
        });
      });
      setAreas(array);
    }
    if (err) toast.error(err);
  };

  /* Social Media */
  const getSocialMedia = async (filter) => {
    const { res, err } = await SocialMediaAPI(filter);
    if (res) {
      const array = [];
      res?.data?.social_media?.forEach((city) => {
        array.push({
          id: city.id,
          name: city.name,
        });
      });
      setSocial(array);
    }
    if (err) toast.error(err);
  };

  /* Currencies */
  const getCurrencies = async () => {
    const { res, err } = await CurrencyAPI({});
    if (res) {
      const array = [];
      res?.data?.currencies?.forEach((currency) => {
        array.push({
          id: currency.id,
          name: currency.name,
          abbr: currency.lookup_key,
        });
      });
      setCurrencies(array);
    }
    if (err) toast.error(err);
  };

  /* Languages */
  const getLanguages = async (filter) => {
    const { res, err } = await LanguageAPI({
      page_size: 10,
      ...filter,
    });
    if (res) {
      const array = [];
      res?.data?.languages?.forEach((language) => {
        array.push({
          id: language.id,
          name: language.name,
        });
      });
      setUserLanguages(array);
    }
    if (err) toast.error(err);
  };

  /* Certificates Functions */
  const getOrganizations = async (filter) => {
    const { res, err } = await OrganizationsAPI({
      page_size: 10,
      ...filter,
    });
    if (res) {
      const array = [];
      res?.data?.organizations?.forEach((organization) => {
        array.push({
          id: organization.id,
          name: organization.name,
        });
      });
      setOrganizations(array);
    }
    if (err) toast.error(err);
  };

  const getOrganizationLevels = async (filter) => {
    const { res, err } = await OrganizationLevelsAPI(filter);
    if (res) {
      const array = [];
      res?.data?.organization_certificates?.forEach((level) => {
        array.push({
          id: level.id,
          name: level.name,
        });
      });
      setOrganizationLevels(array);
    }
    if (err) toast.error(err);
  };
  const createCertificate = async (data) => {
    setOpenLoader(true);
    const { res, err } = await createCertificateAPI(data);
    if (res) await getProfileData();
    if (err) toast.error(err);
    setOpenLoader(false);
  };

  const editCertificate = async (id, data) => {
    setOpenLoader(true);
    const { res, err } = await UpdateProfileAPI(profileData?.id, {
      user: data,
    });
    if (res) await setProfileData(res?.data?.user);
    if (err) toast.error(err);
    setOpenLoader(false);
  };

  const deleteCertificate = async (id) => {
    setOpenLoader(true);
    const { res, err } = await UpdateProfileAPI(profileData?.id, {
      user: profileData,
      deleted_certificates_ids: [id],
    });
    if (res) await setProfileData(res?.data?.user);
    if (err) toast.error(err);
    setOpenLoader(false);
  };

  /* Documents */
  const getDocuments = async () => {
    const { res, err } = await GetDocumentsAPI({});
    if (res) {
      res?.data?.documents?.forEach((item) => {
        if (item.name === "covid_regulations") setCovid(item.content);
       
      });
    }
    if (err) toast.error(err);
  };

  /* Points */
  const getPointsActions = async () => {
    const { res, err } = await PointsActionsAPI();
    if (res) setPointsActions(res?.data?.point_actions);
    if (err) toast.error(err);
  };

  const getPointsConfig = async () => {
    const { res, err } = await PointsCongigAPI();
    if (res) {
      setPointsConfig(res?.data?.point_configurations);
      setPointsRate(res?.extra?.points_conversion_rate);
    }
    if (err) toast.error(err);
  };

  useEffect(() => {
    getProfileData();
    getDocuments();
  }, []);
  useEffect(() => {
    if (profileData) {
      getCities({
        country_id: profileData?.country_id,
      });
      getAreas({
        city_id: profileData?.city_id,
      });
    }
  }, [profileData]);
  return (
    <>
      <Loader open={openLoader} />
      <ProfileTemplate
        t={t}
        profileData={profileData}
        changePassword={changePassword}
        Logout={Logout}
        deleteProfile={deleteProfile}
        updateProfile={updateProfile}
        getProfileData={getProfileData}
        countries={countries}
        cities={cities}
        organizations={organizations}
        getOrganizationLevels={getOrganizationLevels}
        createCertificate={createCertificate}
        deleteCertificate={deleteCertificate}
        editCertificate={editCertificate}
        language={language}
        onLanguageHandle={onLanguageHandle}
        currencies={currencies}
        languages={languages}
        organizationLevels={organizationLevels}
        setIsAuth={setIsAuth}
        getCities={getCities}
        areas={areas}
        getAreas={getAreas}
        social={social}
        covid={covid}
        levels={levels}
        userLanguages={userLanguages}
        BulkLogout={BulkLogout}
        pointsConfig={pointsConfig}
        pointsActions={pointsActions}
        pointsRate={pointsRate}
        getCountries={getCountries}
        getCurrencies={getCurrencies}
        getLanguages={getLanguages}
        getOrganizations={getOrganizations}
        getSocialMedia={getSocialMedia}
        getLevels={getLevels}
        getPointsActions={getPointsActions}
        getPointsConfig={getPointsConfig}
      />
      <AccountDeletedModal open={open} handleClose={handleClose} />
    </>
  );
};

export default ProfilePage;
