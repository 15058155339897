import { useState } from "react"
import { Grid, Typography } from "@mui/material"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton"

import HowToGetPointsModal from "../../../Organisms/Modals/Profile/HowToGetPointsModal"
// import PointsHistoryModal from "../../../Organisms/Modals/Profile/PointsHistoryModal"
import PointsCard from "./Cards/Pointscard"
import SidePanelDrawer from "../../../Organisms/Profile/ActionSidePanel/SidePanelDrawer";

function PointsMolecule({ t, profileData, levels }) {
    const [howPoints, setHowPoints] = useState(false)
    // const [pointsHistory, setPointsHistory] = useState(false)
    const handleClose = () => {
        setHowPoints(false)
        // setPointsHistory(false)
    }
    
    return (
        <Grid container  mb={3} gap={3}>
            <Grid item xs={12} sm={6.5} md={7.5}
                sx={{
                    padding: '16px',
                    borderRadius: '8px',
                    backgroundImage: profileData?.level?.rank === 3 ? 'linear-gradient(to left, #f3d163 123%, #dfa440 0%)' :
                        profileData?.level?.rank === 4 ? 'linear-gradient(to left, #c2e0f0 123%, #9ac5db 0%)' :
                            profileData?.level?.rank === 2 ? 'linear-gradient(to left, #c8c8c8 121%, #8a8a8a 0%)' :
                                'linear-gradient(to left, #dbb06e 121%, #804a00 0%)'
                }}
            >
                <PointsCard
                    t={t}
                    profileData={profileData}
                    type={profileData?.level?.name}
                />
            </Grid>
            <Grid container item xs={12} sm={4}  md={4}
               
            >
                <Grid container
                    sx={{
                        border: 'solid 1px #f0b70d',
                        borderRadius: '8px',
                        padding: '14px',
                        cursor: 'pointer'
                    }}
                    onClick={() => setHowPoints(true)}
                >
                    <Grid container item xs={8} justifyContent='center'
                        sx={{ margin: 'auto', textAlign: 'center' }}
                    >
                        <Typography component='p'
                            sx={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: 'primary.main'
                            }}
                        >
                            {t("Profile.how_points")}
                        </Typography>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <Typography component='p'
                            sx={{
                                fontSize: '16px',
                                color: 'primary.main'
                            }}
                        >
                            {t("Profile.details")}
                        </Typography>
                        <KeyboardArrowRightIcon sx={{ color: 'primary.main' }} />
                    </Grid>
                </Grid>
                {/* <PrimaryButton
                    text={t("Profile.how_points")}
                    classBtn='points-btn'
                    click={() => setHowPoints(true)}
                /> */}
                {/* <PrimaryButton
                    text={t("Profile.history_points")}
                    classBtn='points-btn'
                    click={() => setPointsHistory(true)}
                /> */}
            </Grid>
            <HowToGetPointsModal
                t={t}
                open={howPoints}
                handleClose={handleClose}
                levels={levels}
            />
       
         
            {/* <PointsHistoryModal
                t={t}
                open={pointsHistory}
                handleClose={handleClose}
                profileData={profileData}
            /> */}
        </Grid>
    )
}

export default PointsMolecule