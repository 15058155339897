import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SuperSignInAPI } from "../../../Contexts/APIs/Auth/Signin";
import { UsersAPI } from "../../../Contexts/APIs/Lookups/Users";
import ChooseAccountTemplate from "../../../Templates/Auth/SuperAdmin/ChooseAccount.Template";

const ChooseAccountPage = () => {
  const [users, setUsers] = useState([]);
  const [keyword, setKeyWord] = useState("");
  const [userId, setUserId] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const getUsers = async (filter) => {
    const { res, err } = await UsersAPI(filter);
    if (res) {
      const array = [];
      res?.data?.forEach((level) => {
        array.push({
          id: level.id,
          name: level.name,
        });
      });
      setUsers(array);
    }
    if (err) toast.error(err);
  };

  useEffect(() => {
    if (searchParams?.get("type"))
      getUsers({ user_type: searchParams?.get("type"), page_size: 10000 });
  }, [searchParams?.get("type")]);

  useEffect(() => {
    if (keyword)
      getUsers({
        user_type: searchParams?.type,
        keyword: keyword,
        page_size: 10000,
      });
  }, [keyword]);

  const handleListItemClick = async (id) => {
    setUserId(id);
  };
  const handelLogin = async () => {
    const { res, err } = await SuperSignInAPI(
      searchParams?.get("type"),
      userId
    );
    if (res) {
      localStorage.setItem("ActiveToken", res?.extra?.access_token);
      localStorage.setItem("ActiveUserId", res?.user?.id);
      localStorage.setItem("ActiveAvatar", res?.user?.avatar);
      localStorage.setItem("ActiveUserType", res?.user?.user_type);
      localStorage.setItem("ActiveEmail", res?.user?.email);
      if (!localStorage.getItem("fcm_token")) localStorage.setItem("fcm_token", window.navigator.userAgent)
      localStorage.setItem("ISVERIFIED", res?.user?.is_verified);
      localStorage.setItem("USER_TYPE", "user");
      navigate("../", { replace: true });
    }
    if (err) toast.error(err);
  };
  return (
    <ChooseAccountTemplate
      userId={userId}
      users={users}
      setUsers={setUsers}
      setKeyWord={setKeyWord}
      keyword={keyword}
      handleListItemClick={handleListItemClick}
      handelLogin={handelLogin}
    />
  );
};

export default ChooseAccountPage;
