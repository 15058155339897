import { useState } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Rating,
  Grid,
  Skeleton,
  Stack,
} from "@mui/material";
import { PanoramaFishEye, Circle } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";

import { icons } from "../../../../Assets/AssetHelper";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { AddFavoriteAPI } from "../../../../Contexts/APIs/Favorites/AddFavorite";
import { DeleteFavoriteAPI } from "../../../../Contexts/APIs/Favorites/DeleteFavorite";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../Organisms/Modals/Profile/ConfirmationModal";
import dayjs from "dayjs";

export default function CarouselCard({
  t,
  item,
  handleClick,
  getFilteredFavorites,
  getFavorites,
  deleteOrder,
  payOrder,
  tapOption,
  isBranchDetails,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [isFavorite, setIsFavorite] = useState(
    item?.is_favourite_by_current_user ||
      item?.favourite?.is_favourite_by_current_user
  );
  
  // cancelDuration should not static(1), should return in response as duration number or new order time with cancelDuration
  // if return duration assign to cancelDuration
  // if return new order time assign to cancelLimit
  const cancelDuration = 1;
  const cancelLimit = dayjs(item.created_at).hour() + cancelDuration;
  
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleCloseConfirm = () => setOpenConfirm(false);
  const { start_date, start_time } = item?.order_item?.purchasable || {};
  const dateTimeString = `${start_date} ${start_time}`;
  const startTime = new Date(dateTimeString);
  // let currentTime = Date.now();
  const [isBookingTimePassed, setIsBookingTimePassed] = useState(false);

  console.log(
    Date.parse("Thu Jan 12 2024 15:05:54 GMT+0200") >=
      Date.parse("Thu Jan 12 2024 15:05:55 GMT+0200"),
    "hagaaar"
  );
  console.log(Date.now(), "currentTime");
  // if (startTime <= currentTime) {
  //   setIsBookingTimePassed(true)
  // } else {
  //   setIsBookingTimePassed(false)
  // }
  const handleFavoriteChange = async () => {
    if (localStorage.getItem("ActiveToken")) {
      if (isFavorite) {
        await DeleteFavoriteAPI({
          favourite: {
            favourite_id: path === "/favorites" ? item?.favourite_id : item?.id,
            favourite_type: "DiveActivity", // DiveActivity, DiveCenter, DiveExpert
          },
        });
        await getFavorites({ page_size: 100 });
        (await getFilteredFavorites) &&
          getFilteredFavorites({ favourite_type: tapOption });

        setIsFavorite(!isFavorite);
      } else {
        await AddFavoriteAPI({
          favourite: {
            favourite_id: item?.id,
            favourite_type: "DiveActivity", // DiveActivity, DiveCenter, DiveExpert
          },
        });
        await getFavorites({ page_size: 100 });
        (await getFilteredFavorites) &&
          getFilteredFavorites({ favourite_type: tapOption });

        setIsFavorite(!isFavorite);
      }
    } else toast.error(t("General.logged_in_to_add_fav"));
  };

  // const submitToCart = async () => {
  //     await addEditCart({
  //         "cart_item": {
  //             "dive_activity_id": item?.order_item?.purchasable?.dive_activity_id,
  //             "start_date": '',
  //             "quantity": item?.quantity
  //         },
  //         "pickup_location": {},
  //         "reservation_participants": []
  //     })
  // }

  return (
    <Card
      style={{
        boxShadow: "none",
        margin: "0 10px",
        position: "relative",
        width: "-webkit-fill-available",
      }}
    >
      {path === "/orders" && (
        <Grid container>
          <Grid item xs={7}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: "bold",
                color: "#212934",
              }}
            >
              {t("Booking.reservation")}
              {item.reservation_number}
            </Typography>
          </Grid>
          <Grid container item xs={5} justifyContent="end">
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: "500",
                color: "#2b2b2b",
              }}
            >
              {item.created_at.slice(0, 10)}
              {` ${t("Booking.at")} `}
              {item.created_at.slice(11, 16)}
            </Typography>
          </Grid>
        </Grid>
      )}
      {(item?.media?.length > 0 && item?.media?.[0]?.url != "url") ||
      item?.order_item?.purchasable?.dive_activity?.media?.length > 0 ||
      item?.favourite?.media?.length > 0 ? (
        <CardMedia
          component="img"
          height="300"
          image={
            item?.media?.[0]?.url ||
            item?.order_item?.purchasable?.dive_activity?.media?.[0]?.url ||
            item?.favourite?.media?.[0]?.url
          }
          alt="Card"
          style={{ borderRadius: "10px" }}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={300}
          sx={{ bgcolor: "grey", borderRadius: "10px" }}
          animation={false}
        />
      )}
      {path !== "/orders" && (
        <img
          src={isFavorite ? icons.FavouriteYes : icons.FavouriteNo}
          alt="favourite"
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={handleFavoriteChange}
        />
      )}
      <CardActionArea
        onClick={() => {
          if (path === "/orders") {
            navigate(`/details-view/${item?.id}`, {
              state: {
                origin: "orders",
                id: item?.id,
                orderItemId: item?.order_item?.id,
                quantity: item?.quantity,
                qrCode: item?.qr_code,
                status: item?.status,
                statusDisplay: item?.status_display,
                lat: item?.order_item?.pickup_location?.lat,
                long: item?.order_item?.pickup_location?.long,
                payment: item?.payment_method?.name,
                paymentLookupkey: item?.payment_method?.lookup_key,
                startDate: item?.order_item?.purchasable?.start_date,
                endDate: item?.order_item?.purchasable?.end_date,
                startTime: item?.order_item?.purchasable?.start_time,
                cancellationPolicyMsg:
                  item?.order_item?.purchasable?.dive_activity
                    ?.cancellation_policy,
              },
            });
          } else if (path === "/favorites") {
            navigate(`/details-view/${item?.favourite_id}`);
          } else {
            navigate(`/details-view/${item?.id}`);
          }
        }}
        sx={{ mb: startTime < Date.now() && 3 }}
      >
        <CardContent sx={{ padding: "0px", paddingTop: "12px" }}>
          {isBranchDetails !== true && (
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", md: "20px" },
                    color: "var(--font-color)",
                  }}
                  gutterBottom
                >
                  {item?.branch?.name ||
                    item?.order_item?.purchasable?.dive_activity?.branch
                      ?.name ||
                    item?.favourite?.branch?.name}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={6}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Rating
                  readOnly
                  value={
                    parseFloat(item?.branch?.avg_rate) ||
                    parseFloat(
                      item?.order_item?.purchasable?.dive_activity?.branch
                        ?.avg_rate
                    ) ||
                    parseFloat(item?.favourite?.avg_rate) ||
                    parseFloat(item?.avg_rate)
                  }
                  sx={{ color: "#3ed181" }}
                  size="small"
                  precision={0.5}
                  icon={<Circle fontSize="inherit" />}
                  emptyIcon={
                    <PanoramaFishEye
                      fontSize="inherit"
                      sx={{ color: "#3ed181" }}
                    />
                  }
                />
                <Typography
                  sx={{
                    paddingInlineStart: "8px",
                    fontSize: 18,
                    color: "#3ed181",
                  }}
                  display="inline"
                >
                  {item?.branch?.reviews_count ||
                    item?.order_item?.purchasable?.dive_activity
                      ?.reviews_count ||
                    item?.favourite?.reviews_count}
                </Typography>
              </Grid>
            </Grid>
          )}
          {path === "/orders" ? (
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 24,
                color: "var(--font-color)",
              }}
            >
              {item?.order_item?.purchasable?.dive_activity?.name}
              {/* {item?.order_item?.purchasable?.dive_activity?.duration_days}{' '}{t("Search.days")}{' Package '}{item?.order_item?.purchasable?.dive_activity?.no_of_dives}{' '}{t("Search.dives")} */}
            </Typography>
          ) : (
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 16, md: 24 },
                color: "var(--font-color)",
              }}
            >
              {/* {item?.duration_days}{' '}{t("Search.days")}{' Package '}{item?.no_of_dives}{' '}{t("Search.dives")} */}
              {item?.name || item?.favourite?.name}
            </Typography>
          )}
          <Grid container mb={2} mt={2}>
            <Grid container item xs={1}>
              <img src={icons.LocationOrangeIcon} alt="location" />
            </Grid>
            <Grid container item xs={5} alignContent="center">
              <Typography sx={{ fontSize: { xs: 12, md: 18 } }} color="#212934">
                {path === "/orders" &&
                item?.order_item?.purchasable?.dive_activity?.diving_location
                  ?.area?.name
                  ? `${item?.order_item?.purchasable?.dive_activity?.diving_location?.area?.name},${item?.order_item?.purchasable?.dive_activity?.diving_location?.country?.name}`
                  : path === "/favorites" &&
                    item?.favourite?.diving_location?.area?.name
                  ? `${item?.favourite?.diving_location?.area?.name},${item?.favourite?.diving_location?.country?.name}`
                  : item?.diving_location?.area?.name
                  ? `${item?.diving_location?.area?.name},${item?.diving_location?.country?.name}`
                  : ""}
              </Typography>
            </Grid>
            {path !== "/orders" ? (
              <>
                <Grid container item xs={1}>
                  <img src={icons.ClockIcon} alt="duration" />
                </Grid>
                <Grid container item xs={5} alignContent="center">
                  <Typography
                    sx={{ fontSize: { xs: 12, md: 18 } }}
                    color="#212934"
                  >
                    {path === "/favorites"
                      ? `${item?.favourite?.duration_days} ${t(
                          "Search.days"
                        )},${item?.favourite?.no_of_dives} ${t("Search.dives")}`
                      : `${item?.duration_days} ${t("Search.days")},${
                          item?.no_of_dives
                        } ${t("Search.dives")}`}
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid container item xs={1}>
                  <img src={icons.Person} alt="duration" />
                </Grid>
                <Grid container item xs={5}>
                  <Typography sx={{ fontSize: 18 }} color="#212934">
                    {item?.order_item?.quantity}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          {item?.activity_type === "live_aboard" && (
            <Grid container mb={2} mt={2}>
              <Grid container item xs={1}>
                <img src={icons.FromTo} alt="from to" />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  display="inline"
                  sx={{
                    fontSize: { xs: 12, md: 18 },
                  }}
                >
                  {t("HomePage.from")}{" "}
                </Typography>
                <Typography
                  display="inline"
                  sx={{
                    fontSize: { xs: 12, md: 18 },
                    fontWeight: "600",
                  }}
                >
                  {item?.start_point}{" "}
                </Typography>
                <Typography
                  display="inline"
                  sx={{
                    fontSize: { xs: 12, md: 18 },
                  }}
                >
                  {t("HomePage.end")}{" "}
                </Typography>
                <Typography
                  display="inline"
                  sx={{
                    fontSize: { xs: 12, md: 18 },
                    fontWeight: "600",
                  }}
                >
                  {item?.end_point}
                </Typography>
              </Grid>
            </Grid>
          )}
          {path !== "/orders" && (
            <>
              <Stack alignItems="center" flexDirection="row">
                <Typography
                  sx={{
                    fontSize: { xs: 18, md: 28 },
                    fontWeight: "500",
                    textTransform: "uppercase",
                  }}
                  display="inline"
                >
                  {path === "/favorites"
                    ? `${item?.favourite?.currency?.lookup_key} `
                    : `${item?.currency?.lookup_key} `}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: 18, md: 28 },
                    fontWeight: "500",
                    paddingInlineEnd: "50px",
                  }}
                  display="inline"
                >
                  {path === "/favorites"
                    ? `${item?.favourite?.price_after_discount} `
                    : `${item?.price_after_discount}`}
                </Typography>
                {(item?.price !== item?.price_after_discount ||
                  item?.favourite?.price !==
                    item?.favourite?.price_after_discount) && (
                  <>
                    <Typography
                      sx={{
                        fontSize: { xs: 12, md: 18 },
                        fontWeight: "500",
                        textTransform: "uppercase",
                        color: "#6e7a8a",
                      }}
                      display="inline"
                    >
                      {path === "/favorites"
                        ? `${item?.favourite?.currency?.lookup_key} `
                        : `${item?.currency?.lookup_key} `}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: 12, md: 18 },
                        fontWeight: "500",
                        color: "#6e7a8a",
                      }}
                      component="del"
                    >
                      {path === "/favorites"
                        ? `${item?.favourite?.price}`
                        : `${item?.price}`}
                    </Typography>

                    <Typography
                      ml={4}
                      sx={{
                        fontSize: { xs: 12, md: "14px" },
                        fontWeight: "500",
                        color: "#f0b70d",
                        borderRadius: "9px",
                        border: { xs: "solid 1px #f0b70d", md: "none" },
                        padding: { xs: "1px 6px", md: "0px" },
                      }}
                    >
                      {t("Details.hotOffer")}{" "}
                      {item?.favourite?.discount_percentage ||
                        item?.discount_percentage}
                      % {t("Details.off")}
                    </Typography>
                  </>
                )}
              </Stack>
              <Typography
                mt={2}
                sx={{ fontSize: { xs: 12, md: 18 }, fontWeight: "500" }}
                color="#3ed181"
              >
                {t("Search.available")}
                {item?.next_available_date ||
                  item?.favourite?.next_available_date}
                {/* {path === '/favorites' && item?.favourite?.activity_groups > 0 ? `${item?.favourite?.activity_groups?.[0]?.start_date}` : ''}
                           {path !== '/favorites' && item?.activity_groups > 0 ? `${item?.activity_groups?.[0]?.start_date}` : ''} */}
              </Typography>
            </>
          )}
          {path === "/orders" && (
            <>
              <Grid container mb={2} mt={2}>
                <Grid container item xs={1}>
                  <img src={icons.ClockIcon} alt="duration" />
                </Grid>
                <Grid container item xs={11} justifyContent="start">
                  <Typography sx={{ fontSize: 18 }} color="#212934">
                    {item?.order_item?.purchasable?.start_date}{" "}
                    {t("Booking.at")}{" "}
                    {item?.order_item?.purchasable?.start_time}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mb={2} mt={2}>
                <Grid container item xs={1}>
                  <img src={icons.Payment} alt="payment" />
                </Grid>
                <Grid container item xs={11} justifyContent="start">
                  <Typography sx={{ fontSize: 18 }} color="#212934">
                    {item?.payment_method?.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mb={2} mt={2}>
                <Grid container item xs={1}>
                  <img
                    src={
                      item?.status === "pending" ||
                      item?.status === "payment_pending"
                        ? icons.WaitingConfirmed
                        : item?.status === "completed" ||
                          item?.status === "checkin_pending" ||
                          item?.status === "checked_in"
                        ? icons.BookingConfirmed
                        : icons.Cancelled
                    }
                    alt="status"
                  />
                </Grid>
                <Grid container item xs={11} justifyContent="start">
                  <Typography
                    sx={{ fontSize: 18, fontWeight: "500" }}
                    color={item?.status_display?.display_color}
                  >
                    {item?.status_display?.display_name}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </CardContent>
      </CardActionArea>
      {/* to display cancel reservation only if time now(dayjs(new Date()).hour()) before cancel time(cancelLimit) */}
      {path === "/orders" &&
        startTime > Date.now() &&
        (item?.status === "pending" || item?.status === "checkin_pending") && (
          <Grid container mb={2}>
            <PrimaryButton
              text={t("Details.cancel")}
              classBtn="cancel-btn"
              click={() => setOpenConfirm(true)}
            />
          </Grid>
        )}
      {path === "/orders" &&
        item?.status === "payment_pending" &&
        startTime > Date.now() && (
          <Grid container mb={2}>
            {item?.payment_method?.lookup_key == "credit_card" && (
              <Grid item xs={6} sx={{ paddingInlineEnd: "3px" }}>
                <PrimaryButton
                  text={t("Booking.pay")}
                  classBtn="pay-btn"
                  click={() => payOrder(item?.id)}
                />
              </Grid>
            )}
            <Grid
              item
              xs={item?.payment_method?.lookup_key == "credit_card" ? 6 : 12}
              sx={{ paddingInlineStart: "3px" }}
            >
              {/* {startTime} */}
              <PrimaryButton
                text={t("Details.cancel")}
                classBtn="cancel-btn"
                click={() => setOpenConfirm(true)}
              />
            </Grid>
          </Grid>
        )}
      {/* {path === '/orders' && item?.status === 'cancelled' &&
                <Grid container mb={2}>
                    <PrimaryButton
                        text={t("Reservation.add")}
                        classBtn='pay-btn'
                    // click={() => handleClick(item?.id, item?.order_item?.id)}
                    />
                </Grid>
            } */}
      {path === "/orders" && item?.status === "completed" && (
        <Grid container mb={2}>
          {!item?.is_reviewed && (
            <PrimaryButton
              text={t("Review.rate_activity")}
              classBtn="book-btn"
              click={() => handleClick(item?.id, item?.order_item?.id)}
            />
          )}
          {/* <Grid item xs={6} sx={{ paddingInlineEnd: '3px' }}>
                        <PrimaryButton
                            text={t("Review.rate_activity")}
                            classBtn='book-btn'
                            click={() => handleClick(item?.id, item?.order_item?.id)}
                        />
                    </Grid>
                    <Grid item xs={6} sx={{ paddingInlineStart: '3px' }}>
                        <PrimaryButton
                            text={t("Booking.book")}
                            classBtn='points-btn'
                        />
                    </Grid> */}
        </Grid>
      )}
      <ConfirmationModal
        t={t}
        open={openConfirm}
        handleClose={handleCloseConfirm}
        item={item?.id}
        action={deleteOrder}
        type="deleteOrder"
        cancellationPolicy={
          item?.order_item?.purchasable?.dive_activity?.cancellation_policy
        }
      />
    </Card>
  );
}
