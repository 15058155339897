import React from "react";
import { Grid } from "@mui/material";
import * as yup from "yup";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";

import "./HelpForms.css";
import SuccessModal from "../../SuccessModal/SuccessModal";
import { useFormik } from "formik";
import {
  addAppComplaint,
  addGeneralComplaint,
  addReservationComplaint,
} from "../../../../Contexts/APIs/Complaints/helpAndSupport";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

function HelpForms({ t, targetPage, userEmail, handleClose }) {
  const validationSchemaObject = yup.object({
    name: yup.string(),
    sender_email: yup
      .string()
      .trim()
      .required(t("validation.email"))
      .email(t("validation.invalid_email")),
    description: yup
      .string()
      .trim()
      .min(11, t("validation.description_short"))
      .required(t("validation.description")),
  });

  const [open, setOpen] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      sender_email: userEmail
        ? userEmail
        : localStorage.getItem("ActiveEmail") || "",
      description: "",
    },
    validationSchema: validationSchemaObject,
    onSubmit: async (values) => {
      const { res, err } =
        targetPage === "GuestFaqs" || targetPage === "CustomerSupport"
          ? await addGeneralComplaint({ complaint: values }, setOpen)
          : await addAppComplaint({ complaint: values }, setOpen);
      if (res) {
        handleClose && handleClose();
        setOpen(true);
      }
      if (err) toast.error(err);
    },
  });

  return (
    <>
      <Grid container item md={12} style={{ margin: "auto" }}>
        <Grid
          style={{ margin: "auto", width: "100%" }}
          className="form-element"
        >
          {targetPage === "GuestFaqs" && (
            <TextFieldInput
              name="name"
              id="name"
              placeholder={t("validation.full_name_placeholder")}
              label={t("validation.full_name_placeholder")}
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              clickEnter={formik.handleSubmit}
              sx={{ backgroundColor: "#f9f9f9", borderRadius: "10px" }}
            />
          )}
          <TextFieldInput
            name="sender_email"
            id="sender_email"
            placeholder={t("validation.email_placeholder")}
            label={t("validation.email_placeholder")}
            type="email"
            onChange={formik.handleChange}
            value={formik.values.sender_email}
            error={
              formik.touched.sender_email && Boolean(formik.errors.sender_email)
            }
            helperText={
              formik.touched.sender_email && formik.errors.sender_email
            }
            clickEnter={formik.handleSubmit}
            sx={{ backgroundColor: "#f9f9f9", borderRadius: "10px" }}
          />
          <TextFieldInput
            name="description"
            id="description"
            placeholder={t("validation.description_placeholder")}
            label={t("validation.description_placeholder")}
            type="text"
            multiline={true}
            rows={4}
            onChange={formik.handleChange}
            value={formik.values.description}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            clickEnter={() => {}}
            sx={{ backgroundColor: "#f9f9f9", borderRadius: "10px" }}
          />

          <PrimaryButton
            text={t("validation.submit")}
            classBtn={`primary ${
              targetPage === "GuestFaqs" && "guest-contact"
            }`}
            style={{ padding: "5px 50px", margin: "auto" }}
            click={formik.handleSubmit}
          />
        </Grid>
      </Grid>
      <SuccessModal
        formik={formik}
        open={open}
        setOpen={setOpen}
        text={t("Profile.successModal")}
      />
    </>
  );
}

export default withTranslation()(HelpForms);
