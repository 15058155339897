import { Divider, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import ConfirmationModal from "../Modals/Profile/ConfirmationModal";
import AccountCard from "../../Molecules/Profile/Settings/AccountCrad";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { icons } from "../../../Assets/AssetHelper";
import "./SwitchAccount.css";
import { Firebase, signOutFirebase } from "../../../Contexts/Firebase/Firebase";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const SwitchAccount = ({ t, Logout, setIsAuth, BulkLogout }) => {
  let accounts = JSON.parse(localStorage.getItem("Accounts"));
  const navigate = useNavigate();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [type, setType] = useState("");
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [helpAnchorEl, setHelpAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  // const openHelp = Boolean(helpAnchorEl);

  const handleCloseConfirm = () => setOpenConfirm(false);

  const handleAccountChange = async (account) => {
    await Firebase.SignoutFirebase();
    localStorage.setItem("firebase_node_path", account.firebase_node_path);
    localStorage.setItem("firebase_auth_token", account.firebase_auth_token);
    localStorage.setItem("ActiveToken", account.token);
    localStorage.setItem("ActiveUserId", account.userId);
    localStorage.setItem("ActiveAvatar", account.avatar);
    localStorage.setItem("ActiveUserType", account.user_type);
    localStorage.setItem("ActiveEmail", account.email);
    localStorage.setItem("ISVERIFIED", account.is_verified);
    window.location.reload();
  };

  const logout = async () => {
    const id = parseInt(localStorage.getItem("ActiveUserId"));
    const newAccounts = accounts.filter((account) => account.userId !== id);

    Logout();
    if (newAccounts.length > 0) {
      localStorage.setItem("Accounts", JSON.stringify(newAccounts));
      handleAccountChange(accounts[0]);
    } else {
      await Firebase.SignoutFirebase();
      localStorage.clear();
      localStorage.setItem("USER_TYPE", "guest");
      localStorage.removeItem("firebase_auth_token");
      Cookies.remove("lastFirebaseRequestEpoch");
      localStorage.setItem("lang", "en");
      setIsAuth(false);
      navigate("/");
    }
  };

  const LogoutAll = async () => {
    const arr = [];
    accounts.forEach((account) => {
      arr.push(account.token);
    });
    BulkLogout({
      tokens: arr,
    });
    // accounts.forEach(account => {
    //     localStorage.setItem("ActiveToken", account.token)
    //     Logout()
    // })
    localStorage.clear();
    await Firebase.SignoutFirebase();
    localStorage.removeItem("firebase_auth_token");
    Cookies.remove("lastFirebaseRequestEpoch");
    localStorage.setItem("USER_TYPE", "guest");
    localStorage.setItem("lang", "en");
    setIsAuth(false);
    navigate("/");
  };
  return (
    <>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          textAlign: "center",
          color: "#272726",
          padding: "16px 20px 20px",
        }}
      >
        {t("Navbar.your_accounts")}
      </Typography>
      {accounts?.map((account) => {
        return (
          <MenuItem key={account.userId} justifyContent={"center !important"}>
            <AccountCard
              t={t}
              profileData={account}
              type="menuItem"
              setType={setType}
              setOpenConfirm={setOpenConfirm}
              handleAccountChange={handleAccountChange}
              currentAccountClass={"current-acount-item"}
              isSwitchAccountPage={true}
            />
          </MenuItem>
        );
      })}
      <Divider sx={{ width: "calc(100% - 32px)", mx: "auto" }} />
      <MenuItem
        onClick={() => {
          navigate("/login");
        }}
      >
        <ListItemIcon>
          <img src={icons.AddAccount} alt="add account" />
        </ListItemIcon>
        <Typography
          sx={{
            color: "var(--text-color)",
            fontSize: "var(--normal-font)",
            fontWeight: "600",
          }}
        >
          {t("Profile.add_account")}
        </Typography>
      </MenuItem>
      <Divider sx={{ width: "calc(100% - 32px)", mx: "auto" }} />
      <MenuItem>
        <PrimaryButton
          text={t("Profile.logout_all")}
          classBtn="points-btn"
          click={() => {
            setType("logout-all");
            setOpenConfirm(true);
          }}
          sx={{ width: "90%", mx: "auto" }}
        />
      </MenuItem>
      <ConfirmationModal
        t={t}
        open={openConfirm}
        handleClose={handleCloseConfirm}
        action={type === "logout-all" ? LogoutAll : logout}
        type={type}
      />
    </>
  );
};

export default withTranslation()(SwitchAccount);
