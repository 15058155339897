import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { withTranslation } from "react-i18next";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { icons } from "../../../../Assets/AssetHelper";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { GoogleRegister } from "../../../../Contexts/APIs/Auth/GoogleAuth";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { signInWithApplePopup } from "../../../../Contexts/Firebase/Firebase";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function CreateAccountOptions({ t, changeChooseAccountOpt }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [isConfirmTerms, setIsConfirmTerms] = useState(false);
  const fcm_token = localStorage.getItem("fcm_token");
  const userAgent = window.navigator.userAgent;

  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
  provider.addScope("https://www.googleapis.com/auth/userinfo.email");
  auth.languageCode = "ar";

  const socialLoginButtonStyle = {
    width: "400px",
    height: "58px",
    fontSize: "22px",
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: "white",
    color: "#272726",
    border: "1px solid #27272666",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    padding: "0px 28px",
    gap: "20px",
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  const checkFirebaseToken = () => {
    if (localStorage.getItem("fcm_token") == null) {
      return toast.error("You must allow browser notifications");
    }
  };

  const handelSocailRegister = async (data, type) => {
    const { res, err } = await GoogleRegister(
      type,
      `${searchParams.get("userType")}`,
      data
    );
    if (res) {
      let array = [];

      const account = {
        token: res.extra.access_token,
        userId: res.user.id,
        avatar: res.user.avatar,
        user_type: res.user.user_type,
        email: res.user.email,
        firstname: res.user.firstname,
        lastname: res.user.lastname,
        is_verified: res.user.is_verified,
      };

      const accounts = JSON.parse(localStorage.getItem("Accounts"));
      if (accounts?.length > 0) {
        const check = accounts?.filter((val) => val?.userId == account?.userId);
        if (check?.length == 0) array.push(account);
      } else {
        array.push(account);
      }

      if (localStorage.getItem("Accounts") === null) {
        localStorage.setItem("Accounts", JSON.stringify(array));
      } else {
        const accounts = JSON.parse(localStorage.getItem("Accounts"));

        accounts.push(...array);
        localStorage.setItem("Accounts", JSON.stringify(accounts));
      }

      if (
        res?.user?.user_type == "customer" ||
        res?.user?.user_type == "dive_club"
      ) {
        navigate("../", { replace: true });
        localStorage.setItem("ActiveToken", res?.extra?.access_token);
        localStorage.setItem("ActiveUserId", res?.user?.id);
        localStorage.setItem("ActiveAvatar", res?.user?.avatar);
        localStorage.setItem("ActiveUserType", res?.user?.user_type);
        localStorage.setItem("ActiveEmail", res?.user?.email);
        localStorage.setItem("ISVERIFIED", res?.user?.is_verified);
        if (!localStorage.getItem("fcm_token"))
          localStorage.setItem("fcm_token", window.navigator.userAgent);
        localStorage.setItem("USER_TYPE", "user");
      } else if (res?.user?.user_type == "super_admin") {
        localStorage.setItem("ActiveToken", res?.extra?.access_token);
        navigate("/choose-account-type", { replace: true });
      }

      window.location.reload();
    }
    if (err) toast.error(err);
  };

  const triggerAppleLoginPopup = async () => {
    const { accessToken, uid } = await signInWithApplePopup();
    const invite_code = localStorage.getItem("invitationCode") || "";

    if (accessToken && uid) {
      // console.log(accessToken, "accessApple");

      const requestData = {
        user: {
          apple_uid: uid,
          id_token: accessToken,
          invitation_code: invite_code,
        },
        device: {
          fcm_token: fcm_token ? fcm_token : userAgent,
        },
      };

      handelSocailRegister(requestData, "apple");
    }
  };
  const googleLogin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.idToken;
        const token = result?._tokenResponse?.idToken;
        const user = result?.user;

        checkFirebaseToken();
        const requestData = {
          user: {
            id_token: token,
            google_id: user?.providerData[0].uid,
          },
          device: {
            fcm_token: fcm_token ? fcm_token : userAgent,
          },
        };

        handelSocailRegister(requestData, "google");
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const checkTermsAndPrivacyPolicySelected = (callBack) => {
    if (!checked) {
      setIsConfirmTerms(true);
      return;
    }
    setIsConfirmTerms(false);
    callBack();
  };
  useEffect(() => {
    checked && setIsConfirmTerms(false);
  }, [checked]);
  return (
    <>
      <Grid
        container
        item
        xs={12}
        flexDirection="column"
        alignItems="center"
        gap={1}
        mb={5}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "500",
          }}
        >
          {t("Login.welcome")}
        </Typography>
        <Typography
          sx={{
            fontSize: "28px",
            fontWeight: "700",
          }}
        >
          {t("Login.create-account-using")}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={3.5}
      >
        <PrimaryButton
          text={t("Signup.create_account_with_email")}
          style={socialLoginButtonStyle}
          startIcon={
            <div
              style={{
                minWidth: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <img src={icons.Avatar} alt="google-icon" />{" "}
            </div>
          }
          click={(_) =>
            checkTermsAndPrivacyPolicySelected(changeChooseAccountOpt)
          }
        />

        <PrimaryButton
          text={t("Signup.continue_using_google")}
          style={socialLoginButtonStyle}
          startIcon={
            <div
              style={{
                minWidth: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <img src={icons.Google} alt="google-icon" />{" "}
            </div>
          }
          click={(_) => checkTermsAndPrivacyPolicySelected(googleLogin)}
        />

        <PrimaryButton
          text={t("Signup.continue_using_apple")}
          style={socialLoginButtonStyle}
          startIcon={
            <div
              style={{
                minWidth: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <img src={icons.AppleIcon} alt="apple-icon" />{" "}
            </div>
          }
          // click={appleLogin}
          click={(_) =>
            checkTermsAndPrivacyPolicySelected(triggerAppleLoginPopup)
          }
        />

        <FormControlLabel
          checked={checked}
          onChange={handleCheckChange}
          control={
            <Checkbox
              style={{
                height: "28px",
                marginRight: "5px",
              }}
              sx={{
                "& svg": {
                  color: isConfirmTerms && "red",
                },
              }}
            />
          }
          label={
            <Typography
              display="inline"
              sx={{
                color: "#272726",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {t("Signup.signup_read")}
            </Typography>
          }
          sx={{
            width: "400px",
            margin: "auto",
            display: "flex",
            alignItems: "flex-start",
          }}
        />
      </Grid>
      {isConfirmTerms && (
        <Grid
          item
          sm={12}
          xs={11}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={3.5}
          mt="20px"
          marginX={"auto"}
        >
          <Stack
            direction="row"
            sx={{ border: "1px solid red", p: 2, borderRadius: "5px" }}
            gap="5px"
          >
            <InfoOutlinedIcon sx={{ color: "red", fontSize: "20px" }} />
            <Typography
              sx={{
                color: "red",
              }}
            >
              {t('Login.privacy-policy-confirmation')}
            </Typography>
          </Stack>
        </Grid>
      )}
    </>
  );
}

export default withTranslation()(CreateAccountOptions);
