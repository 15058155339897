import { Avatar, Badge, Box, Menu, Stack } from "@mui/material";
import React from "react";
import { icons } from "../../../../Assets/AssetHelper";
import { useNavigate } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import NotificationsListing from "../../Notifications/NotificationsListing";
import Language from "../Language";
const AccountActions = ({
  notifCount,
  notificationsCount,
  handleClick,
  handleClose,
  anchorEl,
  cartCount,
  notificationsList,
  setNotificationsList,
  ordersCount,
  profileData,
  open,
  getList,
  favoritesCount,
}) => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      spacing="evenly"
      sx={{
        display: {
          xs: "none",
          md: "inline-flex",
        },
      }}
    >
      <Box
        sx={{
          display: { cursor: "pointer" },
        }}
      >
        <Language />
      </Box>
      <Badge badgeContent={cartCount} color="primary" overlap="circular">
        <Avatar
          sx={{
            width: 28,
            height: 28,
            marginInlineStart: "16px",
            backgroundColor: "#40423f",
            cursor: "pointer",
          }}
          variant="rounded"
          onClick={() => navigate("/cart")}
        >
          <ShoppingCartIcon sx={{ width: "16px" }} />
        </Avatar>
      </Badge>
      <div>
        <Badge
          badgeContent={notificationsCount}
          color="primary"
          overlap="circular"
        >
          <Avatar
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => {
              if (localStorage.getItem("notificationsEnabled") === "true") {
                handleClick(e);
                // getList();
              }
            }}
            sx={{
              width: 28,
              height: 28,
              marginInlineStart: "16px",
              backgroundColor: "#40423f",
              cursor: "pointer",
            }}
            variant="rounded"
          >
            <NotificationsIcon sx={{ width: "16px" }} />
          </Avatar>
        </Badge>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          // MenuListProps={{
          //   "aria-labelledby": "basic-button",
          // }}
          PaperProps={{
            style: {
              width: "485px",
              // height: "329px",
              margin: "0 0.5px 0 0",
              borderRadius: "10px",
              boxShadow: "0 2px 15px 0 rgba(0, 0, 0, 0.1)",
              border: "solid 1px #ececec",
              backgroundColor: "#fff",
            },
          }}
        >
          {/* <Grid container sx={{ padding: "20px", maxWidth: "380px" }}> */}
          {/* <Grid item xs={12} mb={3}>
            <Typography sx={{ fontSize: "28px", fontWeight: "600" }}>
              {t("Navbar.notifications")}
            </Typography>
          </Grid> */}

          {/* use NotificationsListing component here with take consideration differnt head  */}
          <NotificationsListing
            isPopupNotifications={true}
            notificationsList={notificationsList}
            setNotificationsList={setNotificationsList}
            handleClose={handleClose}
            notifCount={notifCount}
            getList={getList}
          />
          {/* </Grid> */}
        </Menu>
      </div>
      <Badge badgeContent={favoritesCount} color="primary" overlap="circular">
        <Avatar
          sx={{
            width: 28,
            height: 28,
            marginInlineStart: "16px",
            backgroundColor: "#40423f",
            cursor: "pointer",
          }}
          variant="rounded"
          onClick={() => navigate("/favorites")}
        >
          <FavoriteIcon sx={{ width: "16px" }} />
        </Avatar>
      </Badge>
      <Badge badgeContent={ordersCount} color="primary" overlap="circular">
        <img
          src={icons.BookingIcon}
          alt="booking"
          style={{ marginInlineStart: "16px", cursor: "pointer" }}
          onClick={() => navigate("/orders")}
        />
      </Badge>
      <Box>
        <Avatar
          src={profileData?.avatar}
          sx={{
            width: 28,
            height: 28,
            cursor: "pointer",
            marginLeft: "16px",
          }}
          onClick={() => navigate("/profile/view-profile")}
        />
      </Box>
    </Stack>
  );
};

export default AccountActions;
