import { Dialog, DialogActions, Divider, Grid, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'
import SelectBox from "../../../Atoms/Select/Select";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useState } from "react";

const AddSocialMediaModal = ({ t, open, handleClose, arrayHelpers, social }) => {
    const [socialType, setSocialType] = useState('');
    const [url, setUrl] = useState('');

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "8px",
                    height: 'auto',
                    width: '400px',
                    padding: '18px'
                }
            }}
        >
            <Grid container>
                <Grid item xs={11}>
                    <Typography display='inline'
                        sx={{ fontSize: '20px', fontWeight: 'bold', color: '#272726' }}
                    >
                        {t("Profile.social")}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
                </Grid>
                <Divider width='100%' />
                <Grid item xs={5}>
                    <SelectBox
                        items={social}
                        className='login-input'
                        style={{ marginTop: '24px' }}
                        value={socialType}
                        onchange={(e) => setSocialType(e.target.value)}
                    />
                </Grid>
                <Grid item xs={7} sx={{ paddingInlineStart: '4px' }}>
                    <TextFieldInput
                        className='login-input'
                        gridClass='grid-input'
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent='center'>
                <PrimaryButton
                    text={t("Profile.add")}
                    classBtn='profile-btn'
                    style={{ marginTop: '32px', backgroundColor: '#f6b119', color: 'white' }}
                    click={() => {
                        arrayHelpers.push({
                            "social_medium_id": socialType,
                            "link": url
                        })
                        handleClose()
                    }}
                />
            </Grid>
        </Dialog>
    );
}

export default AddSocialMediaModal;