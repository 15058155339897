import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { Grid, Rating, Typography, Skeleton } from "@mui/material";
import { PanoramaFishEye, Circle } from "@mui/icons-material";

import { icons } from "../../../Assets/AssetHelper";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { AddFavoriteAPI } from "../../../Contexts/APIs/Favorites/AddFavorite";
import { DeleteFavoriteAPI } from "../../../Contexts/APIs/Favorites/DeleteFavorite";
import { toast } from "react-toastify";
import ConfirmationModal from "../Modals/Profile/ConfirmationModal";
import moment from "moment";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import dayjs from "dayjs";

const CartCard = ({
  t,
  card,
  deleteCart,
  getFavorites,
  orderId,
  cart,
  favorites,
  cardId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [isFavorite, setIsFavorite] = useState(
    card?.is_favourite_by_current_user
  );
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleCloseConfirm = () => setOpenConfirm(false);
  // console.log(card?.id, "card");
  const handleFavoriteChange = async () => {
    if (localStorage.getItem("ActiveToken")) {
      if (isFavorite) {
        await DeleteFavoriteAPI({
          favourite: {
            favourite_id: cardId,
            favourite_type: "DiveActivity", // DiveActivity, DiveCenter, DiveExpert
          },
        });
        getFavorites({ page_size: 100 });
        setIsFavorite(!isFavorite);
      } else {
        await AddFavoriteAPI({
          favourite: {
            favourite_id: cardId,
            favourite_type: "DiveActivity", // DiveActivity, DiveCenter, DiveExpert
          },
        });
        getFavorites({ page_size: 100 });
        setIsFavorite(!isFavorite);
      }
    } else toast.error(t("General.logged_in_to_add_fav"));
  };

  return (
    <Grid container mb={5}>
      <Grid item xs={12} md={4} sx={{ position: "relative" }}>
        {card?.purchasable?.dive_activity?.media?.length > 0 ? (
          <img
            src={card?.purchasable?.dive_activity?.media?.[0]?.url}
            width="100%"
            height="100%"
            alt="search result"
            style={{ borderRadius: "10px", maxHeight: "300px" }}
          />
        ) : (
          <Skeleton
            variant="rectangular"
            style={{ borderRadius: "10px" }}
            width="100%"
            height="100%"
            animation={false}
          />
        )}
        {path !== "/checkout" && (
          <img
            src={isFavorite ? icons.FavouriteYes : icons.FavouriteNo}
            alt="favourite"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={handleFavoriteChange}
          />
        )}
      </Grid>
      <Grid item xs={12} md={8} sx={{ paddingInlineStart: { md: "24px" } }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Typography sx={{ fontSize: 20, padding: "4px 0px" }}>
              {card?.purchasable?.dive_activity?.branch?.name}
            </Typography>
          </Grid>
          {path === "/checkout" && (
            <Grid
              container
              item
              xs={12}
              md={6}
              alignContent="center"
              justifyContent={{ xs: "space-between" }}
            >
              <PrimaryButton
                text={t("Profile.edit")}
                startIcon={
                  <img src={icons.Edit} alt="Edit button" width="15px" />
                }
                style={{
                  color: "#303030",
                  fontSize: "14px",
                }}
                click={() =>
                  navigate(`/edit`, { state: { card, cart, cardId: card?.id } })
                }
              />
              <PrimaryButton
                text={t("Cart.remove")}
                startIcon={
                  <img src={icons.DeleteBtn} alt="delete button" width="15px" />
                }
                style={{
                  color: "#ff5252",
                  fontSize: "14px",
                  fontWeight: "500",
                  // padding:'10px 0px'
                }}
                click={() => setOpenConfirm(true)}
              />
            </Grid>
          )}
          <Grid
            container
            item
            xs={path === "/checkout" ? 12 : 6}
            alignItems="flex-start"
            justifyContent={path === "/checkout" ? "start" : "end"}
          >
            <Rating
              readOnly
              value={parseFloat(
                card?.purchasable?.dive_activity?.branch?.avg_rate
              )}
              sx={{ color: "#3ed181", padding: "4px 0px" }}
              size="small"
              precision={0.5}
              icon={<Circle fontSize="inherit" />}
              emptyIcon={
                <PanoramaFishEye fontSize="inherit" sx={{ color: "#3ed181" }} />
              }
            />
            <Typography
              color="#3ed181"
              sx={{ paddingInlineStart: "8px", fontSize: 18 }}
              display="inline"
            >
              {card?.purchasable?.dive_activity?.reviews_count}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "var(--subtitle-font)",
              color: "var(--font-color)",
            }}
          >
            {card?.purchasable?.dive_activity?.name}
            {/* {card?.purchasable?.duration_days}{' '}{t("Search.days")}{' Package '}{card?.purchasable?.dive_activity?.no_of_dives}{' '}{t("Search.dives")} */}
          </Typography>
        </Grid>
        <Grid container>
          <Typography
            sx={{ fontSize: 28, fontWeight: "500", textTransform: "uppercase" }}
            display="inline"
          >
            {card?.purchasable?.currency?.lookup_key}{" "}
          </Typography>
          <Typography
            sx={{ fontSize: 28, fontWeight: "500", marginInlineStart: "10px" }}
            display="inline"
          >
            {card?.purchasable?.price_after_discount}
          </Typography>

          {card?.purchasable?.price_after_discount !==
            card?.purchasable?.price && (
            <>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: "500",
                  textTransform: "uppercase",
                  color: "#6e7a8a",
                  ml: 2,
                  pt: 1.5,
                }}
                display="inline"
              >
                {card?.purchasable?.currency?.lookup_key}
                <Typography
                  sx={{ fontSize: 18, fontWeight: "500", color: "#6e7a8a" }}
                  component="del"
                >
                  {card?.purchasable?.price}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: "500",
                  color: "#f0b70d",
                  ml: 4,
                  pt: 1.5,
                }}
              >
                {t("Search.hotOffer")} {card?.purchasable?.discount_percentage}%{" "}
                {t("Search.off")}
              </Typography>
            </>
          )}
        </Grid>
        <Grid container mt={2}>
          <img src={icons.CalenderSmall} alt="date" />
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: "500",
              color: "var(--font-color)",
              marginInlineStart: "10px",
            }}
          >
            {t("Details.from")}{" "}
            {dayjs(card?.purchasable?.start_date).format("DD-MM-YYYY")}{" "}
            {t("Details.to")}{" "}
            {dayjs(card?.purchasable?.end_date).format("DD-MM-YYYY")}
          </Typography>
        </Grid>
        <Grid container mb={2} mt={1}>
          <Grid container item xs={5} alignContent="center">
            <img src={icons.ClockSmall} alt="time" />
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "500",
                marginInlineStart: "10px",
              }}
              color="#212934"
            >
              {card?.purchasable?.start_time
                ? card?.purchasable?.start_time
                : "N/A"}
            </Typography>
          </Grid>
          <Grid container item xs={7} alignContent="center">
            <img src={icons.Person} alt="persons" />
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "500",
                marginInlineStart: "10px",
              }}
              color="#212934"
            >
              {/* {card?.purchasable?.reservations_count} */}
              {card?.quantity}
            </Typography>
          </Grid>
        </Grid>
        {path !== "/checkout" && (
          <Grid container>
            <Grid item xs={6} md={6}>
              <PrimaryButton
                text={t("Profile.edit")}
                classBtn="book-btn"
                style={{
                  width: "100%",
                  borderRadius: "4px!important",
                  opacity: card?.error_message && ".5",
                }}
                click={() =>
                  navigate(`/edit`, {
                    state: { card, cart, cardId: card?.id },
                  })
                }
                disabled={card?.error_message ? true : false}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <PrimaryButton
                text={t("Cart.remove")}
                startIcon={
                  <img
                    src={icons.DeleteBtn}
                    alt="delete button"
                    width="18px"
                    height="16px"
                  />
                }
                style={{
                  width: "calc(100% - 15px)",
                  marginLeft: "15px",
                  color: "#ff5252",
                  fontSize: "16px",
                  fontWeight: "500",
                  padding: "10px 25px",
                  borderRadius: "4px",
                  border: "solid 1px #d32030",
                  textTransform: "none",
                }}
                click={() => setOpenConfirm(true)}
              />
            </Grid>
          </Grid>
        )}
        {path === "/checkout" &&
          card?.purchasable?.dive_activity?.need_confirmation && (
            <Grid container xs={12}>
              <img
                src={icons.NeedConfirm}
                alt="confirmation"
                width="20px"
                height="20px"
              />
              <Typography
                sx={{
                  fontSize: { xs: 14, md: 16 },
                  fontWeight: "500",
                  marginInlineStart: "10px",
                  color: "#e2574c",
                }}
              >
                {t("Checkout.confirm")}
              </Typography>
            </Grid>
          )}
        {card?.error_message && (
          <Grid container item xs={12} mt="10px" justifyContent="space-between">
            <PriorityHighRoundedIcon
              sx={{
                width: "15px",
                height: "15px",
                border: "2px solid #e2574c",
                color: "#e2572c",
                borderRadius: "50%",
                marginTop: "1px",
              }}
            />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                marginInlineStart: "10px",
                color: "#e2574c",
                width: "calc(100% - 30px)",
                margin: "0",
              }}
            >
              {card?.error_message}
            </Typography>
          </Grid>
        )}
        {card?.need_more_info && !card?.error_message && (
          <Grid container item xs={12} mt="10px" justifyContent="space-between">
            <PriorityHighRoundedIcon
              sx={{
                width: "15px",
                height: "15px",
                border: "2px solid #e2574c",
                color: "#e2572c",
                borderRadius: "50%",
                marginTop: "1px",
              }}
            />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                marginInlineStart: "10px",
                color: "#e2574c",
                width: "calc(100% - 30px)",
                margin: "0",
              }}
            >
              {t("Cart.complete")}
            </Typography>
          </Grid>
        )}
      </Grid>
      {/* {!card?.have_available_seats && (
        <Grid container item xs={12} mt={1}>
          <img
            src={icons.NeedConfirm}
            alt="confirmation"
            style={{ width: 16, height: 16 }}
          />
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: "500",
              marginInlineStart: "10px",
              color: "#e2574c",
            }}
          >
            {t("Cart.no_seats")}
          </Typography>
        </Grid>
      )} */}
      <ConfirmationModal
        t={t}
        open={openConfirm}
        handleClose={handleCloseConfirm}
        item={orderId}
        action={deleteCart}
        type="deleteCart"
      />
    </Grid>
  );
};

export default CartCard;
