import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Select, { components } from "react-select";
import ClearIcon from "@mui/icons-material/Clear";
import "./Select.css";

const SearchableSelect = ({
  options,
  onChange,
  onClear,
  value,
  label,
  className,
  error,
  id,
  name,
  handelMenuOpen,
  helperText,
  index,
  moreFilterData,
  defaultValues,
  getList,
  count = 10,
  type,
  isMulti = false,
  closeMenuOnSelect = true,
  customOptions,
  dropdownIndicator,
  StartIcon,
}) => {
  const DropdownIndicator = (props) => {
    return dropdownIndicator;
  };

  // Control to add custom start icon in search field
  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      {StartIcon}
      {children}
    </components.Control>
  );

  const colourStyles = {
    controls: (styles) => {
      return {
        ...styles,
        border: 0,
        // This line disable the blue border
        boxShadow: "none",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: "#333132",
        backgroundColor: (isFocused || isSelected) && "#fff2d1",
      };
    },
    menuList: (styles) => {
      return {
        ...styles,
        background: "#ffff",
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        borderRadius: "16px",
        padding: "3px 10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      fontSize: "13px",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
      backgroundColor: "rgba(0, 0, 0, 0.26)",
      borderRadius: "50%",
      width: "18px",
      height: "18px",
      marginLeft: "3px",
      ":hover": {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        color: "white",
      },
    }),
    dropdownIndicator: (styles, { data }) => ({
      ...styles,
      // backgroundColor: 'rgba(0, 0, 0, 0.4)',
    }),
    container: (provided, state) => ({
      ...provided,
      // backgroundColor: 'rgba(0, 0, 0, 0.4)',
    }),
  };

  let filteredItems = options?.filter((data) => data?.id != defaultValues?.id);
  let concatedArr = defaultValues
    ? options
    : filteredItems?.concat(defaultValues);
  const [searchFilterTimeout, setSearchFilterTimeout] = useState(null);

  const handelOptions = concatedArr?.map((val) => {
    return {
      label: val?.name,
      value: val?.id,
      countryName: val?.countryName,
    };
  });

  const ClearIndicator = () => {
    return (
      <IconButton
        onClick={onClear}
        style={{
          position: "absolute",
          top: "calc(50% - 10px)",
          right: "55px",
          padding: "0",
          zIndex: "99",
          width: "20px",
        }}
      >
        <ClearIcon
          sx={{
            color: "hsl(0, 0%, 70%) !important",
            fontSize: "20px !important",
            "&:hover": { color: "hsl(0, 0%, 50%) !important" },
            "&:focus": { color: "hsl(0, 0%, 20%) !important" },
          }}
        />
      </IconButton>
    );
  };
  const handleMenuScrollToBottom = () => {
    if (options?.length < count) {
      getList({
        ...moreFilterData,
        page_number: Math?.floor(options?.length / 10) + 1,
      });
    }
  };

  const handleInputChange = (value, event) => {
    // !read
    /* the below code was causing a bug in search fields 
    The spoken language and location fields are blocked after writing one character.
    check SCU-483
    that's why it's commented for now, as I don't know yet if it will affect another search functionality
    */
    console.log(value, "value");
    if (searchFilterTimeout) {
      clearTimeout(searchFilterTimeout);
    }
    const timeout = setTimeout(() => {
      if (event?.action == "input-change")
        getList({ keyword: value, page_number: 1, ...moreFilterData });
    }, 700);
    setSearchFilterTimeout(timeout);
  };

  const { Option } = components;
  const IconOption = ({ data, isSelected, ...props }) => {
    return (
      <Option {...props}>
        {customOptions ? (
          customOptions(data)
        ) : (
          <Stack direction={"row"} gap="15px" alignItems={"center"}>
            {data.label}
          </Stack>
        )}
      </Option>
    );
  };

  return (
    <>
      <Box
        sx={{
          p: 0,
          m: 0,
          boxShadow: "none",
          border: "none",
          position: "relative",
        }}
      >
        <Select
          isMulti={isMulti}
          id={id}
          name={name}
          onMenuScrollToBottom={handleMenuScrollToBottom}
          className={`${className} ${error && "select-error"}`}
          options={handelOptions}
          components={{
            // Control,
            DropdownIndicator,
            Option: (props) => (
              <IconOption {...props} isSelected={props.data.isSelected} />
            ),
          }}
          onChange={(selectedOptions) => onChange(selectedOptions)}
          value={value}
          placeholder={
            <Typography fontSize="14px" fontWeight={"800"} color="#9d9d9d">
              {label}
            </Typography>
          }
          onInputChange={(value, event) => {
            handleInputChange(value, event);
          }}
          onMenuOpen={() =>
            options?.length == 0 && handelMenuOpen && handelMenuOpen()
          }
          styles={colourStyles}
          loadingMessage={() => <p>loading...</p>}
          closeMenuOnSelect={closeMenuOnSelect}
          //   isClearable={true}
        />
        {value?.label ? <ClearIndicator /> : null}
      </Box>
      {error && (
        <Typography
          component={"p"}
          className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1sb723f-MuiFormHelperText-root"
        >
          {helperText ? helperText : `${label} is Required`}
        </Typography>
      )}
    </>
  );
};
export default SearchableSelect;
