import { IconButton, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { icons } from "../../../../Assets/AssetHelper.js";

import "./DatePicker.css";
import ClearIcon from "@mui/icons-material/Clear";

export default function BasicDatePicker({
  className,
  redBorder,
  errorText,
  value,
  setValue,
  placeholder,
  renderDay,
  maxDate,
  shouldDisableDate,
  shouldDisableMonth,
  shouldDisableYear,
}) {
  const icon = () => <img src={icons.Calender} alt="calender" />;
  return (
    <Stack
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <DatePicker
        value={value}
        maxDate={maxDate}
        onChange={(newValue) => setValue(newValue)}
        label={placeholder}
        inputFormat="DD/MM/YYYY"
        mask="__/__/____"
        components={{
          OpenPickerIcon: icon,
        }}
        renderDay={renderDay}
        shouldDisableDate={shouldDisableDate}
        shouldDisableMonth={shouldDisableMonth}
        disablePast
        openTo={"year"}
        views={["year", "month", "day"]}
        slotProps={{
          field: {
            clearable: true,
            onClear: (e) => {
              setValue(null);
              // setSelectedDate(null);
              e.stopPropagation();
              e.preventDefault();
            },
          },
        }}
      />
      <Stack
        sx={{
          height: "100%",
          position: "absolute",
          right: "2rem",
          padding: "0px",
        }}
        justifyContent={"center"}
        alignItems="center"
      >
        {value && (
          <IconButton
            onClick={() => {
              setValue(null);
              // setSelectedDate(null)
            }}
          >
            <ClearIcon sx={{ color: "rgba(0, 0, 0, 0.4)" }} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
}
