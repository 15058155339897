import { Box, Divider, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";

import { icons } from "../../../Assets/AssetHelper";
import SearchBar from "../../../Components/Molecules/Search/SearchBar";
import CarouselSmallCard from "../../../Components/Molecules/Carousels/Children/CarouselSmallCard";
import CustomPagination from "../../../Components/Atoms/Pagination/Pagination";

const DiveExpertsTemplate = ({
  t,
  diveExperts,
  getDiveExperts,
  locationArray,
  count,
  page,
  handlePageChange,
  getFavorites,
  setCityKeyword,
}) => {
  /* Location filter states */
  const [locationValue, setLocationValue] = useState(""); // for search choosen obj
  const [locationInputValue, setLocationInputValue] = useState(""); // for search textbox input

  useEffect(() => {
    getDiveExperts({ city_id: locationValue?.id });
  }, [locationValue]);

  useEffect(() => {
    setCityKeyword(locationInputValue);
  }, [locationInputValue]);

  return (
    <Grid
      container
      mt={5}
      mb={6}
      sx={{
        width: {
          xs: "100%",
          md: "90%",
        },
        marginX: "auto",
      }}
    >
      <Grid container mb={{ sm: 0, xs: "10px" }} item xs={12}>
        <Grid item sm={9} xs={5}>
          <Typography
            sx={{
              color: "var(--font-color)",
              fontSize: {
                sm: "var(--home-header-font)",
                xs: "var(--xlarge-font)",
              },
              ml: { sm: 0, xs: "10px" },
              fontWeight: "bold",
            }}
          >
            {t("Dive-Experts.experts")}
          </Typography>
        </Grid>
        <Grid item sm={3} xs={7}>
          <SearchBar
            value={locationValue}
            setValue={setLocationValue}
            inputValue={locationInputValue}
            setInputValue={setLocationInputValue}
            items={locationArray}
            text={t("Edit_Trip.search")}
            // getSearchKeywords={getSearchKeywords}
            // className='location-search'
            startIcon={
              <img
                src={icons.LocationOrange}
                style={{
                  color: "#f6b119",
                }}
                alt="search by location"
              />
            }
            endIcon={
              <SearchIcon
                htmlColor="white"
                sx={{
                  backgroundColor: "#f6b119",
                  width: "44px",
                  height: "37px",
                  borderRadius: "3px",
                  cursor: "pointer",
                }}
              />
            }
            sx={{
              ".MuiInputBase-formControl": {
                paddingInlineEnd: "0px",
              },
              ".MuiOutlinedInput-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          />
        </Grid>
      </Grid>
      <Divider sx={{ width: "100%", marginBottom: "27px" }} />
      <Grid container item xs={12}>
        {diveExperts?.map((expert) => {
          return (
            <Grid item md={3} sm={4} xs={12} key={expert?.id}>
              <CarouselSmallCard
                t={t}
                item={expert}
                type="experts"
                getFavorites={getFavorites}
              />
            </Grid>
          );
        })}
      </Grid>
      {count > 12 && (
        <Grid container item xs={12} justifyContent="center">
          <CustomPagination
            count={Math.ceil(count / 12)}
            size="large"
            shape="rounded"
            page={page}
            handleChange={handlePageChange}
            className="pagination"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DiveExpertsTemplate;
