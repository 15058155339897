import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Navbar from "./Components/Organisms/Layouts/Navbar.jsx";
import Footer from "./Components/Organisms/Layouts/Footer.jsx";
import HomePage from "./Pages/Main/Home/Home.Page.jsx";
import SearchPage from "./Pages/Main/Search/Search.Page.jsx";
import LoginPage from "./Pages/Auth/Login.Page.jsx";
import SignupPage from "./Pages/Auth/Signup.Page.jsx";
import ForgetPasswordPage from "./Pages/Auth/ForgetPassword.Page.jsx";
import DetailsViewPage from "./Pages/Main/Details/DetailsView.Page.jsx";
import ProfilePage from "./Pages/Main/Profile/Profile.Page.jsx";
import CartPage from "./Pages/Main/Reservation/Cart.Page.jsx";
import EditDiveTripPage from "./Pages/Main/Reservation/EditDiveTrip.Page.jsx";
import CheckoutPage from "./Pages/Main/Reservation/Checkout.Page.jsx";
import OrdersPage from "./Pages/Main/Reservation/Orders.Page.jsx";
import DiveCentersPage from "./Pages/Main/DiveCenters/DiveCenters.Page.jsx";
import CenterDetailsPage from "./Pages/Main/DiveCenters/CenterDetails.Page.jsx";
import DiveExpertsPage from "./Pages/Main/DiveExperts/DiveExperts.Page.jsx";
import ExpertDetailsPage from "./Pages/Main/DiveExperts/ExpertDetails.Page.jsx";
import FavoritesPage from "./Pages/Main/Favorites/Favorites.Page.jsx";
import PaymentSuccessPage from "./Pages/Main/Reservation/PaymentSuccess.Page.jsx";
import PaymentFailurePage from "./Pages/Main/Reservation/PaymentFailure.Page.jsx";
import BookingPage from "./Pages/Main/Reservation/Booking.Page.jsx";
import AccountTypePage from "./Pages/Auth/AccountType.Page.jsx";
import ChooseAccountTypePage from "./Pages/Auth/SperAdmin/ChooseAccountType.Page.jsx";
import ChooseAccountPage from "./Pages/Auth/SperAdmin/ChooseAccount.Page.jsx";
import { Firebase, auth } from "./Contexts/Firebase/Firebase.js";
import { useEffect, useId, useRef } from "react";
import ChangePasswordSuccess from "./Components/Organisms/Profile/Tabs/ViewProfile/ChangePasswordSuccess.jsx";
import ScrollToTop from "./Contexts/Scroll/ScrollToTop.jsx";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import NotificationsPage from "./Pages/Main/Notifications/Notifications.page.jsx";
import SwitchAccountPage from "./Pages/Main/SwitchAccount/SwitchAccount.page.jsx";
import { SetDeviceFCMAPI } from "./Contexts/APIs/Auth/SetDeviceFCM.js";
import { CreateAuthToken } from "./Contexts/APIs/Auth/createAuthToken.js";
import TermsAndConditions from "./Components/Organisms/Profile/Tabs/TermsAndConditions.jsx";
import PrivacyPolicy from "./Components/Organisms/Profile/Tabs/PrivacyPolicy.jsx";
import Faqs from "./Components/Organisms/Profile/Tabs/Faqs.jsx";
import DeleteAccountPage from "./Pages/Auth/deleteAccount/DeleteAccount.Page.jsx";


const AuthenticatedRoute = ({ element: Component, isAuth, to, ...rest }) => {
  return isAuth ? <Navigate to={to} /> : <Component {...rest} />;
};

function RoutesPage({
  t,
  language,
  onLanguageHandle,
  cart,
  getCart,
  redeem,
  orders,
  getOrders,
  favorites,
  getFavorites,
  mapKey,
  setIsAuth,
  languages,
  deleteCart,
  Logout,
  profileData,
  getProfileData,
  ordersPage,
  setOrdersPage,
  ordersCount,
  ordersOpenLoader,
  setProfileData,
  favoritesCount,
  notificationsCount,
  cartCount,
  ordersPaginationCount,
  openLoader,
  setOpenLoader,
}) {
  const token = localStorage.getItem("ActiveToken");
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const fcm_token = localStorage.getItem("fcm_token");
  const id = useId();

  const createAuthToken = async () => {
    const { res, err } = await CreateAuthToken();
    if (res) {
      localStorage.setItem("firebase_auth_token", res?.data?.firebase_auth_token);
      Firebase.SignInFirebase();
    }
  };

  useEffect(() => {
    // console.log('TEST',token);
    if (token) {
      const user = auth.currentUser;
      if (!user) {
        createAuthToken();
      }
    }
  }, [token]);

  // fcm
  const getFCMToken = async () => {
    const updateNewFCMToken = (newFCMToken) => {
      const new_fcm_token = newFCMToken || id;
      SetDeviceFCMAPI({
        device: {
          fcm_token: new_fcm_token,
        },
      });
      localStorage.setItem("fcm_token", new_fcm_token);
    };

    const newFCMToken = await Firebase.requestForFCMToken();

    if (fcm_token !== newFCMToken) {
      updateNewFCMToken(newFCMToken);
    }
  };

  useEffect(() => {
    if (token) {
      if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted" && !fcm_token && token) {
            getFCMToken();
          } else {
            getFCMToken();
          }
        });
      }
    }
  }, [token]);

  return (
    <ScrollToTop>
      <ToastContainer />
      <Routes>

      <Route path="/delete-account" element={<DeleteAccountPage />} />
        <Route path="/login" element={<LoginPage t={t} setIsAuth={setIsAuth} />} />
        <Route path="/signup" element={<SignupPage t={t} />} />
        <Route path="/forget-password" element={<ForgetPasswordPage t={t} />} />
        <Route path="/account-type" element={<AccountTypePage t={t} />} />
        <Route path="/choose-account-type" element={<ChooseAccountTypePage t={t} />} />
        <Route path="/choose-account" element={<ChooseAccountPage t={t} />} />

        <Route
          path="/*"
          element={
            <Stack justifyContent={"space-between"} p={0} m={0} minHeight={"100vh"}>
              <div>
                <Navbar
                  t={t}
                  cart={cart}
                  orders={orders}
                  favorites={favorites}
                  onLanguageHandle={onLanguageHandle}
                  languages={languages}
                  language={language}
                  Logout={Logout}
                  setIsAuth={setIsAuth}
                  profileData={profileData}
                  getProfileData={getProfileData}
                  favoritesCount={favoritesCount}
                  ordersCount={ordersCount}
                  cartCount={cartCount}
                  notificationsCount={notificationsCount}
                />
                <Routes>
                  <Route index element={<HomePage t={t} getFavorites={getFavorites} />} />
                  <Route path="/search/:type" element={<SearchPage t={t} getFavorites={getFavorites} />} />
                  <Route path="change-password-success" element={<ChangePasswordSuccess />} />
                  <Route path="/terms" element={<AuthenticatedRoute isAuth={token} to="/profile/terms" element={TermsAndConditions} />} />
                  <Route path="/privacy" element={<AuthenticatedRoute isAuth={token} to="/profile/privacy" element={PrivacyPolicy} />} />
                  <Route path="/faq" element={<AuthenticatedRoute isAuth={token} to="/profile/faq" element={Faqs} />} />

                  <Route
                    path="/details-view/:id"
                    element={<DetailsViewPage t={t} getFavorites={getFavorites} mapKey={mapKey} />}
                  />
                  <Route
                    path="/profile/*"
                    element={
                      token ? (
                        <ProfilePage
                          t={t}
                          onLanguageHandle={onLanguageHandle}
                          language={language}
                          setIsAuth={setIsAuth}
                          languages={languages}
                          Logout={Logout}
                          profileData={profileData}
                          getProfileData={getProfileData}
                          setProfileData={setProfileData}
                        />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                  <Route
                    path="/cart"
                    element={
                      token ? (
                        <CartPage
                          t={t}
                          cart={cart}
                          getCart={getCart}
                          redeem={redeem}
                          getFavorites={getFavorites}
                          deleteCart={deleteCart}
                          favorites={favorites}
                          openLoader={openLoader}
                          setOpenLoader={setOpenLoader}
                        />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                  <Route
                    path="/edit"
                    element={
                      token ? (
                        <EditDiveTripPage t={t} mapKey={mapKey} getCart={getCart} cart={cart} openLoader={openLoader} />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                  <Route
                    path="/orders"
                    element={
                      token ? (
                        <OrdersPage
                          t={t}
                          orders={orders}
                          getOrders={getOrders}
                          ordersPage={ordersPage}
                          setOrdersPage={setOrdersPage}
                          ordersCount={ordersCount}
                          ordersOpenLoader={ordersOpenLoader}
                          ordersPaginationCount={ordersPaginationCount}
                        />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                  <Route path="/bookings" element={token ? <BookingPage t={t} /> : <Navigate to="/" replace />} />
                  <Route
                    path="/checkout"
                    element={
                      token ? (
                        <CheckoutPage t={t} cart={cart} getCart={getCart} redeem={redeem} deleteCart={deleteCart} />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                  <Route path="/dive-centers" element={<DiveCentersPage t={t} getFavorites={getFavorites} />} />
                  <Route path="/dive-centers/:id" element={<CenterDetailsPage t={t} getFavorites={getFavorites} />} />
                  <Route path="/dive-experts" element={<DiveExpertsPage t={t} getFavorites={getFavorites} />} />
                  <Route path="/dive-experts/:id" element={<ExpertDetailsPage t={t} getFavorites={getFavorites} />} />
                  <Route
                    path="/favorites"
                    element={
                      token ? (
                        <FavoritesPage t={t} getFavorites={getFavorites} favorites={favorites} />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                  <Route path="/payment-success" element={token ? <PaymentSuccessPage t={t} /> : <Navigate to="/" replace />} />
                  <Route path="/payment-failure" element={token ? <PaymentFailurePage t={t} /> : <Navigate to="/" replace />} />

                  <Route
                    path="/notifications"
                    element={token && isMd ? <NotificationsPage t={t} /> : <Navigate to="/" replace />}
                  />

                  <Route
                    path="/switch-account"
                    element={token && isMd ? <SwitchAccountPage setIsAuth={setIsAuth} /> : <Navigate to="/" replace />}
                  />
                </Routes>
              </div>
              <Footer t={t} />
            </Stack>
          }
        />
      </Routes>
    </ScrollToTop>
  );
}

export default RoutesPage;
