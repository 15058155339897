import { Box, Divider, Grid, MenuItem, Stack, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { icons } from "../../../Assets/AssetHelper";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import CircleIcon from "@mui/icons-material/Circle";

const NotificationsListing = ({
  t,
  isPopupNotifications,
  notificationsList,
  handleClose,
  notifCount,
  getList,
  setNotificationsList,
}) => {
  const notificationIcon = (type) => {
    const notifications = {
      dive_trip: icons.notificationDiveTrip,
      dive_course: icons.notificationDiveCourse,
      liveaboard: icons.notificationLiveaboard,
      order: icons.Reservation,
      points: icons.PointsNotification,
      invitation: icons.InvitationNotification,
      levels: icons.LevelNotification,
    };
    return notifications[type] ?? icons.notificationIcon;
  };

  const setIsSeenTrue = (id) => {
    const updatedNotifications = notificationsList.map((notification) => {
      if (notification?.id == id) {
        return { ...notification, is_seen: true };
      } else {
        return notification;
      }
    });
    setNotificationsList(updatedNotifications);
  };

  const handleClick = (note) => {
    handleClose(note);
    setIsSeenTrue(note?.id);
  };

  return (
    <>
      {isPopupNotifications ? (
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "left",
            color: "#333132",
            padding: "16px 20px 10px",
          }}
        >
          {" "}
          {t("Navbar.notifications")}{" "}
        </Typography>
      ) : (
        <Grid container item xs={12} mb={"0px !important"} p={"20px 20px 10px"}>
          <img src={icons.notificationOrange} alt="" style={{ marginInlineEnd: "10px" }} />
          <Typography
            display="inline"
            sx={{
              color: "var(--font-color)",
              fontSize: { xs: "20px", md: "var(--home-header-font)" },
              fontWeight: "bold",
            }}
          >
            {t("Navbar.notifications")}
          </Typography>
        </Grid>
      )}
      <InfiniteScroll
        dataLength={notificationsList?.length} //This is important field to render the next data
        next={getList}
        hasMore={notifCount != notificationsList?.length}
        height={600}
        // below props only if you need pull down functionality
      >
        {notificationsList?.map((note, i) => {
          return (
            <MenuItem
              key={i}
              sx={{
                p: "0px 20px",
                flexDirection: "column",
                cursor: "default",
              }}
              onClick={() => {
                handleClick(note);
              }}
            >
              <Stack p="22px 0px 15px" width="100%">
                <Stack flexDirection="row" alignItems="center" gap={2}>
                  <Stack width="48px" height="48px" borderRadius="50%" alignItems="center" justifyContent="center">
                    <Box as="img" src={notificationIcon(note?.notification_type)} width="48px" height="48px" />
                  </Stack>

                  <Typography sx={{ textWrap: "wrap" }} fontSize="18px" fontWeight="bold">
                    {note?.title}
                  </Typography>
                </Stack>
                <Typography alignSelf="end" color="#bfbfbf" fontSize="14px">
                  {`${
                    moment(new Date()).diff(moment(note?.sent_at), "days") === 0
                      ? `Today ${moment(note?.sent_at).format("hh:mm A")}`
                      : moment(new Date()).diff(moment(note?.sent_at), "days") === 1
                      ? `Yesterday ${moment(note?.sent_at).format("hh:mm A")}`
                      : `${moment(note?.sent_at).fromNow()} ${moment(note?.sent_at).format("hh:mm A")}`
                  }`}
                </Typography>
                {!note?.is_seen && <CircleIcon sx={{ fontSize: "small", color: "orange", position: "absolute", right: 3 }} />}
              </Stack>
              {i !== notificationsList.length - 1 && <Divider style={{ width: "100%" }} />}
            </MenuItem>
          );
        })}
      </InfiniteScroll>
    </>
  );
};

export default withTranslation()(NotificationsListing);
