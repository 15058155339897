import {
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { withTranslation } from "react-i18next";

const SearchSelect = ({
  className,
  value,
  onchange,
  title,
  items,
  onclick = () => {},
  name,
  label,
  id,
  style,
  IconComponent,
  t,
  getList,
  moreFilterData,
  defaultValues,
}) => {
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const timeId = setTimeout(() => {
      getList && getList({ keyword: searchText, ...moreFilterData });
    }, 700);

    return () => clearTimeout(timeId);
  }, [searchText]);
  let filteredItems = items?.filter((data) => data?.id != defaultValues?.id);
  let concatedArr = filteredItems?.concat(defaultValues);
  return (
    <FormControl style={style} fullWidth>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        onOpen={() => getList && getList(moreFilterData)}
        className={className}
        name={name}
        value={value}
        MenuProps={{ autoFocus: false }}
        labelId="search-select-label"
        id="search-select"
        onChange={onchange}
        IconComponent={IconComponent}
      >
        <ListSubheader>
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            placeholder={t("General.type_search")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        <MenuItem value="" disabled style={{ direction: "ltr" }}>
          {title}
        </MenuItem>
        {concatedArr?.map((item) => (
          <MenuItem
            value={item?.id}
            key={item?.id}
            onClick={() => onclick(item?.id)}
            style={{ direction: "ltr" }}
          >
            {item?.icon ? (
              <img
                style={{ margin: "0 10px" }}
                src={item.icon}
                alt="item icon"
              />
            ) : (
              ""
            )}{" "}
            {item?.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withTranslation()(SearchSelect);
