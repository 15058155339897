import { useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

import { images, icons } from "../../../Assets/AssetHelper.js";
import SearchBar from "../../../Components/Molecules/Search/SearchBar";
import CarouselOrganism from "../../../Components/Organisms/Carousels/CarouselOrganism";
import { withTranslation } from "react-i18next";
import "./HomeTemplate.css";

const HomeTemplate = ({
  t,
  diveActivities,
  getBranches,
  getLocations,
  getHotOffers,
  getSponsersList,
  search,
  getFavorites,
}) => {
  // useEffect(() => {
  //   window.scrollTo(0, 0); // Scroll to the top of the page
  // }, []);
  const activities = [
    {
      text: t("HomePage.dive_trips"),
      image: images.Dive,
      name: "dive_trip",
    },
    {
      text: t("HomePage.courses"),
      image: images.Courses,
      name: "dive_course",
    },
    {
      text: t("HomePage.liveboard"),
      image: images.Liveboard,
      name: "live_aboard",
    },
  ];
  const benefits = [
    {
      text: t("HomePage.support"),
      image: images.Support,
    },
    {
      text: t("HomePage.booking"),
      image: images.Booking,
    },
    {
      text: t("HomePage.activities"),
      image: images.Activities,
    },
  ];

  const navigate = useNavigate();
  /* search bar states */
  const [searchArray, setsearchArray] = useState([]); // array passed to search
  const [value, setValue] = useState(null); // for search choosen obj
  const [inputValue, setInputValue] = useState(""); // for search textbox input
  /* carousels states */
  const [topRatedBranches, setTopRatedBranches] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [hotoffers, setHotoffers] = useState([]);
  const [diveTrips, setDiveTrips] = useState([]);
  const [courses, setCourses] = useState([]);
  const [liveaboards, setLiveaboards] = useState([]);
  const [sponsers, setSponsers] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState(images.HomePageBg1x);
  const [headerText, setHeaderText] = useState(t("HomePage.discover"));

  /* Sponsors carousel */ /*
  // targetUl to get sponsors carousel ul to add transition after render
  const targetUl = document.querySelector(".sponsers-carousel ul.react-multi-carousel-track");
  targetUl && targetUl.classList.add('addTrans');  
  
  // customArrowStyle for sponsors carousel custom arrows styling
  const customArrowStyle = {
    position: "absolute",
    outline: "0",
    transition: "all .5s",
    borderRadius: "35px",
    zIndex: "1000",
    minWidth: "43px",
    minHeight: "43px",
    opacity: "1",
    cursor: "pointer"
  };
    
  // handleClick for sponsors carousel arrows to toggle transition classes
  const handleClick = () => {
    const targetUl = document.querySelector(".sponsers-carousel ul.react-multi-carousel-track");
    if(targetUl.classList.contains('addTrans')){
      targetUl.classList.remove('addTrans');
      targetUl.classList.add('removeTrans');
      setTimeout(() => {
        targetUl.classList.remove('removeTrans');
        targetUl.classList.add('addTrans');
      }, 3000);
    }else{
      targetUl.classList.add('removeTrans');
      setTimeout(() => {
        targetUl.classList.remove('removeTrans');
        targetUl.classList.add('addTrans');
      }, 3000);
    }
  }
  
  // CustomRightArrow, CustomLeftArrow are a custom arrows for sponsors carousel
  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;    
    return <button aria-label="Go to next slide"
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      onClick={() => handleClick()} 
      style={{...customArrowStyle, right: "3.5%"}}
    />;
  };
  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;    
    return <button aria-label="Go to previous slide"
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      onClick={() => handleClick()} 
      style={{...customArrowStyle, left: "3.5%"}}
    />;
  };
  */ /* Sponsors carousel */

  const getTopRatedBranches = async (area_id = "") => {
    const res = await getBranches({
      area_id: area_id,
      status: "approved",
      sort_by: "rating_high",
    });
    setTopRatedBranches(res);
  };

  const getDiveLocations = async (city_id = "") => {
    const res = await getLocations(
      {}
      // {'area_id': city_id}
    );
    setDestinations(res);
  };

  const getHotOffersList = async () => {
    const res = await getHotOffers();
    setHotoffers(res);
  };

  const getDiveTrips = async (area_id = "") => {
    const res = await diveActivities({
      activity_type: "dive_trip",
      area_id: area_id,
    });
    setDiveTrips(res);
  };

  const getCourses = async (area_id = "") => {
    const res = await diveActivities({
      activity_type: "dive_course",
      area_id: area_id,
    });
    setCourses(res);
  };

  const getLiveaboards = async (area_id = "") => {
    const res = await diveActivities({
      activity_type: "live_aboard",
      area_id: area_id,
    });
    setLiveaboards(res);
  };

  const getSponsers = async (area_id = "") => {
    const res = await getSponsersList();
    setSponsers(res);
  };

  const callSearch = async (val = "") => {
    const res = await search({
      keyword: val,
    });
    const array = [];
    res?.map((item) => {
      if (item?.type === "area") {
        item?.areas?.length > 0 &&
          item?.areas?.map((city) => {
            array.push({
              type: item?.type,
              id: city?.id,
              mainTitle: city?.name,
              subTitle: city?.city?.country?.name,
              logo: city?.logo,
            });
          });
        // } else if (item?.type === 'location') {
        //     item?.locations?.length > 0 && item?.locations?.map((location) => {
        //         array.push({
        //             type: item?.type,
        //             mainTitle: location?.name,
        //             subTitle: location?.city?.name
        //         })
        //     })
      } else if (item?.type === "branch") {
        item?.branches?.length > 0 &&
          item?.branches?.map((center) => {
            array.push({
              type: item?.type,
              id: center?.id,
              mainTitle: center?.name,
              subTitle: t("Details.center"),
            });
          });
      } else {
        item?.dive_experts?.length > 0 &&
          item?.dive_experts?.map((freelancer) => {
            array.push({
              type: item?.type,
              id: freelancer?.id,
              mainTitle: freelancer?.name,
              subTitle: t("General.dive_expert"),
            });
          });
      }
    });
    setsearchArray(array);
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      inputValue &&
      callSearch(inputValue);            
    }, 500);    
    return () => clearTimeout(timeId);
  }, [inputValue]);
  useEffect(() => {
    if (value?.type === "area") {
      setBackgroundImage(value?.logo);
      setHeaderText(`${value?.mainTitle} ,${value?.subTitle}`);
      getTopRatedBranches(value.id);
      // getDiveLocations(value.id)
      getDiveTrips(value.id);
      getCourses(value.id);
      getLiveaboards(value.id);
    } else if (value === null) {
      setBackgroundImage(images.HomePageBg1x);
      setHeaderText(t("HomePage.discover"));
      getTopRatedBranches();
      getDiveLocations();
      getHotOffersList();
      getDiveTrips();
      getCourses();
      getLiveaboards();
    } else if (value.type === "branch") {
      navigate(`/dive-centers/${value?.id}`);
      setValue(null);
      setInputValue("");
    } else if (value.type) {
      navigate(`/dive-experts/${value?.id}`);
      setValue(null);
      setInputValue("");
    }
  }, [value]);
  useEffect(() => {
    getSponsers();
  }, []);
  return (
    <>
      {/* Search Bar */}
      <Grid
        container
        sx={{
          backgroundImage: {
            xs: `url(${backgroundImage})`,
            // xl: `url(${images.HomePageBg2x})`
          },
          height: {
            xs: "374px",
            md: "574px",
            xl: "670px",
          },
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        alignContent="center"
        justifyContent="center"
      >
        <Grid
          container
          item
          xs={12}
          mb={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            width={{ xs: "31px", md: "40px" }}
            height={{ xs: "33px", md: "45px" }}
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={icons.ScubersIcon2x}
              srcSet={`${icons.ScubersIcon} 600w, ${icons.ScubersIcon2x} 1400w`}
              alt="Scubers Icon"
              width={"100% !important"}
              height={"100% !important"}
            />
          </Grid>
          <Typography
            color="white"
            sx={{
              fontSize: {
                xs: "14px",
                md: "32px",
              },
              fontWeight: "bold",
              // marginInlineStart: "15px",
              marginInlineStart: { xs: "10px", md: "20px" },
              textShadow: `0 3px 6px rgba(0, 0, 0, 0.5)`,
            }}
            display="inline"
          >
            {t("HomePage.welcome")}
          </Typography>
        </Grid>
        <Grid container item xs={12} mb={6} justifyContent="center">
          <Typography
            color="white"
            sx={{
              fontWeight: "bold",
              textShadow: `0 4px 8px rgba(0, 0, 0, 0.25)`,
              fontSize: {
                xs: "28px",
                md: "64px",
              },
            }}
          >
            {headerText}
          </Typography>
        </Grid>
        <SearchBar
          value={value}
          setValue={setValue}
          inputValue={inputValue}
          setInputValue={setInputValue}
          items={searchArray}
          text={t("HomePage.search_placeholder")}
          className="search-input home-search-input"
          justifyContent="center"
          startIcon={
            <SearchIcon
              htmlColor="#f6b119"
              sx={{
                marginInlineStart: "10px",
              }}
            />
          }
        />
      </Grid>
      {/* Activities Bar */}
      <Grid
        container
        mt={10}
        mb={10}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography
          sx={{
            fontWeight: "bold",
            marginBottom: "20px",
            fontSize: { xs: "16px", md: "38px" },
          }}
        >
          {t("HomePage.explore")}
        </Typography>
        <Grid
          container
          width={{ xs: "90%", md: "85%", lg: "90%" }}
          gap={2}
          justifyContent="center"
          alignItems="center"
        >
          {activities.map((activity, i) => (
            <Grid container item xs={3.5} key={i} justifyContent="center">
              <Grid
                container
                sx={{
                  width: { xs: "100%", md: "300px" },
                  height: { xs: "110px", md: "100px" },
                  border: "2px solid #f6b119",
                  borderRadius: "12px",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/search/${activity.name}`)}
              >
                <Grid
                  container
                  item
                  xs={12}
                  md={5}
                  justifyContent="center"
                  alignContent="center"
                >
                  <img
                    src={activity.image}
                    className="explore-activities-image"
                    alt="activity"
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={7}
                  justifyContent={{ xs: "center", md: "flex-start" }}
                  alignContent="center"
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "14px", md: "24px" },
                    }}
                  >
                    {activity.text}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/* Top Diving Destinations Carousel */}
      {destinations?.length > 0 && value?.type !== "area" && (
        <CarouselOrganism
          t={t}
          type="image"
          items={destinations}
          heading={t("HomePage.destinations")}
          description={t("HomePage.destinations_description")}
          width="90%"
          navigateParam={{ state: { searchHotOffers: false } }}
          customCarouselClass={"destinations-carousel"}
        />
      )}
      {/* Hot Offers Carousel */}
      {hotoffers?.length > 0 && (
        <CarouselOrganism
          t={t}
          type="card"
          items={hotoffers}
          heading={t("HomePage.hot_offers")}
          description={t("HomePage.hot_offers_description")}
          width="90%"
          getFavorites={getFavorites}
          activity_type="dive_trip"
          navigateParam={{ state: { searchHotOffers: true } }}
          customCarouselClass={"hotoffers-carousel"}
        />
      )}

      {/* Popular Diving Trips Carousel */}
      {diveTrips?.length > 0 && (
        <CarouselOrganism
          t={t}
          type="card"
          items={diveTrips}
          heading={t("HomePage.trips")}
          description={t("HomePage.trips_description")}
          width="90%"
          getFavorites={getFavorites}
          activity_type="dive_trip"
          navigateParam={{ state: { searchHotOffers: false } }}
          customCarouselClass={"diveTrips-carousel"}
        />
      )}
      {/* Top Rated Diving Centers Carousel */}
      {topRatedBranches?.length > 0 && (
        <CarouselOrganism
          t={t}
          type="smallCard"
          items={topRatedBranches}
          heading={t("HomePage.centers")}
          description={t("HomePage.centers_description")}
          width="90%"
          getFavorites={getFavorites}
          activity_type="dive-centers"
          navigateParam={{ state: { searchHotOffers: false } }}
          customCarouselClass={"topRatedBranches-carousel"}
        />
      )}
      {/* Popular Courses Carousel */}
      {courses?.length > 0 && (
        <CarouselOrganism
          t={t}
          type="card"
          items={courses}
          heading={t("HomePage.popular_courses")}
          description={t("HomePage.courses_description")}
          width="90%"
          getFavorites={getFavorites}
          activity_type="dive_course"
          navigateParam={{ state: { searchHotOffers: false } }}
          customCarouselClass={"courses-carousel"}
        />
      )}
      {/* Benifits Bar */}
      <Grid
        container
        mt={{ xs: 5, md: 10 }}
        mb={{ xs: 5, md: 10 }}
        bgcolor="rgba(236, 236, 236, 0.56)"
        height="120px"
      >
        {benefits.map((activity, i) => (
          <Grid container item xs={4} key={i}>
            <Grid
              container
              item
              xs={12}
              md={4}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignContent="center"
              sx={{ paddingInlineEnd: { xs: "0px", md: "24px" } }}
            >
              <img src={activity.image} alt="activity" />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={8}
              justifyContent={{ xs: "center", md: "flex-start" }}
              alignContent="center"
            >
              <Typography
                sx={{
                  fontSize: { xs: "16px", md: "32px" },
                  fontWeight: "600",
                  color: "var(--font-color)",
                }}
              >
                {activity.text}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {/* Popular Liveaboard Carousel */}
      {liveaboards?.length > 0 && (
        <CarouselOrganism
          t={t}
          type="card"
          items={liveaboards}
          heading={t("HomePage.popular_liveaboard")}
          description={t("HomePage.live_description")}
          width="90%"
          getFavorites={getFavorites}
          activity_type="live_aboard"
          navigateParam={{ state: { searchHotOffers: false } }}
          customCarouselClass={"liveaboards-carousel"}
        />
      )}

      {/* Sponsors Carousel */}
      {sponsers?.length > 0 && (
        <Grid container width={"90%"} mx="auto">
          <Grid item xs={10}>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "38px" },
                fontWeight: "bold",
                margin: "0 10px",
                color: "#272726",
              }}
            >
              Sponsors
            </Typography>
          </Grid>
        </Grid>
      )}
      {sponsers?.length > 0 && (
        <Grid
          container
          mt={"26px"}
          mb={{ xs: 5, md: 10 }}
          bgcolor="rgba(236, 236, 236, 0.56)"
          className="sponsors"
          py="30px"
        >
          <CarouselOrganism
            t={t}
            type="image"
            action={false}
            items={sponsers}
            // heading={"Sponsors"}
            width="90%"
            navigateParam={{ state: { searchHotOffers: false } }}
            customCarouselClass={"sponsers-carousel"}
            imageOnly={true}
            enableWidth={false}
            maxHeight="100px"
            itemsToShow={3}
            customStyle={{ paddingLeft: "120px" }}
            enableHeight={false}
            borderRadius="none"
            mb={"40px"}
            // autoPlay={true}
            infinite={true}
            // customRightArrow={<CustomRightArrow />}
            // customLeftArrow={<CustomLeftArrow />}
          />
        </Grid>
      )}
      {/* Mobile App Bar */}
      <Grid
        container
        height={{ xs: "auto" }}
        mb={{ xs: 5, md: 30 }}
        mt={{ xs: 5, md: 30 }}
        width={{ xs: "100%", md: "90%" }}
        mx="auto"
        bgcolor="rgba(236, 236, 236, 0.56)"
        borderRadius={{ xs: "0px", md: "22px" }}
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          height={{ xs: "auto", md: "430px" }}
          sx={{ paddingInlineStart: { xs: "0px", md: "70px" } }}
          justifyContent={{ xs: "center", md: "flex-start" }}
          alignContent="center"
          mt={{ xs: 5, md: 0 }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "24px" },
                fontWeight: "500",
                lineHeight: "2.08",
                color: "var(--font-color)",
                textAlign: { xs: "center", md: "start" },
              }}
            >
              {t("HomePage.start_adventure")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: { xs: "28px", md: "45px" },
                fontWeight: "bold",
                marginBottom: "37px",
                color: "var(--font-color)",
                textAlign: { xs: "center", md: "start" },
              }}
            >
              {t("HomePage.download")}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={6}
            lg={"auto"}
            justifyContent={{ xs: "center", md: "inherit" }}
            position={"relative"}
            left={{ xs: "0px", md: "-12px" }}
            className="mobile-app-button"
          >
            <a
              href="https://apps.apple.com/us/app/scubers/id1636514336"
              target="_blank"
            >
              <img
                src={images.Apple}
                alt="apple"
                style={{ cursor: "pointer", maxWidth: "100%" }}
              />
            </a>
          </Grid>
          <Grid
            container
            item
            xs={6}
            lg={"auto"}
            justifyContent={{ xs: "center", md: "inherit" }}
            position={"relative"}
            left={{ xs: "0px", md: "-12px" }}
            className="mobile-app-button"
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.inova.scubers"
              target="_blank"
            >
              <img
                src={images.Android}
                alt="android"
                style={{ cursor: "pointer", maxWidth: "100%" }}
              />
            </a>
          </Grid>
        </Grid>
        <Grid
          item
          md={6}
          height="430px"
          sx={{ position: "relative", display: { xs: "none", md: "inherit" } }}
        >
          <img
            src={images.Mobile}
            alt="Mobile"
            style={{ position: "absolute", top: "-34%", left: "-0" }}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          mb={5}
          sx={{ display: { md: "none" } }}
        >
          <img src={images.MobileResponsive} alt="Mobile" />
        </Grid>
      </Grid>
    </>
  );
};

export default withTranslation()(HomeTemplate);
