import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import { FormControl, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, TextField } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import { withTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
const CountrySelect = ({ t, value, onChange, selectClass, label, labels, ...rest }) => {
    // fix `tReady` prop issue
    delete rest.tReady;
    // fix `tReady` prop issue
    const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    const allOptions = getCountries();
    const [searchText, setSearchText] = useState("");
    const displayedOptions = useMemo(
        () =>
        allOptions.filter((option) => containsText(labels[option], searchText)),
        [searchText]
    );
    return(
    <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
            {...rest}
            value={value}
            fullWidth
            onChange={(e) => onChange(e)}
            onClose={() => setSearchText("")}
            className={selectClass}
            renderValue={() => value}
        >
            <ListSubheader>
                <TextField
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder={t("General.type_search")}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                    ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                    e.stopPropagation();
                    }
                }}
                />
            </ListSubheader>
            {/* {getCountries().map((country) => ( */}
            {displayedOptions.map((country) => (
                <MenuItem key={country} value={getCountryCallingCode(country)}>
                    {labels[country]} +{getCountryCallingCode(country)}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
)}

export default withTranslation()(CountrySelect)