import axios from "axios";
import { BASE_URL } from "./URLs";
import { LogoutAPI } from "./Auth/Logout";
import { toast } from "react-toastify";
import { Firebase } from "../Firebase/Firebase";
import Cookies from "js-cookie";

export function axiosFn(
  method,
  url,
  recivedData,
  auth = true,
  contentType = "application/json"
) {
  let token = localStorage.getItem("ActiveToken");
  let data = { ...recivedData };
  let verifyToken = localStorage.getItem("VERIFY");
  return axios({
    method,
    url: BASE_URL + url, //url === "/auth/token/create" ? BASE + url : BASE_URL + url,
    data,
    headers: {
      Authorization: verifyToken ? "" : `Bearer ${token}`,
      "Content-Type": contentType,
      "verification-token": verifyToken,
      "Accept-Language": localStorage.getItem("lang"),
      // "Timezone": "Cairo",
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,

      // "Access-Control-Allow-Origin":"*"
    },
  });
}

export function handleError(err) {
  let errMsg;
  let accounts = JSON.parse(localStorage.getItem("Accounts"));

  const handleAccountChange = (account) => {
    localStorage.setItem("ActiveToken", account.token);
    localStorage.setItem("ActiveUserId", account.userId);
    localStorage.setItem("ActiveAvatar", account.avatar);
    localStorage.setItem("ActiveUserType", account.user_type);
    localStorage.setItem("ActiveEmail", account.email);
    localStorage.setItem("ISVERIFIED", account.is_verified);
    window.location.reload();
  };
  const Logout = async () => {
    const { res, err } = await LogoutAPI();
    if (res) toast.success(res?.message);
    if (err) toast.error(err);
  };
  if (err?.message == 'Request failed with status code 401' || err?.response?.status == 401) {
    const id = parseInt(localStorage.getItem("ActiveUserId"));
    const newAccounts = accounts?.filter((account) => account.userId !== id);
    Logout();
    if (newAccounts.length > 0) {
      localStorage.setItem("Accounts", JSON.stringify(newAccounts));
      handleAccountChange(newAccounts[0]);
    } else {
      localStorage.clear();
      localStorage.remove("firebase_auth_token");
      Cookies.remove("lastFirebaseRequestEpoch");
      localStorage.setItem("USER_TYPE", "guest");
      localStorage.setItem("lang", "en");
      window.location.replace('/login')
    }
    return
  }
  if (err?.response) errMsg = err?.response?.data?.message;
  return {
    res: null,
    err: errMsg,
  };
}
