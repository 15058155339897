import { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton.jsx";
import CartCard from "../../../Components/Organisms/Cards/CartCard.jsx";
import SummeryOrganism from "../../../Components/Organisms/Reservation/SummeryOrganism.jsx";
import { toast } from "react-toastify";

const CheckoutTemplate = ({
  t,
  cart,
  getCart,
  checkout,
  redeem,

  deleteCart,
}) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(2);
  const [payWait, setPayWait] = useState(2);
  const [checked, setChecked] = useState(false);
  const [show, setShow] = useState(false);
  const [isAllNeedConfirm, setIsAllNeedConfirm] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handlePayChange = (event) => {
    setPayWait(event.target.value);
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  const sendCheckout = async () => {
    if (!checked) {
      toast.error(t("Checkout.confirm-terms-conditions"));
    }
    if (checked && value) {
      await checkout({
        payment_method_id: value,
        payment_option:
          payWait === null
            ? ""
            : payWait == 1
            ? "pay_confirmed"
            : "wait_for_all", //pay_confirmed, wait_for_all
      });
    }
  };

  useEffect(() => {
    if (value === "1") setShow(false);
  }, [value]);

  useEffect(() => {
    const needConfirm = cart?.cart_items?.every((item) => {
      return item.purchasable.dive_activity.need_confirmation === true;
    });

    const oneNeedConfiem = cart?.cart_items
      ?.map((item) => {
        console.log(item.purchasable.dive_activity.need_confirmation, "hii 2");
        if (item.purchasable.dive_activity.need_confirmation) return true;
      })
      .find((s) => s == true);
    if (!needConfirm) {
      setPayWait(null);
      setShow(false);
    }
    if (oneNeedConfiem && value != "1") setShow(true);
    setIsAllNeedConfirm(needConfirm);
  }, [cart?.cart_items, value]);
  // console.log(payWait);
  return (
    <Grid
      container
      mt={5}
      mb={8}
      sx={{
        width: {
          xs: "100%",
          md: "90%",
        },
        marginX: "auto",
        px: { xs: 2 },
        gap: { xs: 2 },
      }}
    >
      <Grid item xs={12} mb={4}>
        <Typography
          sx={{
            color: "var(--font-color)",
            fontSize: "var(--home-header-font)",
            fontWeight: "bold",
          }}
        >
          {t("Checkout.checkout")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7} sx={{ paddingInlineEnd: { md: "24px" } }}>
        <Grid
          container
          mb={3}
          sx={{
            padding: "0px 22px",
            borderRadius: "8px",
            border: "solid 0.5px #979797",
          }}
        >
          <Grid container mt={3} mb={2}>
            <Typography
              sx={{
                color: "black",
                fontSize: "var(--large-font)",
                fontWeight: "bold",
              }}
            >
              {t("Checkout.review")}
            </Typography>
          </Grid>
          {cart?.cart_items?.map((item) => {
            return (
              <CartCard
                key={item?.id}
                t={t}
                card={item}
                deleteCart={deleteCart}
                orderId={item?.id}
                cardId={item?.id}
              />
            );
          })}
        </Grid>
        <Grid
          container
          mb={3}
          sx={{
            padding: "0px 22px",
            borderRadius: "8px",
            border: "solid 0.5px #979797",
          }}
        >
          <Grid container mt={3} mb={2}>
            <Typography
              sx={{
                color: "black",
                fontSize: "var(--large-font)",
                fontWeight: "bold",
              }}
            >
              {t("Checkout.payment")}
            </Typography>
          </Grid>
          <Grid container>
            <FormControl sx={{ width: "100%" }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                {cart?.allowed_payment_methods?.map((method) => {
                  return (
                    <FormControlLabel
                      value={method.id}
                      key={method.id}
                      control={<Radio />}
                      label={method.name}
                      sx={{
                        border:
                          value == method.id
                            ? "solid 0.5px #f6b119"
                            : "solid 0.5px #dadada",
                        borderRadius: "8px",
                        marginBottom: "16px",
                        minHeight: "70px",
                      }}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {show && (
          <Grid
            container
            mb={3}
            sx={{
              padding: "0px 22px",
              borderRadius: "8px",
              border: "solid 0.5px #979797",
            }}
          >
            <Grid container mt={3} mb={2}>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "var(--large-font)",
                  fontWeight: "bold",
                }}
              >
                {t("Checkout.select")}
              </Typography>
            </Grid>
            <Grid container>
              <FormControl sx={{ width: "100%" }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons"
                  name="controlled-radio-buttons"
                  value={payWait}
                  onChange={handlePayChange}
                >
                  {!isAllNeedConfirm && (
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label={t("Checkout.pay")}
                      sx={{
                        border:
                          payWait == 1
                            ? "solid 0.5px #f6b119"
                            : "solid 0.5px #dadada",
                        borderRadius: "8px",
                        marginBottom: "16px",
                        minHeight: "70px",
                      }}
                    />
                  )}
                  <FormControlLabel
                    value={2}
                    control={
                      <Radio
                        checked={
                          isAllNeedConfirm ? true : payWait == 2 ? true : false
                        }
                      />
                    }
                    label={t("Checkout.wait")}
                    sx={{
                      border:
                        payWait == 2
                          ? "solid 0.5px #f6b119"
                          : "solid 0.5px #dadada",
                      borderRadius: "8px",
                      marginBottom: "16px",
                      minHeight: "70px",
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid container mb={2.5} alignItems="center">
          <FormControlLabel
            checked={checked}
            onChange={handleCheckChange}
            control={<Checkbox />}
            label={
              <Typography
                display="inline"
                sx={{
                  color: "rgba(39, 39, 38, 0.8)",
                  fontSize: "var(--text-font)",
                  fontWeight: "bold",
                }}
              >
                {t("Checkout.read_sucbers")}{" "}
              </Typography>
            }
            sx={{ marginRight: "5px" }}
          />
          <Typography
            display="inline"
            sx={{
              color: "#f0b70d",
              fontSize: "var(--text-font)",
              fontWeight: "bold",
              textDecorationLine: "underline",
              textUnderlineOffset: "3px",
              textDecorationThickness: "10%",
              cursor: "pointer",
            }}
            onClick={() => navigate("/profile/terms")}
          >
            {t("Profile.terms")}
          </Typography>
        </Grid>
        <Grid container>
          <PrimaryButton
            text={t("Checkout.order")}
            classBtn="orange-btn"
            style={{ width: "450px" }}
            click={sendCheckout}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <SummeryOrganism t={t} cart={cart} redeem={redeem} getCart={getCart} />
      </Grid>
    </Grid>
  );
};

export default CheckoutTemplate;
