import { Grid, Modal } from "@mui/material";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    TelegramIcon,
    WhatsappIcon,
    TumblrIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
} from "react-share";

import { icons } from "../../../../Assets/AssetHelper";

const ShareModal = ({ t, open, handleClose, link }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: 200, md: 600 },
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: { xs: 1, md: 4 },
    };
    const title = t("Details.social");
    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Grid container sx={style} justifyContent='space-evenly' alignContent='center'>
                <FacebookShareButton
                    url={link}
                    quote={title}
                // className="Demo__some-network__share-button"
                >
                    <FacebookIcon size={62} round />
                </FacebookShareButton>
                <FacebookMessengerShareButton
                    url={link}
                    appId="521270401588372"
                    className="Demo__some-network__share-button"
                >
                    <FacebookMessengerIcon size={62} round />
                </FacebookMessengerShareButton>
                <TwitterShareButton
                    url={link}
                    title={title}
                    className="Demo__some-network__share-button"
                >
                    <img src={icons.TwitterX} alt="twitter-x" style={{
                        borderRadius: "50%",  border: "2px solid #1e1e1e",  padding: "11.5px"
                    }}/>
                </TwitterShareButton>
                <TelegramShareButton
                    url={link}
                    title={title}
                    className="Demo__some-network__share-button"
                >
                    <TelegramIcon size={62} round />
                </TelegramShareButton>
                <WhatsappShareButton
                    url={link}
                    title={title}
                    separator=":: "
                    className="Demo__some-network__share-button"
                >
                    <WhatsappIcon size={62} round />
                </WhatsappShareButton>
                <LinkedinShareButton url={link} className="Demo__some-network__share-button">
                    <LinkedinIcon size={62} round />
                </LinkedinShareButton>
                <TumblrShareButton
                    url={link}
                    title={title}
                    className="Demo__some-network__share-button"
                >
                    <TumblrIcon size={62} round />
                </TumblrShareButton>
            </Grid>
        </Modal>
    );
}

export default ShareModal;