import { useState, useEffect } from "react";
import { Avatar, Divider, Grid, Typography } from "@mui/material";
import * as Yup from "yup";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import FileInput from "../../../../Atoms/Input/FileInput/FileInput";
import { icons } from "../../../../../Assets/AssetHelper";
import Loader from "../../../../Atoms/Loader/Loader";
import {
  getPresignedLinkAPI,
  uploadFileAPI,
} from "../../../../../Contexts/APIs/Uploads/Upload";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import TextFieldInput from "../../../../Atoms/Input/TextField/TextFieldInput";
import PrimaryButton from "../../../../Atoms/Buttons/Primary/PrimaryButton";
import SelectBox from "../../../../Atoms/Select/Select";
import DateModal from "../../../Modals/DateModal";
import CertificatesMolecule from "../../../../Molecules/Profile/ViewProfile/CertificatesMolecule";
import AddSocialMediaModal from "../../../Modals/Profile/AddSocialMediaModal";
import SearchSelect from "../../../../Atoms/Select/SearchSelect";
import BasicDatePicker from "../../../../Atoms/Input/DatePicker copy/DatePicker";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "./EditProfile.css";

const EditProfile = ({
  t,
  profileData,
  updateProfile,
  getProfileData,
  countries,
  cities,
  organizations,
  getOrganizationLevels,
  createCertificate,
  deleteCertificate,
  editCertificate,
  organizationLevels,
  userLanguages,
  getCities,
  areas,
  getAreas,
  social,
  getCountries,
  getLanguages,
  getOrganizations,
  getSocialMedia,
  getLevels,
}) => {
  const [open, setOpen] = useState(false);
  const [openSocial, setOpenSocial] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [socialMedia, setSocialMedia] = useState([]);
  const [deletedSocialMedia, setDeletedSocialMedia] = useState([]);
  const navigate = useNavigate();

  const changeProfilePic = async (event) => {
    const file = event.target.files[0];
    const fileSize = file.size / window.maxFileSize; // in MiB
    if (fileSize > 1) {
      toast.warning(window.fileSize);
    } else {
      setOpenLoader(true);
      const res = await getPresignedLinkAPI([{ extension: "png" }]);
      const presignedUrl = res?.res?.data[0].presigned_url;
      const upload = await uploadFileAPI(presignedUrl, file);
      if (upload) {
        const result = await updateProfile({
          user: {
            avatar: res?.res?.data[0].file_url,
          },
        });
        if (result) {
          const accounts = await JSON.parse(localStorage.getItem("Accounts"));
          const ActiveUserId = await localStorage.getItem("ActiveUserId");
          let index = null;
          await accounts?.map((data, idx) => {
            if (data?.userId == ActiveUserId) index = idx;
          });
          if (index != null) {
            accounts[index] = {
              ...accounts[index],
              avatar: result?.data?.user?.avatar,
            };
          }
          localStorage.setItem("Accounts", JSON.stringify(accounts));
          localStorage.setItem("ActiveAvatar", result?.data?.user?.avatar);
          getProfileData();
        }
      }
      setOpenLoader(false);
    }
  };

  const customerInitials = {
    firstName: profileData?.firstname || "",
    lastName: profileData?.lastname || "",
    date: profileData?.birthdate || null,
    email: profileData?.email || "",
    phone: profileData?.phone_number || "",
    gender: gender || "",
    language:
      {
        language_id: profileData?.languages?.[0]?.language?.id,
        id: profileData?.languages?.[0]?.id,
      } || "",
    country: country || "",
    province: province || "",
    city: city || "",
  };

  const diveClubInitials = {
    name: profileData?.name || "",
    person: profileData?.contact_person_name || "",
    date: profileData?.birthdate || "",
    email: profileData?.email || "",
    phone: profileData?.phone_number || "",
    website: profileData?.website || "",
    language:
      {
        language_id: profileData?.primary_language?.id,
        id: profileData?.primary_language?.id,
      } || "",
    country: country || "",
    province: province || "",
    city: city || "",
    social_media: socialMedia || "",
  };

  const customerValidation = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t("validation.short"))
      .max(30, t("validation.long"))
      .required(t("validation.required")),
    lastName: Yup.string()
      .min(2, t("validation.short"))
      .max(30, t("validation.long"))
      .required(t("validation.required")),
    // email: Yup.string()
    //     .email(t("validation.invalid_email"))
    //     .required(t("validation.email")),
    phone: Yup.number()
      .required(t("validation.required"))
      .typeError(t("validation.specify_number")),
    gender: Yup.string().required(t("validation.required")),
    language: Yup.object()
      .shape({
        id: Yup.string(),
        language_id: Yup.string().required(t("validation.required")),
      })
      .required(t("validation.required")),
    country: Yup.number()
      .required(t("validation.required"))
      .typeError(t("validation.specify")),
    province: Yup.number()
      .required(t("validation.required"))
      .typeError(t("validation.specify")),
    city: Yup.number()
      .required(t("validation.required"))
      .typeError(t("validation.specify")),
    date: Yup.date()
      .required(t("validation.required"))
      .typeError(t("validation.specify")),
  });

  const diveClubValidation = Yup.object().shape({
    name: Yup.string()
      .min(2, t("validation.short"))
      .max(30, t("validation.long"))
      .required(t("validation.required")),
    person: Yup.string()
      .min(2, t("validation.short"))
      .max(30, t("validation.long"))
      .required(t("validation.required")),
    // email: Yup.string()
    //     .email(t("validation.invalid_email"))
    //     .required(t("validation.email")),
    phone: Yup.number()
      .required(t("validation.required"))
      .typeError(t("validation.specify_number")),
    website: Yup.string().required(t("validation.required")),
    language: Yup.object().shape({
      id: Yup.string(),
      language_id: Yup.string().required(t("validation.required")),
    }),
    country: Yup.number().required(t("validation.required")),
    province: Yup.number().required(t("validation.required")),
    city: Yup.number().required(t("validation.required")),
    date: Yup.date().required(t("validation.required")),
  });

  useEffect(() => {
    getSocialMedia({});
    getLevels();
  }, []);

  useEffect(() => {
    setCountryCode(profileData?.country_code);
    setGender(profileData?.gender);
    setCountry(profileData?.country_id);
    setProvince(profileData?.city_id);
    setCity(profileData?.area?.id);
    setSocialMedia(profileData?.social_media);
  }, [profileData]);

  return (
    <Grid container>
      <Loader open={openLoader} />
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: "var(--xlarge-font)",
            fontWeight: "800",
            color: "var(--font-color)",
            marginBottom: "24px",
          }}
        >
          {t("Profile.edit_profile")}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ position: "relative" }}>
        <Avatar
          src={profileData?.avatar}
          alt="avatar"
          sx={{ width: "96px", height: "96px" }}
        />
        <FileInput
          icon={icons.Camera}
          btnStyle="upload-profile-pic"
          change={(e) => changeProfilePic(e)}
          accept="image/*"
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography sx={{ color: "black" }}>{window.fileSize}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: "var(--text-font)",
            fontWeight: "bold",
            color: "var(--font-color)",
            marginBottom: "16px",
            marginTop: "40px",
          }}
        >
          {t("Profile.information")}
        </Typography>
      </Grid>
      <Formik
        enableReinitialize={true}
        initialValues={
          profileData?.user_type === "customer"
            ? customerInitials
            : diveClubInitials
        }
        validationSchema={
          profileData?.user_type === "customer"
            ? customerValidation
            : diveClubValidation
        }
        onSubmit={async (values) => {
          const data =
            profileData?.user_type === "customer"
              ? {
                  user: {
                    firstname: values.firstName,
                    lastname: values.lastName,
                    email: values.email,
                    phone_number: values.phone,
                    country_code: `+${countryCode}`,
                    gender: values.gender,
                    birthdate: values.date
                      ? dayjs(values.date).format("DD-MM-YYYY")
                      : null,
                    country_id: values.country,
                    city_id: values.province,
                    user_languages_attributes: [values.language],
                    area_id: values.city,
                  },
                }
              : {
                  user: {
                    name: values.name,
                    contact_person_name: values.person,
                    email: values.email,
                    phone_number: values.phone,
                    country_code: countryCode,
                    website: values.website,
                    birthdate: values.date
                      ? dayjs(values.date).format("DD-MM-YYYY")
                      : null,
                    country_id: values.country,
                    city_id: values.province,
                    primary_language_id: values.language?.language_id,
                    area_id: values.city,
                    user_social_media_attributes: values.social_media,
                  },
                  deleted_social_media_ids: deletedSocialMedia,
                };
          const res = await updateProfile(data);
          if (res) {
            getProfileData();
            navigate("/profile/view-profile");
          }
        }}
      >
        {({
          isSubmitting,
          submitForm,
          values,
          handleChange,
          setFieldValue,
          errors,
          setValues,
        }) => (
          <Form>
            <Grid container>
              {profileData?.user_type === "customer" ? (
                <Grid item xs={12} md={6} sx={{ paddingInlineEnd: "4px" }}>
                  <Field /* First Name */
                    as={TextFieldInput}
                    name="firstName"
                    placeholder={t("Signup.first_name")}
                    label={t("Signup.first_name")}
                    className="login-input"
                    gridClass="grid-input"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    style={{ color: "#d32f2f" }}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={6} sx={{ paddingInlineEnd: "4px" }}>
                  <Field /* Dive Club Name */
                    as={TextFieldInput}
                    name="name"
                    placeholder={t("Signup.name")}
                    label={t("Signup.name")}
                    className="login-input"
                    gridClass="grid-input"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    style={{ color: "#d32f2f" }}
                  />
                </Grid>
              )}
              {profileData?.user_type === "customer" ? (
                <Grid item xs={12} md={6} sx={{ paddingInlineStart: "4px" }}>
                  <Field /* Last Name */
                    as={TextFieldInput}
                    name="lastName"
                    placeholder={t("Signup.last_name")}
                    className="login-input"
                    gridClass="grid-input"
                    label={t("Signup.last_name")}
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    style={{ color: "#d32f2f" }}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={6} sx={{ paddingInlineStart: "4px" }}>
                  <Field /* Contact Person Name */
                    as={TextFieldInput}
                    name="person"
                    placeholder={t("Profile.contact")}
                    className="login-input"
                    gridClass="grid-input"
                    label={t("Profile.contact")}
                  />
                  <ErrorMessage
                    name="person"
                    component="div"
                    style={{ color: "#d32f2f" }}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={6}
                sx={{ paddingInlineEnd: "4px", marginTop: "15px" }}
              >
                {/* <Field // Phone //
                  as={PhoneInput}
                  id="phone"
                  name="phone"
                  inputValue={values.phone}
                  setPhone={handleChange}
                  labels={en}
                  selectClass="profile-input"
                  phoneClass="login-input"
                  phoneGridClass="grid-input"
                  placeholder={t("Profile.mobile")}
                  // value={countryCode}
                  value={countryCode ? countryCode : ""}
                  onChange={(input) => setCountryCode(input)}
                  label={t("Edit_Trip.code")}
                  textLabel={t("General.phone_number")}
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  style={{ color: '#d32f2f' }}
                /> */}

                <PhoneInput
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  inputStyle={{
                    color: "black",
                    border: "none",
                    width: "calc(100% - 40px)",
                    padding: "7px 15px",
                    direction: "ltr",
                    fontSize: "1rem",
                  }}
                  inputClass="phone-input-class login-input"
                  specialLabel="Phone number"
                  defaultMask={countryCode != '20' ? '... ... ....' : ''}                  
                  value={`${countryCode}${values?.phone}`}
                  onChange={(value, data) => {
                    setValues({
                      ...values,
                      phone: value.slice(data?.dialCode?.length),
                    });
                    setCountryCode(data.dialCode);
                  }}
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  style={{ color: "#d32f2f" }}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ paddingInlineStart: "4px" }}>
                <Field /* Email */
                  as={TextFieldInput}
                  type="email"
                  name="email"
                  placeholder={t("Profile.email")}
                  className="login-input"
                  gridClass="grid-input"
                  disabled={true}
                  label={t("Profile.email")}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  style={{ color: "#d32f2f" }}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ paddingInlineEnd: "4px" }}>
                <BasicDatePicker
                  placeholder={
                    profileData?.user_type === "customer"
                      ? t("Profile.birth")
                      : t("Profile.foundation")
                  }
                  value={values.date}
                  disableFuture={true}
                  maxDate={
                    profileData?.user_type === "customer"
                      ? dayjs().subtract(18, "year")
                      : dayjs()
                  }
                  className="border-r-5px basic-date-picker mt-24px login-input"
                  errorText={t("General.date_field_required")}
                  handelChange={(newValue) => {
                    setFieldValue("date", newValue);
                    // setDate={(value) => setFieldValue("date", value)}
                  }}
                  setValue={() => setFieldValue("date", null)}
                />
                <ErrorMessage
                  name="date"
                  component="div"
                  style={{ color: "#d32f2f" }}
                />
              </Grid>
              {profileData?.user_type === "customer" ? (
                <Grid item xs={12} md={6} sx={{ paddingInlineStart: "4px" }}>
                  <Field /* Gender */
                    as={SelectBox}
                    id="gender"
                    name="gender"
                    items={[
                      { id: "male", name: t("Profile.male") },
                      { id: "female", name: t("Profile.female") },
                    ]}
                    className="login-input"
                    style={{ marginTop: "24px" }}
                    // value={values.gender}
                    value={values.gender ? values.gender : ""}
                    onchange={handleChange}
                    label={t("Profile.gender")}
                    IconComponent={(props) => (
                      <ExpandMoreOutlinedIcon {...props} />
                    )}
                  />
                  <ErrorMessage
                    name="gender"
                    component="div"
                    style={{ color: "#d32f2f" }}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={6} sx={{ paddingInlineStart: "4px" }}>
                  <Field /* website */
                    as={TextFieldInput}
                    name="website"
                    placeholder={t("Profile.website")}
                    label={t("Profile.website")}
                    className="login-input"
                    gridClass="grid-input"
                  />
                  <ErrorMessage
                    name="website"
                    component="div"
                    style={{ color: "#d32f2f" }}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6} sx={{ paddingInlineEnd: "4px" }}>
                <Field /* Language */
                  as={SearchSelect}
                  defaultValues={profileData?.languages?.[0]?.language}
                  items={[
                    ...userLanguages,
                    profileData?.languages?.[0]?.language,
                  ]}
                  getList={getLanguages}
                  name="language.language_id"
                  className="login-input"
                  style={{ marginTop: "24px" }}
                  value={
                    values.language?.language_id
                      ? values.language?.language_id
                      : ""
                  }
                  onchange={handleChange}
                  label={t("Profile.primary_language")}
                  IconComponent={(props) => (
                    <ExpandMoreOutlinedIcon {...props} />
                  )}
                />
                <ErrorMessage
                  name="language.language_id"
                  component="div"
                  style={{ color: "#d32f2f" }}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ paddingInlineStart: "4px" }}>
                <Field /* Country */
                  as={SearchSelect}
                  getList={getCountries}
                  defaultValues={profileData?.country}
                  items={[...countries, profileData?.country]}
                  name="country"
                  label={t("Profile.Country")}
                  className="login-input"
                  style={{ marginTop: "24px" }}
                  value={values.country}
                  onchange={handleChange}
                  onclick={(id) =>
                    getCities({
                      country_id: id,
                      page_size: 10,
                    })
                  }
                  IconComponent={(props) => (
                    <ExpandMoreOutlinedIcon {...props} />
                  )}
                />
                <ErrorMessage
                  name="country"
                  component="div"
                  style={{ color: "#d32f2f" }}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ paddingInlineEnd: "4px" }}>
                <Field /* province */
                  as={SearchSelect}
                  defaultValues={profileData?.city}
                  items={[...cities, profileData?.city]}
                  moreFilterData={{ country_id: profileData?.country_id }}
                  name="province"
                  className="login-input"
                  style={{ marginTop: "24px" }}
                  value={values.province}
                  onchange={handleChange}
                  onclick={(id) =>
                    getAreas({
                      city_id: id,
                      page_size: 10,
                    })
                  }
                  label={t("Profile.province")}
                  IconComponent={(props) => (
                    <ExpandMoreOutlinedIcon {...props} />
                  )}
                />
                <ErrorMessage
                  name="province"
                  component="div"
                  style={{ color: "#d32f2f" }}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ paddingInlineStart: "4px" }}>
                <Field /* City */
                  as={SearchSelect}
                  defaultValues={profileData?.area}
                  items={[...areas]}
                  name="city"
                  moreFilterData={{ city_id: profileData?.area?.id }}
                  className="login-input"
                  style={{ marginTop: "24px" }}
                  value={values.city}
                  onchange={handleChange}
                  label={t("Profile.city")}
                  IconComponent={(props) => (
                    <ExpandMoreOutlinedIcon {...props} />
                  )}
                />
                <ErrorMessage
                  name="city"
                  component="div"
                  style={{ color: "#d32f2f" }}
                />
              </Grid>
              <Divider
                width="100%"
                sx={{ marginTop: "24px", marginBottom: "24px" }}
              />
              {profileData?.user_type === "customer" ? (
                <CertificatesMolecule
                  t={t}
                  profileData={profileData}
                  organizations={organizations}
                  getOrganizationLevels={getOrganizationLevels}
                  createCertificate={createCertificate}
                  deleteCertificate={deleteCertificate}
                  editCertificate={editCertificate}
                  type="edit"
                  organizationLevels={organizationLevels}
                  getOrganizations={getOrganizations}
                />
              ) : (
                <FieldArray
                  name="social_media"
                  render={(arrayHelpers) => (
                    <Grid item xs={12}>
                      <Typography
                        display="inline"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: "#272726",
                        }}
                      >
                        {t("Profile.social")}
                      </Typography>
                      <img
                        src={icons.AddIcon}
                        alt="add certificate"
                        style={{ marginInlineStart: "18px", cursor: "pointer" }}
                        onClick={() => setOpenSocial(true)}
                      />
                      {values?.social_media?.length > 0 ? (
                        values?.social_media?.map((item, i) => {
                          return (
                            <Grid
                              container
                              sx={{
                                width: "355px",
                                height: "57px",
                                borderRadius: "10px",
                                border: "solid 1px rgba(104, 104, 104, 0.16)",
                                paddingInlineStart: "12px",
                                position: "relative",
                              }}
                              alignItems="center"
                              mt={4}
                              key={i}
                            >
                              <Typography
                                display="inline"
                                sx={{ fontSize: "16px" }}
                              >
                                {social?.find(
                                  (obj) => obj.id === item.social_medium_id
                                )?.name || item.social_medium.name}
                                :
                              </Typography>
                              <Typography
                                display="inline"
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  paddingInlineStart: "18px",
                                }}
                              >
                                {item.link}
                              </Typography>
                              <img
                                src={icons.Delete}
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  arrayHelpers.remove(i);
                                  if (item.id)
                                    setDeletedSocialMedia([
                                      ...deletedSocialMedia,
                                      item.id,
                                    ]);
                                }}
                              />
                            </Grid>
                          );
                        })
                      ) : (
                        <Grid container justifyContent="center" mt={3} mb={5}>
                          <Typography>
                            {t("Profile.no_socila_media_links_added")}
                          </Typography>
                        </Grid>
                      )}
                      <AddSocialMediaModal
                        t={t}
                        open={openSocial}
                        handleClose={() => setOpenSocial(false)}
                        arrayHelpers={arrayHelpers}
                        social={social}
                      />
                    </Grid>
                  )}
                />
              )}
              <PrimaryButton
                type="submit"
                disabled={isSubmitting}
                text={t("Profile.save_changes")}
                classBtn="profile-btn"
                style={{
                  marginTop: "32px",
                  backgroundColor: "#f6b119",
                  color: "white",
                }}
                sx={{ width: { xs: "100%", md: "35%" } }}
                click={submitForm}
              />
            </Grid>
            <DateModal
              t={t}
              open={open}
              handleClose={() => setOpen(false)}
              date={values.date}
              setDate={(value) => setFieldValue("date", value)}
              disablePast={false}
              openTo="day"
            />
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default EditProfile;
