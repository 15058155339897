import { Grid, Typography, Divider } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { icons, images } from "../../../../Assets/AssetHelper.js";
import TextFieldInput from "../../../Atoms/Input/TextField/TextFieldInput.jsx";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton.jsx";

import {
  getAuth,
  signInWithPopup,
  OAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import { toast } from "react-toastify";
import {
  GoogleLogin,
  GoogleRegister,
} from "../../../../Contexts/APIs/Auth/GoogleAuth.js";
import { signInWithApplePopup } from "../../../../Contexts/Firebase/Firebase.js";
import { useRef, useState } from "react";

function LoginStep1({ t, LoginApi, setStep, setIsAuth }) {
  const socialLoginButtonStyle = {
    width: "165px",
    height: "52px",
    fontSize: "18px",
    fontWeight: "600",
    textTransform: "capitalize",
    backgroundColor: "white",
    color: "#272726",
    border: "1px solid rgba(39, 39, 38, 0.4)",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const navigate = useNavigate();
  const fcm_token = localStorage.getItem("fcm_token");
  const userAgent = window.navigator.userAgent;
  const [applePayload, setApplePayload] = useState();
  const signUpRef = useRef();
  const auth = getAuth();
  auth.languageCode = "ar";

  const checkFirebaseToken = () => {
    if (localStorage.getItem("fcm_token") == null) {
      return toast.error("You must allow browser notifications");
    }
  };

  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
  auth.languageCode = "ar";

  const handleSocailLogin = async (data, type) => {
    const { res, err } = await GoogleLogin(data, type);
    if (res) {
      let array = [];
      res.map((obj) => {
        const account = {
          token: obj.extra.access_token,
          userId: obj.user.id,
          avatar: obj.user.avatar,
          user_type: obj.user.user_type,
          email: obj.user.email,
          firstname: obj.user.firstname,
          lastname: obj.user.lastname,
          is_verified: obj.user.is_verified,
          firebase_auth_token: obj?.user?.firebase_auth_token,
          firebase_node_path: obj?.user?.firebase_node_path,
        };

        const accounts = JSON.parse(localStorage.getItem("Accounts"));
        if (accounts?.length > 0) {
          const check = accounts?.filter(
            (val) => val?.userId == account?.userId
          );
          if (check?.length == 0) array.push(account);
        } else {
          array.push(account);
        }
      });
      if (localStorage.getItem("Accounts") === null) {
        localStorage.setItem("Accounts", JSON.stringify(array));
      } else {
        const accounts = JSON.parse(localStorage.getItem("Accounts"));

        accounts.push(...array);
        localStorage.setItem("Accounts", JSON.stringify(accounts));
      }
      if (
        res[0]?.user?.user_type == "customer" ||
        res[0]?.user?.user_type == "dive_club"
      ) {
        if (array.length === 1) {
          setIsAuth(true);
          navigate("../", { replace: true });
        } else {
          localStorage.setItem("GoogleAccounts", JSON.stringify(array));
          setStep(2);
        }
        localStorage.setItem("ActiveToken", res[0]?.extra?.access_token);
        localStorage.setItem("ActiveUserId", res[0]?.user?.id);
        localStorage.setItem("ActiveAvatar", res[0]?.user?.avatar);
        localStorage.setItem("ActiveUserType", res[0]?.user?.user_type);
        localStorage.setItem("ActiveEmail", res[0]?.user?.email);
        localStorage.setItem("ISVERIFIED", res[0]?.user?.is_verified);
        localStorage.setItem(
          "firebase_auth_token",
          res[0]?.user?.firebase_auth_token
        );
        localStorage.setItem(
          "firebase_node_path",
          res[0]?.user?.firebase_node_path
        );

        if (!localStorage.getItem("fcm_token"))
          localStorage.setItem("fcm_token", window.navigator.userAgent);
        localStorage.setItem("USER_TYPE", "user");
      } else if (res[0]?.user?.user_type == "super_admin") {
        localStorage.setItem("ActiveToken", res[0]?.extra?.access_token);
        navigate("/choose-account-type", { replace: true });
      }
    }
    if (err) toast.error(err);
  };

  const triggerAppleLoginPopup = async () => {
    const { accessToken, uid } = await signInWithApplePopup();
    if (accessToken && uid) {
      // console.log(accessToken, "accessApple");
      const invite_code = localStorage.getItem("invitationCode") || "";

      const requestData = {
        user: {
          apple_uid: uid,
          id_token: accessToken,
          invitation_code: invite_code,
        },
        device: {
          fcm_token: fcm_token ? fcm_token : userAgent,
        },
      };

      handleSocailLogin(requestData, "apple");
    }
  };
  const googleLogin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.idToken;
        const token = result?._tokenResponse?.idToken;
        const user = result.user;

        checkFirebaseToken();

        const requestData = {
          user: {
            google_id: user?.providerData[0].uid,
            id_token: token,
          },
          device: {
            uuid: "dummy",
            device_type: "web",
            fcm_token: fcm_token ? fcm_token : userAgent,
          },
        };

        handleSocailLogin(requestData, "google");
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <Grid container item xs={12} md={6} alignContent="center">
      <Grid
        container
        sx={{ display: { xs: "inherit", md: "none" } }}
        justifyContent="center"
        mb={4}
        mt={2.5}
      >
        <Grid container item xs={12} justifyContent="center">
          <img src={icons.UpArrow} alt="up arrow" />
        </Grid>
        <Typography
          sx={{ fontSize: "16px", fontWeight: "bold", cursor: "pointer" }}
          mb={2}
          onClick={() => navigate(-1)}
          mt={1}
        >
          {t("Login.skip")}
        </Typography>
        <Grid container item xs={12} justifyContent="center">
          <img src={images.Zigzag} />
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="center" mb={3}>
        <Grid
          width={{ xs: "213px", md: "316px" }}
          height={{ xs: "42px", md: "55.7px" }}
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={images.LogoLogin1}
            srcSet={`${images.LogoLogin2} 1700w`}
            alt="Logo"
            width={"100%"}
            height={"100%"}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="center" mb={2}>
        <Typography
          sx={{
            fontSize: { xs: "14px", md: "24px" },
            fontWeight: "500",
          }}
        >
          {t("Login.welcome")}
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="center" mb={2}>
        <Typography
          sx={{
            fontSize: { xs: "16px", md: "28px" },
            fontWeight: "bold",
          }}
        >
          {t("Login.login")}
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="center" mb={2}>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(t("validation.invalid_email"))
              .required(t("validation.email")),
            password: Yup.string().required(t("validation.password")),
            // .min(8, t("validation.password_short"))
          })}
          onSubmit={async (values) => {
            const data = {
              user: {
                email: values.email,
                password: values.password,
              },
              device: {
                uuid: "dummy",
                device_type: "web",
                fcm_token: fcm_token ? fcm_token : userAgent,
              },
            };
            await LoginApi(data);
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <Field
                as={TextFieldInput}
                type="email"
                name="email"
                placeholder={t("Login.email")}
                label={t("Login.email")}
                className="login-input"
                gridClass="grid-input"
              />
              <ErrorMessage
                name="email"
                component="div"
                style={{ color: "#d32f2f" }}
              />
              <Field
                as={TextFieldInput}
                type="password"
                name="password"
                placeholder={t("Login.password")}
                label={t("Login.password")}
                className="login-input"
                gridClass="grid-input"
                clickEnter={submitForm}
              />
              <ErrorMessage
                name="password"
                component="div"
                style={{ color: "#d32f2f" }}
              />
              <Grid container justifyContent="center">
                <PrimaryButton
                  type="submit"
                  disabled={isSubmitting}
                  text={t("Login.login")}
                  classBtn="orange"
                  style={{
                    marginTop: "32px",
                  }}
                  click={submitForm}
                  sx={{
                    fontSize: {
                      xs: "20px",
                      md: "24px",
                    },
                    borderRadius: {
                      xs: "100px",
                      md: "10px",
                    },
                    height: {
                      xs: "60px",
                      md: "72px",
                    },
                    width: {
                      xs: "320px",
                      md: "400px",
                    },
                  }}
                />
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid container item xs={12} justifyContent="center" mb={2}>
        <Typography
          sx={{
            fontSize: { xs: "14px", md: "20px" },
            fontWeight: "bold",
            cursor: "pointer",
          }}
          onClick={() => navigate("/forget-password")}
        >
          {t("Login.forget")}
        </Typography>
      </Grid>

      {/* <Grid container item xs={12} justifyContent="center" flexDirection="column" mb={2}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            color: "rgba(39, 39, 38, 0.4)",
            textAlign: "center"
          }}
        >
          or log in using
        </Typography>
        <Button
          onClick={login}
        >Google</Button> */}
      <Grid container item xs={12} justifyContent="center" mb={2}>
        <Grid
          item
          xs={12}
          mb={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="21px"
        >
          <Divider style={{ width: "109px", height: "1px" }} />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "rgba(39, 39, 38, 0.4)",
              textAlign: "center",
            }}
          >
            or log in using
          </Typography>
          <Divider style={{ width: "109px", height: "1px" }} />
        </Grid>

        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="8px"
          mb={`${signUpRef?.current?.clientHeight}px`}
        >
          <PrimaryButton
            text={"Google"}
            style={socialLoginButtonStyle}
            startIcon={<img src={icons.Google} alt="google-icon" />}
            click={googleLogin}
          />

          <PrimaryButton
            text={"Apple"}
            style={socialLoginButtonStyle}
            startIcon={<img src={icons.AppleIcon} alt="apple-icon" />}
            // click={appleLogin}
            click={triggerAppleLoginPopup}
          />
        </Grid>
      </Grid>

      {/* <Grid container item xs={12} justifyContent='center' mb={3}>
            <Divider textAlign='center' sx={{
                width: '50%'
            }}>
                {t("Login.log")}
            </Divider>
        </Grid>
        <Grid container item xs={12} mb={5}>
            <Grid container item xs={6} justifyContent='end' sx={{
                paddingInlineEnd: '4px'
            }}>
                <PrimaryButton text={t("Login.google")} classBtn='social-media-log' startIcon={<img src={icons.Google} alt='google' width='28px' height='28px' />} // click={submitForm}
                />
            </Grid>
            <Grid container item xs={6} sx={{
                paddingInlineStart: '4px'
            }}>
                <PrimaryButton text={t("Login.facebook")} classBtn='social-media-log' startIcon={<img src={icons.Facebook} alt='facebook' width='28px' height='28px' />} // click={submitForm}
                />
            </Grid>
        </Grid> */}
      <Grid
        container
        item
        md={12}
        justifyContent="center"
        sx={{ display: { xs: "none", md: "inherit" } }}
      >
        <PrimaryButton
          text={t("Login.create-account")}
          classBtn="white-btn"
          click={() => navigate("/signup")}
        />
      </Grid>
      <Grid
        ref={signUpRef}
        container
        item
        md={12}
        justifyContent="center"
        sx={{
          display: { xs: "inherit", md: "none" },
          bgcolor: "#fbfbfb",
          position: "fixed",
          bottom: 0,
        }}
        onClick={() => navigate("/signup")}
      >
        <Typography
          sx={{ fontSize: "16px", fontWeight: "bold", cursor: "pointer" }}
          mb={2}
          mt={1}
        >
          {t("Login.register")}
        </Typography>
        <Grid container item xs={12} justifyContent="center" mb={2}>
          <img src={icons.DownArrow} alt="down arrow" />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LoginStep1;
