import { Box, Button, Grid, Link, Stack, Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { icons, images } from "../../../Assets/AssetHelper.js";
import { allContacts } from "../../../Contexts/APIs/Lookups/Contacts.js";
import ContactModal from "../Modals/Footer/ContactModal.jsx";
import "./Layouts.css";
import DevelopedBy from "./developedBy.jsx";

const Footer = ({ t }) => {
  const navigate = useNavigate();
  const [openContact, setOpenContact] = useState(false);
  const [contacts, setContacts] = useState({});

  const userType = localStorage.getItem("USER_TYPE");

  const getContacts = async () => {
    const { res, err } = await allContacts({ map_by_key: true });
    res && setContacts(res.data.contacts);
    err && toast.error(err);
  };

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <>
      <Stack>
        <Grid
          container
          bgcolor="#272726"
          sx={{
            height: "auto",
            padding: { xs: "50px 15px 15px", md: "70px 100px 0px", lg: "70px 200px 0px" },
          }}
          className="footer"
        >
          <Grid
            container
            item
            xs={12}
            md={1}
            alignContent="center"
            sx={{
              justifyContent: {
                xs: "center",
                md: "flex-start",
              },
              marginBottom: {
                xs: "40px",
                md: "0px",
              },
            }}
          >
            <img
              src={images.Logofooter}
              alt="Logo Footer"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer", height: "120px" }}
            />
          </Grid>
          <Grid
            container
            item
            xs={4}
            md={2}
            justifyContent={{ xs: "center", md: "flex-end" }}
            sx={{ border: "1px solid transparent" }}
          >
            <Stack spacing={1} alignItems="flex-start">
              <Typography variant="h6" color="white">
                {t("HomePage.available")}
              </Typography>
              <Typography className="nav-link">{t("HomePage.egypt")}</Typography>
              <Typography className="nav-link">{t("HomePage.france")}</Typography>
              <Typography className="nav-link">{t("HomePage.italy")}</Typography>
              <Typography className="nav-link">{t("HomePage.uae")}</Typography>
              {/* <NavLink to='/' className="nav-link">
                  And 3 more
              </NavLink> */}
            </Stack>
          </Grid>
          <Grid
            container
            item
            xs={4}
            md={2}
            justifyContent={{ xs: "center", md: "flex-end" }}
            sx={{ border: "1px solid transparent" }}
          >
            <Stack spacing={1} alignItems="flex-start">
              <Typography variant="h6" color="white">
                {t("HomePage.explore_f")}
              </Typography>
              <NavLink to="/search/dive_trip" className="nav-link">
                {t("HomePage.dive_activities")}
              </NavLink>
              <NavLink to="/search/dive_course" className="nav-link">
                {t("Navbar.courses")}
              </NavLink>
              <NavLink to="/search/live_aboard" className="nav-link">
                {t("Navbar.liveboards")}
              </NavLink>
              <NavLink to="/dive-centers" className="nav-link">
                {t("HomePage.operators")}
              </NavLink>
              <NavLink to="/dive-experts" className="nav-link">
                {t("Navbar.dive_expert")}
              </NavLink>
              <NavLink to="https://blog.scubers.com/" target="_blank" rel="noopener noreferrer" className="nav-link">
                {t("Navbar.blogs")}
              </NavLink>
              {/* <NavLink to='/dive-experts' className="nav-link">
                        Experts
                    </NavLink> */}
            </Stack>
          </Grid>
          <Grid
            container
            item
            xs={4}
            md={2}
            justifyContent={{ xs: "center", md: "flex-end" }}
            sx={{ border: "1px solid transparent" }}
          >
            <Stack spacing={1} alignItems="flex-start">
              <Typography variant="h6" color="white">
                {t("HomePage.scubers")}
              </Typography>
              <NavLink to="/terms" className="nav-link">
                {t("Profile.terms")}
              </NavLink>
              <NavLink to="/privacy" className="nav-link">
                {t("Profile.privacy")}
              </NavLink>
              {/* <NavLink to='/' className="nav-link">
                        Cancelation policy
                    </NavLink> */}
              {/* <NavLink to='/' className="nav-link">
                        About scubers
                    </NavLink> */}
              {/* <NavLink to='/profile/support' className="nav-link">
              Contact us
            </NavLink> */}
              {/* <NavLink to='/profile/faqs' className="nav-link">
              FAQ
            </NavLink> */}
              {userType === "guest" ? (
                <Typography className="nav-link" sx={{ cursor: "pointer" }} onClick={() => setOpenContact(true)}>
                  {t("Profile.contact_us")}
                </Typography>
              ) : (
                <NavLink to="/profile/support" className="nav-link">
                  {t("Profile.contact_us")}
                </NavLink>
              )}
              <NavLink to="/faq" className="nav-link">
                {t("Profile.faq")}
              </NavLink>
            </Stack>
          </Grid>
          <Grid
            container
            item
            md={2}
            justifyContent="flex-end"
            display={{
              xs: "none",
              md: "flex",
            }}
            sx={{ border: "1px solid transparent" }}
          >
            <Stack spacing={1} alignItems="flex-start">
              <Typography variant="h6" color="white">
                {t("HomePage.social_media")}
              </Typography>
              <NavLink to={contacts?.facebook} target="_blank" className="nav-link">
                {t("HomePage.facebook")}
              </NavLink>
              <NavLink to={contacts?.twitter} target="_blank" className="nav-link">
                {t("HomePage.twitter")}
              </NavLink>
              <NavLink to={contacts?.instagram} target="_blank" className="nav-link">
                {t("HomePage.instagram")}
              </NavLink>
              <NavLink to={contacts?.youtube} target="_blank" className="nav-link">
                {t("HomePage.youtube")}
              </NavLink>
            </Stack>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={3}
            justifyContent={{ xs: "center", md: "center" }}
            sx={{ alignItems: "center", flexDirection: "column", mt: { xs: "10px", md: 0 } }}
          >
            <Stack sx={{ width: "57%", gap: 2 }}>
              <Stack>
                <Typography sx={{ fontFamily: "Catamaran", fontWeight: "500", fontSize: "16px", color: "#FFFFFF  " }}>
                  {t("Footer.join_us")}
                </Typography>
              </Stack>

              <Stack>
                <Link
                  style={{ textDecoration: "none" }}
                  href="http://join.scubers.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Stack
                    sx={{
                      backgroundColor: "#FAA100",
                      width: "100%",
                      fontFamily: "Catamaran",
                      color: "#FFFFFF  ",
                      py: 1.2,
                      flexDirection: "row",
                      gap: 1,
                      justifyContent: "center",
                      borderRadius: "16px",
                    }}
                  >
                    <Typography sx={{ dropShadow: "box-shadow: 0px 4px 4px 0px #00000040", fontWeight: "700", fontSize: "20px" }}>
                      {t("Footer.apply")}
                    </Typography>
                    <Box component="img" src={icons.Arrow} />
                  </Stack>
                </Link>
              </Stack>
            </Stack>
          </Grid>
          <Grid container item xs={12} justifyContent="center" alignItems="flex-end" mt={10}>
            <Typography variant="body2" color="white">
              {t("Footer.copyright")}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                marginInlineStart: "16px",
              }}
              color="#848383"
            >
              {t("Footer.copy")}
            </Typography>
          </Grid>
        </Grid>
        <DevelopedBy />
      </Stack>

      {openContact && (
        <ContactModal open={openContact} modalHead={t("Profile.contact_us")} handleClose={() => setOpenContact(false)} />
      )}
    </>
  );
};

export default Footer;

{
  /* {openTerms && (
        <TermsPrivacyModal
          open={openTerms}
          modalHead={t("Profile.terms")}
          modalText={termsConditions}
          handleClose={() => setOpenTerms(false)}
        />
      )}
      {openPrivacy && (
        <TermsPrivacyModal
          open={openPrivacy}
          modalHead={t("Profile.privacy")}
          modalText={privacyPolicy}
          handleClose={() => setOpenPrivacy(false)}
        />
      )}
      {openFaq && (
        <FAQModal open={openFaq} modalHead={t("Profile.frequently_asked_questions")} handleClose={() => setOpenFaq(false)} />
      )} */
}
