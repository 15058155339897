import { Dialog, Divider, Grid, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'

import { images } from "../../../../Assets/AssetHelper"

const HowToGetPointsModal = ({ t, open, handleClose, levels }) => {
    
    const levelsType = [
        {
            icon: images.Bronze,
            name: t("Profile.bronze"),
            number: levels?.find(item => item.rank === 1)?.reservations_required
        },
        {
            icon: images.Silver,
            name: t("Profile.silver"),
            number: levels?.find(item => item.rank === 2)?.reservations_required
        },
        {
            icon: images.Gold,
            name: t("Profile.gold"),
            number: levels?.find(item => item.rank === 3)?.reservations_required
        },
        {
            icon: images.Platinum,
            name: t("Profile.platinum"),
            number: levels?.find(item => item.rank === 4)?.reservations_required
        }
    ]
    const steps = [
        {
            icon: images.Step1,
            text: t("Profile.step1")
        }, {
            icon: images.Step2,
            text: t("Profile.step2")
        }, {
            icon: images.Step3,
            text: t("Profile.step3")
        }
    ]
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "16px",
                    height: 'auto',
                    width: '450px',
                    padding: '24px',
                    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.1)'
                }
            }}
        >

            <Grid container mb={3}>
                <Grid item xs={11}>
                    <Typography sx={{ fontSize: 'var(--medium-font)', fontWeight: 'bold', color: 'var(--font-color)' }}>
                        {t("Profile.how_points")}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
                </Grid>
            </Grid>

            <Divider />
            <Grid container mt={2}>
                <Grid item xs={12}>
                    <Typography sx={{
                        fontSize: 'var(--normal-font)',
                        fontWeight: '600',
                        color: 'var(--font-color)',
                        paddingBottom: '16px'
                    }}
                    >
                        {t("Profile.level_points")}
                    </Typography>
                </Grid>
                {levelsType.map((level, i) => {
                    return (
                        <Grid item xs={3} key={i}>
                            <Grid container item xs={12} justifyContent='center' mb={1}>
                                <img src={level.icon} alt='bronze' width='90px' height='90px' />
                            </Grid>
                            <Grid container item xs={12} justifyContent='center'>
                                <Typography sx={{
                                    fontSize: 'var(--normal-font)',
                                    fontWeight: '500',
                                    color: '#9a6820'
                                }}>
                                    {level.name}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} justifyContent='center'>
                                <Typography sx={{
                                    fontSize: 'var(--small-font)',
                                    fontWeight: '500',
                                    color: 'var(--font-color)'
                                }}>
                                    {level.number}{' '}{t("HomePage.booking_points")}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })}
                <Divider width='100%' sx={{ marginTop: '14px', marginBottom: '18px' }} />
                <Grid item xs={12}>
                    <Typography sx={{
                        fontSize: 'var(--normal-font)',
                        fontWeight: '600',
                        color: 'var(--font-color)',
                        paddingBottom: '16px'
                    }}
                    >
                        {t("Profile.level_benefits")}
                    </Typography>
                </Grid>
                {steps.map((step, i) => {
                    return (
                        <Grid container key={i} mb={i !== 2 && 4}>
                            <Grid container item xs={2} alignContent='center'>
                                <img src={step.icon} alt={`Step${i}`} width='60px' height='60px' />
                            </Grid>
                            <Grid container item xs={10} justifyContent='center' alignContent='center'>
                                <Typography sx={{
                                    fontSize: 'var(--text-font)',
                                    fontWeight: '500',
                                    color: 'var(--font-color)'
                                }}>
                                    {step.text}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Dialog>
    )
}

export default HowToGetPointsModal