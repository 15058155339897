import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import CarouselOrganism from "../../../Components/Organisms/Carousels/CarouselOrganism";
import ActionsPanel from "../../../Components/Organisms/Details/DetailsPanels/ActionsPanel";
import ReviewPanel from "../../../Components/Organisms/Details/DetailsPanels/ReviewPanel";
import { AddFavoriteAPI } from "../../../Contexts/APIs/Favorites/AddFavorite";
import { DeleteFavoriteAPI } from "../../../Contexts/APIs/Favorites/DeleteFavorite";
import { withTranslation } from "react-i18next";
import ShareModal from "../../../Components/Organisms/Modals/Details/ShareModal";
import BasicInfoMolecule from "../../../Components/Molecules/Profile/ViewProfile/BasicInfoMolecule";
import PhoneInput from "react-phone-input-2";
import { icons } from "../../../Assets/AssetHelper";
import "./ExpertDetails.css";

const ExpertDetailsTemplate = ({
  t,
  expertDetails,
  diveTrips,
  courses,
  liveaboards,
  reviews,
  getFavorites,
}) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleFavoriteChange = async () => {
    if (localStorage.getItem("ActiveToken")) {
      if (isFavorite) {
        await DeleteFavoriteAPI({
          favourite: {
            favourite_id: expertDetails?.id,
            favourite_type: "DiveExpert", // DiveActivity, DiveCenter, DiveExpert
          },
        });
        getFavorites({ page_size: 100 });
        setIsFavorite(!isFavorite);
      } else {
        await AddFavoriteAPI({
          favourite: {
            favourite_id: expertDetails?.id,
            favourite_type: "DiveExpert", // DiveActivity, DiveCenter, DiveExpert
          },
        });
        getFavorites({ page_size: 100 });
        setIsFavorite(!isFavorite);
      }
    } else toast.error(t("General.logged_in_to_add_fav"));
  };

  useEffect(() => {
    setIsFavorite(expertDetails?.is_favourite_by_current_user);
  }, [expertDetails]);
  // console.log(expertDetails, "expertDetails");

  const basicInfo = [
    {
      icon: icons.Birth,
      text: t("Profile.birth"),
      data: expertDetails?.birthdate,
      isNull: expertDetails?.birthdate === null,
    },
    {
      icon: icons.LocationIcon,
      text: t("Profile.location"),
      data: `${expertDetails?.area?.name},\u00A0${expertDetails?.city?.name},\u00A0${expertDetails?.country?.name}`,
      isNull:
        expertDetails?.country?.name === null ||
        expertDetails?.country?.name === undefined,
    },
    {
      icon: icons.Gender,
      text: t("Profile.gender"),
      data: expertDetails?.gender,
      isNull: expertDetails?.gender === null,
    },
    {
      icon: icons.PrimaryLanguage,
      text: t("Profile.primary_language"),
      data: expertDetails?.languages
        ?.map((lang) => lang?.language?.name)
        ?.toString()?.replaceAll(',',', '),
      isNull: expertDetails?.languages?.length === 0,
    },
  ];

  return (
    <Grid
      container
      mt={6}
      mb={6}
      gap={7.5}
      sx={{
        width: {
          xs: "100%",
          md: "90%",
        },
        marginX: "auto",
      }}
    >
      <Grid item xs={12} px={2}>
        <ActionsPanel
          name={expertDetails?.name}
          isFavorite={isFavorite}
          rating={parseFloat(expertDetails?.avg_rate)}
          number={
            expertDetails?.reviews_count || expertDetails?.number_of_reviews
          }
          avatar={expertDetails?.avatar}
          city={expertDetails?.city?.name}
          type="expert"
          handleFavoriteChange={handleFavoriteChange}
          lat={expertDetails?.lat}
          long={expertDetails?.long}
          handleOpen={handleOpen}
          displayLocation={false}
        />
      </Grid>
      {expertDetails?.about && (
        <Grid item xs={12} p={2}>
          <Typography
            sx={{
              color: "var(--font-color)",
              fontSize: "32px",
              fontWeight: "bold",
            }}
          >
            {t("General.about")}
          </Typography>
          <Typography sx={{ color: "var(--font-color)", fontSize: "18px" }}>
            {expertDetails?.about}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} p={2}>
        <Typography
          sx={{
            color: "var(--font-color)",
            fontSize: "32px",
            fontWeight: "bold",
            mb: "20px",
          }}
        >
          {t("General.about")}
        </Typography>
        <Grid container mb={3} xl={11}>
          {basicInfo?.map((item, i) => {
            return (
              <Grid
                container
                item
                xs={12}
                md={6}
                xl={4.5}
                key={i}
                mb={i !== 5 && 5}
              >
                <Grid container item width={"54px"} alignContent="center">
                  <img src={item.icon} alt="" />
                </Grid>
                <Grid
                  container
                  item
                  width={"calc(100% - 54px)"}
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: "var(--font-font)",
                        fontWeight: "600",
                        color: "#aaa",
                      }}
                    >
                      {item.text}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {i === 0 && item.isNull ? (
                      <Typography
                        sx={{
                          fontSize: "var(--medium-font)",
                          fontWeight: "bold",
                          color: item.isNull ? "#c21703" : "var(--font-color)",
                          wordBreak: "break-word",
                        }}
                      >
                        Not added
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "var(--medium-font)",
                          fontWeight: "bold",
                          color: item.isNull ? "#c21703" : "var(--font-color)",
                          wordBreak: "break-word",
                        }}
                      >
                        {item.isNull ? "Not added" : item.data}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      {expertDetails?.certificates?.length > 0 && (
        <Grid item xs={12} p={2}>
          <Typography
            sx={{
              color: "var(--font-color)",
              fontSize: "32px",
              fontWeight: "bold",
              mb: "20px",
            }}
          >
            {t("Profile.certificates")}
          </Typography>
          {expertDetails?.certificates?.map((certificate) => {
            return (
              <img
                src={certificate?.image}
                alt="certificate"
                key={certificate.id}
                style={{
                  width: "223px",
                  height: "140px",
                  marginInlineEnd: "21px",
                  borderRadius: "14px",
                }}
              />
            );
          })}
        </Grid>
      )}
      {diveTrips.length > 0 && (
        <CarouselOrganism
          t={t}
          type="card"
          items={diveTrips}
          heading={t("HomePage.dive_trips")}
          activity_type="dive_trip"
          customCarouselClass="expert_dive_trip"
          displaySeeAllBtn={false}
          fontSize={"32px"}
        />
      )}
      {courses.length > 0 && (
        <CarouselOrganism
          t={t}
          type="card"
          items={courses}
          heading={t("HomePage.courses")}
          activity_type="dive_course"
          customCarouselClass="expert_dive_course"
          displaySeeAllBtn={false}
          fontSize={"32px"}
        />
      )}
      {liveaboards.length > 0 && (
        <CarouselOrganism
          t={t}
          type="card"
          items={liveaboards}
          heading={t("Navbar.liveboards")}
          activity_type="live_aboard"
          customCarouselClass="liveaboards-carousel"
          displaySeeAllBtn={false}
          fontSize={"32px"}
        />
      )}
      <Grid item mt="10px" mb="30px" xs={12}>
        <ReviewPanel details={expertDetails} reviews={reviews} />
      </Grid>

      <ShareModal
        t={t}
        open={open}
        handleClose={handleClose}
        link={expertDetails?.profile_dynamic_link}
      />
    </Grid>
  );
};

export default withTranslation()(ExpertDetailsTemplate);
