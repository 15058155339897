import { Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { images } from "../../../../Assets/AssetHelper";

const Points = ({
  t,
  pointsConfig,
  pointsActions,
  pointsRate,
  profileData,
  getPointsActions,
  getPointsConfig,
}) => {
  const [customerSignup, setCustomerSignup] = useState();
  const [customerSignupInvitation, setCustomerSignupInvitation] = useState();
  const [customerReservation, setCustomerReservation] = useState();

  useEffect(() => {
    if (localStorage.getItem("ActiveUserType") === "customer") {
      setCustomerSignup(
        pointsConfig.find((item) => item.point_type === "customer_signup")
      );
      setCustomerSignupInvitation(
        pointsConfig.find(
          (item) => item.point_type === "customer_signup_invitaion"
        )
      );
      setCustomerReservation(
        pointsConfig.find((item) => item.point_type === "customer_reservation")
      );
    } else {
      setCustomerSignup(
        pointsConfig.find((item) => item.point_type === "dive_club_signup")
      );
      setCustomerSignupInvitation(
        pointsConfig.find(
          (item) => item.point_type === "dive_club_signup_invitation"
        )
      );
      setCustomerReservation(
        pointsConfig.find((item) => item.point_type === "dive_club_reservation")
      );
    }
  }, [pointsConfig]);

  const points = [
    {
      name: "Activity name",
      date: "20 Mar 2021",
      point: "+200",
    },
    {
      name: "Activity name",
      date: "20 Mar 2021",
      point: "+200",
    },
    {
      name: "Activity name",
      date: "20 Mar 2021",
      point: "+200",
    },
    {
      name: "Activity name",
      date: "20 Mar 2021",
      point: "+200",
    },
  ];
  const steps = [
    {
      icon: images.Step1,
      text: t("Profile.step1"),
    },
    {
      icon: images.Step2,
      text: t("Profile.step2"),
    },
    {
      icon: images.Step3,
      text: t("Profile.step3"),
    },
  ];

  useEffect(() => {
    getPointsActions();
    getPointsConfig();
  }, []);
  return (
    <Grid container>
      <Grid container item xs={12} mb={1}>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "800",
            color: "var(--font-color)",
            marginInlineStart: "20px",
          }}
        >
          {t("Profile.points")}
        </Typography>
      </Grid>
      {/* total points */}

      <Grid container item xs={12} my={2}>
        <Grid
          item
          xs={12}
          md={6.75}
          sx={{
            border: "solid 1px #ececec",
            borderRadius: "8px",
            height: "auto",
            padding: "18px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "var(--font-color)",
              }}
            >
              {t("Profile.available_points")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "bold",
                color: "var(--primary-color)",
              }}
            >
              {profileData?.available_points} {t("Profile.points")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid
          item
          xs={12}
          md={6.75}
          sx={{
            border: "solid 1px #ececec",
            borderRadius: "8px",
            height: "auto",
            padding: "18px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "var(--font-color)",
              }}
            >
              {t("Profile.history_points")}
            </Typography>
          </Grid>
          {pointsActions?.length > 0 &&
            pointsActions?.map((point, i) => {
              return (
                <Grid container item xs={12} key={i} mt={2}>
                  <Grid item xs={10}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "var(--font-color)",
                      }}
                    >
                      {point.action_source === "customer_signup" ||
                      point.action_source === "dive_club_signup"
                        ? t("Profile.create")
                        : point.action_source === "customer_signup_invitaion" ||
                          point.action_source === "dive_club_signup_invitation"
                        ? t("Profile.invite")
                        : point.action_source === "customer_reservation" ||
                          point.action_source === "dive_club_reservation"
                        ? t("Profile.activity")
                        : ""}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#848383",
                      }}
                    >
                      {point.date.slice(0, 10)}
                    </Typography>
                  </Grid>
                  <Grid container item xs={2} alignContent="center">
                    {point.action_type === "add" ? (
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#3ed181",
                        }}
                      >
                        +{point.amount}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "var(--large-font)",
                          fontWeight: "600",
                          color: "#fa0000",
                        }}
                      >
                        -{point.amount}
                      </Typography>
                    )}
                  </Grid>
                  {i !== pointsActions?.length - 1 && (
                    <Divider width="100%" sx={{ marginTop: "18px" }} />
                  )}
                </Grid>
              );
            })}
        </Grid>
        <Grid item md={0.5}></Grid>
        <Grid item xs={12} md={4.75} mt={{ xs: 1 }}>
          <Grid
            container
            mb={1.5}
            sx={{
              border: "solid 1px #ececec",
              borderRadius: "8px",
              height: "auto",
              padding: "12px",
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "var(--normal-font)",
                  fontWeight: "600",
                  color: "var(--font-color)",
                  paddingBottom: "16px",
                }}
              >
                {t("Profile.points_benefits")}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid container item xs={2} alignContent="center">
                <img
                  src={images.PointsLarge}
                  alt={`points`}
                  width="50px"
                  height="50px"
                />
              </Grid>
              <Grid
                container
                item
                xs={10}
                justifyContent="center"
                alignContent="center"
                sx={{ paddingInlineStart: "10px" }}
              >
                <Typography
                  sx={{
                    fontSize: "var(--text-font)",
                    fontWeight: "500",
                    color: "var(--font-color)",
                  }}
                >
                  {t("Profile.every")}
                  {pointsRate}
                  {t("Profile.rate1")}
                  {customerReservation?.per_type}
                  {t("Profile.rate2")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              border: "solid 1px #ececec",
              borderRadius: "8px",
              height: "auto",
              padding: "12px",
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "var(--normal-font)",
                  fontWeight: "600",
                  color: "var(--font-color)",
                  paddingBottom: "16px",
                }}
              >
                {t("Profile.how_to")}
              </Typography>
            </Grid>
            {steps.map((step, i) => {
              return (
                <Grid container key={i} mb={i !== 2 && 4}>
                  <Grid container item xs={2} alignContent="center">
                    <img
                      src={step.icon}
                      alt={`Step${i}`}
                      width="50px"
                      height="50px"
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={10}
                    sx={{ paddingInlineStart: "10px" }}
                  >
                    {i === 0 && (
                      <Typography
                        sx={{
                          fontSize: "var(--text-font)",
                          fontWeight: "500",
                          color: "#f3b229",
                        }}
                        display="inline"
                      >
                        {customerSignup?.num_of_points} {t("Profile.points")}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        fontSize: "var(--text-font)",
                        fontWeight: "500",
                        color: "var(--font-color)",
                      }}
                      display="inline"
                    >
                      {step.text}
                    </Typography>
                    {i === 1 && (
                      <Typography
                        sx={{
                          fontSize: "var(--text-font)",
                          fontWeight: "500",
                          color: "#f3b229",
                        }}
                        display="inline"
                      >
                        {"("}
                        {customerSignupInvitation?.num_of_points}{" "}
                        {t("Profile.signup")}
                      </Typography>
                    )}
                    {i === 2 && (
                      <Typography
                        sx={{
                          fontSize: "var(--text-font)",
                          fontWeight: "500",
                          color: "#f3b229",
                        }}
                        display="inline"
                      >
                        {"("}
                        {customerReservation?.num_of_points}{" "}
                        {t("Profile.reservation")}
                        {customerReservation?.per_type}
                        {" )"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Points;
