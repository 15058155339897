import HomePageBg1x from "./Images/HomePageBg-1x.png";
// import HomePageBg2x from "./Images/HomePageBg-2x.png"
import LoginBg from "./Images/loginBg.png";
import Dive from "./Images/dive.png";
import DiveWB from "./Images/diveW&B.png";
import Courses from "./Images/courses.png";
import CoursesWB from "./Images/coursesW&B.png";
import Liveboard from "./Images/liveboard.png";
import LiveboardWB from "./Images/liveboardW&B.png";
import Support from "./Images/support.png";
import Booking from "./Images/booking.png";
import Activities from "./Images/activities.png";
import Mobile from "./Images/mobile.png";
import Apple from "./Images/apple.png";
import Android from "./Images/android.png";
import ForgetPassword from "./Images/forget-password.png";
import ScubersIcon from "./Icons/scubersIcon.png";
import VerificationCode from "./Images/verificationcode.png";
import UserIcon from "./Icons/usericon.svg";
import Logo from "./Images/logo.svg";
import Logofooter from "./Images/logofooter.png";
import Bronze from "./Images/bronze.svg";
import Silver from "./Images/silver.svg";
import Gold from "./Images/gold.svg";
import Platinum from "./Images/platinum.svg";
import Step1 from "./Images/step1.svg";
import Step2 from "./Images/step2.svg";
import Step3 from "./Images/step3.svg";
import Location from "./Icons/location.svg";
import LocationOrangeIcon from "./Icons/locationOrange.svg";
import LocationBlackIcon from "./Icons/locationBlack.svg";
import ClockIcon from "./Icons/clock.svg";
import LogoLogin from "./Icons/logo.svg";
import Facebook from "./Icons/facebook.svg";
import Google from "./Icons/google.svg";
import LocationOrange from "./Icons/location-orange.svg";
import Calender from "./Icons/calender.svg";
import Customer from "./Icons/customer.svg";
import DiveClub from "./Icons/dive-club.svg";
import Freelancer from "./Icons/freelancer.svg";
import BackArrow from "./Icons/backArrow.svg";
import BookingIcon from "./Icons/booking.svg";
import SearchDiveExpert from "./Icons/search-dive-expert.svg";
import SearchLocation from "./Icons/search-location.svg";
import SearchDiveCenter from "./Icons/serach-dive-center.svg";
import LocationBtn from "./Icons/locationBtn.svg";
import ShareIcon from "./Icons/share.svg";
import LikeIcon from "./Icons/like.svg";
import GoArrow from "./Icons/go-arrow.svg";
import CancelTrip from "./Icons/cancel.svg";
import TripDate from "./Icons/date.svg";
import TripLang from "./Icons/languages.svg";
import TripRight from "./Icons/right.svg";
import TripTicket from "./Icons/tickets.svg";
import GreenCheck from "./Icons/greencheck.svg";
import redCircel from "./Icons/redcircle.svg";
import UsersIcon from "./Icons/users.svg";
import PickupIcon from "./Images/pickup-icon.png";
import FavouriteNo from "./Icons/favourite.svg";
import ProfileOrange from "./Icons/profile_orange.svg";
import Profile from "./Icons/profile.svg";
import Invite from "./Icons/invite.svg";
import InviteOrange from "./Icons/invite_orange.svg";
import Settings from "./Icons/settings.svg";
import SettingsOrange from "./Icons/settings_orange.svg";
import Terms from "./Icons/terms.svg";
import Privacy from "./Icons/privacy.svg";
import PrivacyOrange from "./Icons/privacy_orange.svg";
import Help from "./Icons/help.svg";
import Covid from "./Icons/covid.svg";
import Phone from "./Icons/mobile.svg";
import Email from "./Icons/email.svg";
import Birth from "./Icons/birth.svg";
import LocationIcon from "./Icons/location-icon.svg";
import Gender from "./Icons/gender.svg";
import GoldIcon from "./Icons/gold.svg";
import GoldWhite from "./Icons/gold-white.svg";
import Camera from "./Icons/camera.svg";
import AddIcon from "./Icons/add_icon.svg";
import Upload from "./Icons/upload.svg";
import Delete from "./Icons/delete.svg";
import Notification from "./Icons/notification.svg";
import LanguageOrange from "./Icons/language_orange.svg";
import Language from "./Icons/language.svg";
import AddAccount from "./Icons/add_account.svg";
import AccountDeleted from "./Icons/account_deleted.svg";
import CurrencyOrange from "./Icons/currency_orange.svg";
import Currency from "./Icons/currency.svg";
import PlatinumIcon from "./Icons/diamond.svg";
import SilverIcon from "./Icons/silver.svg";
import BronzeIcon from "./Icons/bronze.svg";
import Cart from "./Icons/cart.svg";
import DeleteBtn from "./Icons/delete-btn.svg";
import ClockSmall from "./Icons/clock-small.svg";
import Stop from "./Icons/stop.svg";
import CalenderSmall from "./Icons/calender-small.svg";
import Person from "./Icons/person.svg";
import Success from "./Images/success.svg";
import Fail from "./Images/fail.svg";
import BookingLarge from "./Icons/booking-large.svg";
import Position from "./Icons/position.svg";
import Dimensions from "./Icons/dimensions.svg";
import Build from "./Icons/build.svg";
import Wifi from "./Icons/wifi.svg";
import Cabins from "./Icons/cabins.svg";
import Equipment from "./Icons/equipment.svg";
import Nitrox from "./Icons/nitrox.svg";
import FavouriteYes from "./Icons/fav.svg";
import Heart from "./Icons/heart.svg";
import Empty from "./Icons/empty.svg";
import LikeYes from "./Icons/like-yes.svg";
import QrSmall from "./Icons/qr.svg";
import PrimaryLanguage from "./Icons/primary-language.svg";
import Points from "./Icons/points.svg";
import PointsOrange from "./Icons/points-orange.svg";
import PointsLarge from "./Images/points.svg";
import Website from "./Icons/website.svg";
import FromTo from "./Icons/from_to.svg";
import DiveTrip from "./Icons/dive_trip.svg";
import DiveClubIcon from "./Icons/diveClub.svg";
import CustomerIcon from "./Icons/customer-icon.svg";

import DiveCourse from "./Icons/dive_course.svg";
import Liveaboard from "./Icons/liveaboard.svg";
import Complains from "./Icons/complains.svg";
import Report from "./Icons/report.svg";
import Edit from "./Icons/edit.svg";
import ProfileSupport from "./Icons/support.svg";
import Faq from "./Icons/faq.svg";
import NeedConfirm from "./Icons/need-confirm.png";
import Visa from "./Images/visa.png";
import BookingConfirmed from "./Icons/booking_confirmed.svg";
import WaitingConfirmed from "./Icons/waiting_confirmation.svg";
import Cancelled from "./Icons/cancelled.svg";
import DetailsLocation from "./Icons/details-location.svg";
import DetailsDirection from "./Icons/details-direction.svg";
import Payment from "./Icons/payment.svg";
import PaymentBlack from "./Icons/payment-black.svg";
import SendButton from "./Icons/send-button.svg";
import Reservation from "./Icons/reservation.svg";
import MobileResponsive from "./Images/mobile-reponsive.png";
import UserIconResponsive from "./Icons/usericon-responsive.svg";
import DiveTripResponsive from "./Icons/dive-trip-responsive.svg";
import LiveaboardResponsive from "./Icons/liveaboard-responsive.svg";
import DiveCenterResponsive from "./Icons/dive-center-responsive.svg";
import DiveExpertResponsive from "./Icons/dive-expert-responsive.svg";
import CoursesResponsive from "./Icons/courses-responsive.svg";
import FavoritesResponsive from "./Icons/favorites-responsive.svg";
import CartResponsive from "./Icons/cart-responsive.svg";
import Switch from "./Icons/switch.svg";
import Logout from "./Icons/logout.svg";
import ScubersIcon2x from "./Icons/scubersIcon@2x.png";
import Zigzag from "./Images/zigzagline.svg";
import LogoLogin1 from "./Images/LogoLogin.png";
import LogoLogin2 from "./Images/LogoLogin@2x.png";
import UpArrow from "./Icons/up-arrow.svg";
import DownArrow from "./Icons/down-arrow.svg";
import Fish from "./Images/fish.svg";
import FavoriteResponsive from "./Icons/favorites-resposive.svg";
import Filter from "./Icons/filter.svg";
import Sort from "./Icons/sort.svg";
import ShareOrange from "./Icons/share-orange.svg";
import CheckMark from "./Icons/check-mark.svg";
import NotInclude from "./Icons/not-include.svg";
import HeartResposive from "./Icons/heart-responsive.svg";
import GuidedDives from "./Icons/guided-dives.svg";
import ChairWheel from "./Icons/chair-wheel.svg";
import notificationDiveTrip from "./Icons/notification-dive-trip.svg";
import notificationLiveaboard from "./Icons/notification-liveaboard.svg";
import notificationDiveCourse from "./Icons/notification-dive-course.svg";
import notificationIcon from "./Icons/notification-icon.svg";
import ChangePasswordSuccess from "./Icons/change-password-success.svg";

import ReservationComplain from "./Icons/complain.svg";
import ReservationComplainResponsive from "./Icons/complaint-responsive.svg";
import CrossMark from "./Icons/cross-mark.svg";
import notificationOrange from "./Icons/notification-orange.svg";
import EmptyCart from "./Icons/empty-cart.svg";
import AppleIcon from "./Icons/apple.svg";
import TwitterX from "./Icons/twitterX.svg";
import Avatar from "./Icons/avatar.svg";
import PointsNotification from "./Icons/points-notifcation.svg";

import LevelNotification from "./Icons/Level-notification.svg";
import InvitationNotification from "./Icons/Invitation-notification.svg";
import Arrow from "./Icons/Arrow.svg";
import blogs from "./Icons/blogs.svg";

import DeleteAccount from "./Images/DeleteAccount.png";
import Exclamationmark from "./Images/Exclamationmark.svg";
import SuccessDelete from "./Images/SuccessDelete.svg";
import NotFound from "./Images/NotFound.svg";

export const images = {
  Logo,
  Logofooter,
  HomePageBg1x,

  Dive,
  DiveWB,
  Courses,
  CoursesWB,
  Liveboard,
  LiveboardWB,
  Support,
  Booking,
  Activities,
  Mobile,
  Apple,
  Android,
  LoginBg,
  VerificationCode,
  ForgetPassword,
  PickupIcon,
  Bronze,
  Silver,
  Gold,
  Platinum,
  Step1,
  Step2,
  Step3,
  Success,
  Fail,
  PointsLarge,
  Visa,
  MobileResponsive,
  Zigzag,
  LogoLogin1,
  LogoLogin2,
  Fish,
};

export const icons = {
  LevelNotification,
  InvitationNotification,
  PointsNotification,
  EmptyCart,
  ReservationComplain,
  ReservationComplainResponsive,
  DiveClubIcon,
  CustomerIcon,
  UserIcon,
  ScubersIcon,
  Location,
  LocationOrangeIcon,
  ClockIcon,
  LocationBlackIcon,
  LogoLogin,
  Facebook,
  Google,
  LocationOrange,
  Calender,
  Customer,
  DiveClub,
  Freelancer,
  BackArrow,
  BookingIcon,
  SearchDiveExpert,
  SearchLocation,
  SearchDiveCenter,
  LocationBtn,
  ShareIcon,
  LikeIcon,
  GoArrow,
  CancelTrip,
  TripDate,
  TripLang,
  TripRight,
  TripTicket,
  GreenCheck,
  redCircel,
  UsersIcon,
  FavouriteNo,
  ProfileOrange,
  Profile,
  Invite,
  InviteOrange,
  Settings,
  SettingsOrange,
  Terms,
  Privacy,
  PrivacyOrange,
  Help,
  Covid,
  Phone,
  Email,
  Birth,
  LocationIcon,
  Gender,
  GoldIcon,
  GoldWhite,
  Camera,
  AddIcon,
  Upload,
  Delete,
  Notification,
  LanguageOrange,
  Language,
  AddAccount,
  AccountDeleted,
  CurrencyOrange,
  Currency,
  PlatinumIcon,
  SilverIcon,
  BronzeIcon,
  Cart,
  DeleteBtn,
  ClockSmall,
  Stop,
  CalenderSmall,
  Person,
  BookingLarge,
  Position,
  Dimensions,
  Build,
  Wifi,
  Cabins,
  Equipment,
  Nitrox,
  FavouriteYes,
  Heart,
  Empty,
  LikeYes,
  QrSmall,
  PrimaryLanguage,
  Points,
  PointsOrange,
  Website,
  FromTo,
  DiveTrip,
  DiveCourse,
  Liveaboard,
  Complains,
  Report,
  Edit,
  ProfileSupport,
  Faq,
  NeedConfirm,
  BookingConfirmed,
  WaitingConfirmed,
  Cancelled,
  DetailsLocation,
  DetailsDirection,
  Payment,
  PaymentBlack,
  GuidedDives,
  ChairWheel,
  SendButton,
  Reservation,
  UserIconResponsive,
  DiveTripResponsive,
  LiveaboardResponsive,
  DiveCenterResponsive,
  DiveExpertResponsive,
  CoursesResponsive,
  FavoritesResponsive,
  CartResponsive,
  Switch,
  Logout,
  ScubersIcon2x,
  UpArrow,
  DownArrow,
  FavoriteResponsive,
  Filter,
  Sort,
  ShareOrange,
  CheckMark,
  NotInclude,
  HeartResposive,
  notificationDiveTrip,
  notificationLiveaboard,
  notificationDiveCourse,
  notificationIcon,
  ChangePasswordSuccess,
  CrossMark,
  notificationOrange,
  AppleIcon,
  TwitterX,
  Avatar,
  Arrow,
  blogs,
  DeleteAccount,
  Exclamationmark,
  SuccessDelete,
  NotFound,
};
