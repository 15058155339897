import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import OrdersTemplate from "../../../Templates/Main/Reservation/Orders.Template.jsx";
import { ReviewCreateAPI } from "../../../Contexts/APIs/Reviews/CreateReview.js";
import { AddEditCartItemAPI } from "../../../Contexts/APIs/Carts/AddEditCartItem.js";
import { DeleteOrderAPI } from "../../../Contexts/APIs/Orders/DeleteOrder.js";
import { PayOrderAPI } from "../../../Contexts/APIs/Orders/PayOrder.js";
import { isEmptyObject } from "../../../Contexts/APIs/Helper/Helper.js";
import { withTranslation } from "react-i18next";
import Loader from "../../../Components/Atoms/Loader/Loader.js";

const OrdersPage = ({ t, orders, getOrders, ordersPage, setOrdersPage, ordersCount, ordersOpenLoader, ordersPaginationCount }) => {
  const navigate = useNavigate();

  const [filterObj, setFilterObj] = useState({
    'page_number': 1,
    'page_size': 12,
    'filter_scope': 'all'
  });

  const handlePageChange = (event, value) => {
    setOrdersPage(value);
    setFilterObj({...filterObj, 'page_number': value })
  }

  const handleFilterScopeChange = (value) => {
    setFilterObj({...filterObj, 'filter_scope': value, 'page_number': 1 })
    setOrdersPage(1);
  }

  const createReview = async (orderId, itemId, data) => {
    console.log('createReview')
    const { res, err } = await ReviewCreateAPI(orderId, itemId, data);
    if (res){
      getOrders(filterObj);  
      return res;
    } 
    if (err) toast.error(err);
  };

  const addEditCart = async (data) => {
    const { res, err } = await AddEditCartItemAPI(data);
    if (res) navigate("/cart");
    if (err) toast.error(err);
  };

  const deleteOrder = async (id) => {
    const { res, err } = await DeleteOrderAPI(id, {
      payment_transaction: {
        status: "cancelled",
      },
    });
    if (res) getOrders({});
    if (err) toast.error(err);
  };

  const payOrder = async (id) => {
    const { res, err } = await PayOrderAPI(id);
    if (res) {
      if (isEmptyObject(res?.data?.payment_session)) {
        navigate("/orders");
      } else {
        window.location.replace(res?.data?.payment_session?.payment_url);
      }
    }
    if (err) toast.error(err);
  };

  useEffect(() => {
    getOrders(filterObj);
  }, [filterObj])


  return (
    <>
      <Loader open={ordersOpenLoader} />
      <OrdersTemplate
        t={t}
        orders={orders}
        getOrders={getOrders}
        createReview={createReview}
        deleteOrder={deleteOrder}
        addEditCart={addEditCart}
        payOrder={payOrder}
        handleFilterScopeChange={handleFilterScopeChange}
        page={ordersPage}
        count={ordersPaginationCount}        
        handlePageChange={handlePageChange}
      />
    </>
  );
};

export default withTranslation()(OrdersPage);
