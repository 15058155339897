import { Grid, useMediaQuery, useTheme, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import CarouselOrganism from "../../../Components/Organisms/Carousels/CarouselOrganism.jsx";
import ActionsPanel from "../../../Components/Organisms/Details/DetailsPanels/ActionsPanel.jsx";
import BoatPanel from "../../../Components/Organisms/Details/DetailsPanels/BoatPanel.jsx";
import BookPanel from "../../../Components/Organisms/Details/DetailsPanels/BookPanel.jsx";
import InfoPanel from "../../../Components/Organisms/Details/DetailsPanels/InfoPanel.jsx";
import PackageDescription from "../../../Components/Organisms/Details/DetailsPanels/PackageDescription.jsx";
import RatingPanel from "../../../Components/Organisms/Details/DetailsPanels/RatingPanel.jsx";
import TripDetails from "../../../Components/Organisms/Details/DetailsPanels/TripDetails.jsx";
import GridGallery from "../../../Components/Organisms/Details/Gallery/Gallery";
// import { BoatFeaturesPanel } from "../../../Components/Organisms/Details/DetailsPanels/BoatFeaturesPanel";
import LocationPanel from "../../../Components/Organisms/Details/DetailsPanels/LocationPanel.jsx";
import QrPanel from "../../../Components/Organisms/Details/DetailsPanels/QrPanel.jsx";
import Requirments from "../../../Components/Organisms/Details/DetailsPanels/Requirments.jsx";
import { AddFavoriteAPI } from "../../../Contexts/APIs/Favorites/AddFavorite.js";
import { DeleteFavoriteAPI } from "../../../Contexts/APIs/Favorites/DeleteFavorite.js";
import ShareModal from "../../../Components/Organisms/Modals/Details/ShareModal.jsx";
import ComplainstModal from "../../../Components/Organisms/Modals/Details/Complainst.jsx";

import DetailsResponsive from "../../../Components/Organisms/Responsive/DetailsResponsive.jsx";
import { icons } from "../../../Assets/AssetHelper.js";
import ReviewModal from "../../../Components/Organisms/Modals/Reviews/ReviewModal.jsx";

const DetailsViewTemplate = ({
  t,
  diveDetails,
  activitiesList,
  addEditCart,
  getFavorites,
  mapKey,
  deleteOrder,
  isFromBooking,
  orderId,
  quantity,
  qrCode,
  status,
  statusDisplay,
  center,
  setCenter,
  getRemainingSeats,
  remainingSeats,
  payment,
  startDate,
  endDate,
  startTime,
  paymentLookupkey,
  orderItemId,
  cancellationPolicyMsg,
  payOrder,
  createReview,
  orderData,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [isFavorite, setIsFavorite] = useState(false);
  const [open, setOpen] = useState(false);
  const [openComplaint, setOpenComplaint] = useState(false);

  const [openReview, setOpenReview] = useState(false);
  const [reviewOrderId, setReviewOrderId] = useState();
  const [itemId, setItemId] = useState();

  const [userPosition, setUserPosition] = useState({ lat: "", lng: "" });
  const [destination, setDestination] = useState({ lat: "", lng: "" });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloseReview = () => setOpenReview(false);
  const handleClickReview = (order, item) => {
    setReviewOrderId(order);
    setItemId(item);
    setOpenReview(true);
  };

  const handleOpenComplaint = () => {
    // console.log("first");
    setOpenComplaint(true);
  };
  const handleCloseComplaint = () => setOpenComplaint(false);
  const showInMapClicked = () => {
    window.open(
      "https://maps.google.com?q=" +
        diveDetails?.diving_location?.lat +
        "," +
        diveDetails?.diving_location?.long
    );
  };

  const handleFavoriteChange = async () => {
    if (localStorage.getItem("ActiveToken")) {
      if (isFavorite) {
        await DeleteFavoriteAPI({
          favourite: {
            favourite_id: diveDetails?.id,
            favourite_type: "DiveActivity", // DiveActivity, DiveCenter, DiveExpert
          },
        });
        getFavorites({ page_size: 100 });
        setIsFavorite(!isFavorite);
      } else {
        await AddFavoriteAPI({
          favourite: {
            favourite_id: diveDetails?.id,
            favourite_type: "DiveActivity", // DiveActivity, DiveCenter, DiveExpert
          },
        });
        getFavorites({ page_size: 100 });
        setIsFavorite(!isFavorite);
      }
    } else toast.error(t("General.logged_in_to_add_fav"));
  };

  useEffect(() => {
    if (diveDetails?.pickup_option !== "hotel_pickup") {
      setDestination({
        lat: diveDetails?.pickup_location?.lat,
        lng: diveDetails?.pickup_location?.long,
      });
    }
  }, [diveDetails]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setUserPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  useEffect(() => {
    setIsFavorite(diveDetails?.is_favourite_by_current_user);
    localStorage.setItem('StaticEndDatePicker', diveDetails?.end_date);
  }, [diveDetails]);

  return isMd && !isFromBooking ? (
    <>
      <DetailsResponsive
        isMd={isMd}
        isFavorite={isFavorite}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        showInMapClicked={showInMapClicked}
        handleFavoriteChange={handleFavoriteChange}
        t={t}
        diveDetails={diveDetails}
        isFromBooking={isFromBooking}
        remainingSeats={remainingSeats}
        getRemainingSeats={getRemainingSeats}
        addEditCart={addEditCart}
        showReservationComplaint={handleOpenComplaint}
      />
      <ComplainstModal
        open={openComplaint}
        handleClose={handleCloseComplaint}
        diveDetails={diveDetails}
      />
    </>
  ) : (
    <>
      <Box className="mb-30px mt-30px">
        <GridGallery slider={diveDetails?.media} />
      </Box>
      <Grid
        container
        sx={{
          width: {
            xs: "100%",
            md: "90%",
          },
        }}
        style={{ margin: "auto" }}
      >
        <ActionsPanel
          name={diveDetails?.name}
          isFavorite={isFavorite}
          type="details"
          link={diveDetails?.invitation_link}
          showInMapClicked={showInMapClicked}
          handleFavoriteChange={handleFavoriteChange}
          handleOpen={handleOpen}
          diveDetails={diveDetails}
          showReservationComplaint={handleOpenComplaint}
        />
        <Grid
          xl={12}
          item
          container
          rowSpacing={3}
          columnSpacing={3}
          className="mb-30px"
        >
          <Grid item lg={8} md={7} sm={6}>
            {isFromBooking && (
              <>
                <LocationPanel
                  t={t}
                  location={diveDetails?.pickup_location}
                  center={center}
                  setCenter={setCenter}
                  mapKey={mapKey}
                  userPosition={userPosition}
                  destination={destination}
                />
                <QrPanel
                  t={t}
                  qrCode={qrCode}
                  qrCodeMsg={
                    statusDisplay?.display_name ===
                    "Waiting for payment confirmation"
                      ? t("Details.pay_first")
                      : null
                  }
                  hideQrCode={
                    statusDisplay?.display_name !== "Waiting for check in"
                      ? true
                      : null
                  }
                />
              </>
            )}
            <RatingPanel /* Dive Center Bar */
              t={t}
              diveCenter={diveDetails?.owner}
              diveDetails={diveDetails}
            />
            <BoatPanel t={t} diveDetails={diveDetails} />
            {/* Boat Featured Panel cancelled */}
            {/* <BoatFeaturesPanel t={t} boatFeatures={diveDetails?.boat?.boat_features} />
                            </>
                        } */}
            <TripDetails t={t} diveDetails={diveDetails} />
            <Requirments t={t} diveDetails={diveDetails} />
            <PackageDescription /* Package description */
              t={t}
              includes={diveDetails?.activity_price_includes}
              excludes={diveDetails?.activity_price_excludes}
            />
            {diveDetails?.activity_type === "live_aboard" && (
              <InfoPanel /* Boat additional Features */
                title={t("Details.boat")}
                body={diveDetails?.boat?.boat_features}
              />
            )}
            <InfoPanel /* What to expect */
              title={t("Details.description")}
              body={diveDetails?.description}
            />
            <InfoPanel /* What to expect */
              title={t("Details.expect")}
              body={diveDetails?.client_expectation}
            />
            <InfoPanel /* Cancelation policy */
              title={t("Details.policy")}
              body={diveDetails?.cancellation_policy}
            />
            <InfoPanel /* Confirmation criteria */
              title={t("Details.criteria")}
              body={diveDetails?.confirmation_text}
            />
            <InfoPanel /* Pickup info */
              title={t("Details.pickup")}
              body={diveDetails?.pickup_info}
            />
            <InfoPanel /* Other info */
              title={t("Details.other")}
              body={diveDetails?.other_information}
            />
          </Grid>
          <Grid item lg={4} md={5} sm={6}>
            <BookPanel
              t={t}
              diveDetails={diveDetails}
              addEditCart={addEditCart}
              deleteOrder={deleteOrder}
              orderId={orderId}
              quantity={quantity}
              isFromBooking={isFromBooking}
              status={status}
              statusDisplay={statusDisplay}
              getRemainingSeats={getRemainingSeats}
              remainingSeats={remainingSeats}
              payment={payment}
              startDate={startDate}
              endDate={endDate}
              startTime={startTime}
              paymentLookupkey={paymentLookupkey}
              orderItemId={orderItemId}
              cancellationPolicyMsg={cancellationPolicyMsg}
              payOrder={payOrder}
              handleClickReview={handleClickReview}
              orderData={orderData}
            />
          </Grid>
        </Grid>
        <CarouselOrganism
          t={t}
          type="card"
          items={activitiesList}
          heading={`${t("More dive activities in ")}${
            diveDetails?.diving_location?.area?.name
          }`}
          width="100%"
          getFavorites={getFavorites}
        />
      </Grid>
      <ShareModal
        t={t}
        open={open}
        handleClose={handleClose}
        link={diveDetails?.invitation_link}
      />
      <ComplainstModal
        open={openComplaint}
        handleClose={handleCloseComplaint}
        diveDetails={diveDetails}
      />
      <ReviewModal
        t={t}
        open={openReview}
        handleClose={handleCloseReview}
        createReview={createReview}
        orderId={reviewOrderId}
        itemId={itemId}
      />
    </>
  );
};

export default DetailsViewTemplate;
