// var En = false
// function getEnglishLanguage() {
//     if(localStorage.getItem("lang") === "en"){
//         En = true;
//     }
// };
// getEnglishLanguage();

export function makeFilterString(filter_obj){
    var filterString="?"
    Object.keys(filter_obj).map(function(key) {
        if ( filter_obj[key] ) {
            filterString += key + "="+filter_obj[key] +"&"
        }
    })
    if (filterString.substr(filterString.length - 1) === '&'){
        filterString = filterString.slice(0, -1);
    }
    return filterString
}

// export const DaysList = [
//     {
//         name: En ? "Saturday" : "السبت",
//         id: "Saturday"
//     },
//     {
//         name: En ? "Sunday" : "الأحد",
//         id: "Sunday"
//     },
//     {
//         name: En ? "Monday" : "الاثنين",
//         id: "Monday"
//     },
//     {
//         name: En ? "Tuesday" : "الثلاثاء",
//         id: "Tuesday"
//     },
//     {
//         name: En ? "Wednesday" : "الاربعاء",
//         id: "Wednesday"
//     },
//     {
//         name: En ? "Thursday" : "الخميس",
//         id: "Thursday"
//     },
//     {
//         name: En ? "Friday" : "الجمعة",
//         id: "Friday"
//     },
// ]

// export const Duration = [
//     {
//         name: En ? "Morning" : "صباحا",
//         id: "AM"
//     },
//     {
//         name: En ? "Evening" : "مساءا",
//         id: "PM"
//     },
// ]

// export const Intervals = [
//     {
//         name: En ? "1 Hour" : "ساعة",
//         id: "60"
//     },
//     {
//         name: En ? "1.5 Hour" : "ساعة ونصف",
//         id: "90"
//     },
//     {
//         name: En ? "2 Hours" : "ساعتين",
//         id: "120"
//     },
// ]

// export const Gender = [
//     {
//         name: En ? "Male" : "ذكر",
//         id: "male"
//     },
//     {
//         name: En ? "Female" : "انثي",
//         id: "female"
//     },
// ]

// export const groupBy = (objectArray, property) => {
//     return objectArray.reduce(function (acc, obj) {
//       var key = obj[property];
//       if (!acc[key]) {
//         acc[key] = [];
//       }
//       acc[key].push(obj);
//       return acc;
//     }, {});
// }

// const ConvertToArabicNumbers = (num) => {
//     const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
//    return new String(num).replace(/[0123456789]/g, (d)=>{return arabicNumbers[d]});
// }

// export const convertTimeFormate = (incomingTime) => {
//     const time = incomingTime;
//     const timeArr = time?.split(" ");
//     if(timeArr !== undefined){
//         var formatedTime;
//         if(!En){
//             if(timeArr[1] === 'AM'){
//                 formatedTime = `${ConvertToArabicNumbers(timeArr[0])} ص`
//             }else{
//                 formatedTime = `${ConvertToArabicNumbers(timeArr[0])} م`
//             }
//         }else{
//             formatedTime = time.slice(0, 8);
//         }
//         return formatedTime;
//     }
// }

// export const convertDayLocale = (day) => {
//     var Day;
//     if(!En){
//         if(day === 'Saturday') Day = 'السبت'
//         if(day === 'Sunday') Day = 'الأحد'
//         if(day === 'Monday') Day = 'الاثنين'
//         if(day === 'Tuesday') Day = 'الثلاثاء'
//         if(day === 'Wednesday') Day = 'الاربعاء'
//         if(day === 'Thursday') Day = 'الخميس'
//         if(day === 'Friday') Day = 'الجمعة'
//     }else{
//         Day = day;
//     }
//     return Day
// }

export function isEmptyObject(obj) {
    return JSON.stringify(obj) === '{}'
}