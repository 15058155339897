import {
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Avatar,
  Typography,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { icons, images } from "../../../Assets/AssetHelper";
import SearchSelect from "../../../Components/Atoms/Select/SearchSelect";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { withTranslation } from "react-i18next";

const ChooseAccountTemplate = ({
  t,
  users,
  userId,
  setUsers,
  keyword,
  setKeyWord,
  handleListItemClick,
  handelLogin,
}) => {
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item md={6} sx={{ display: { xs: "none", md: "inherit" } }}>
        <img src={images.LoginBg} alt="Login" width="100%" height="100%" />
      </Grid>
      <Stack width={{ xs: "100%", md: "50%" }} alignItems="center">
        <img
          src={icons.BackArrow}
          alt="Login"
          width="10px"
          style={{
            alignSelf: "flex-start",
            padding: "30px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/login")}
        />
        <Stack
          alignItems="center"
          justifyContent="center"
          gap={2}
          mt={20}
          height="350px"
        >
          <img src={icons.LogoLogin} alt="Login" width="50%" height="100%" />
          <Typography color="#272726" fontSize="22px">
            {t("General.welcome_super_admin")}
          </Typography>
          <Typography color="#272726" fontSize="32px" fontWeight="bold">
            {t("General.login_account_as")}
          </Typography>
          <Box width={{ xs: "90%", md: "50%" }}>
            <SearchSelect
              items={users}
              className="login-input"
              value={userId}
              onchange={(e) => {}}
              label="Select"
              IconComponent={(props) => <ExpandMoreOutlinedIcon {...props} />}
              onclick={(id) => handleListItemClick(id)}
            />
            <PrimaryButton
              type="submit"
              text={t("Login.login")}
              classBtn='orange'
              style={{
                marginTop: "32px",
              }}
              click={handelLogin}
              sx={{
                fontSize: {
                  xs: "20px",
                  md: "24px",
                },
                borderRadius: {
                  xs: "100px",
                  md: "10px",
                },
                height: {
                  xs: "60px",
                  md: "72px",
                },
                width: {
                  xs: "100%",
                  md: "100%",
                }
              }}
            />
          </Box>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default withTranslation()(ChooseAccountTemplate);
