import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DiveCExpertDetailsAPI } from "../../../Contexts/APIs/Dive-Experts/ExpertDetails";
import { ListDiveActivitiesAPI } from "../../../Contexts/APIs/Dive-Activities/ListDiveActivities";
import { ReviewsListAPI } from "../../../Contexts/APIs/Reviews/ReviewsListing";
import { toast } from "react-toastify";
import ExpertDetailsTemplate from "../../../Templates/Main/DiveExperts/ExpertDetails.Template";
import Loader from "../../../Components/Atoms/Loader/Loader";
const ExpertDetailsPage = ({ t, getFavorites }) => {
  const { id } = useParams();
  const [expertDetails, setExpertDetails] = useState({});
  const [diveTrips, setDiveTrips] = useState([]);
  const [courses, setCourses] = useState([]);
  const [liveaboards, setLiveaboards] = useState([]);
  const [reviews, setReviews] = useState({});
  const [loader, setLoader] = useState(false);

  const getExpertData = async () => {
    setLoader(true);
    const { res, err } = await DiveCExpertDetailsAPI(id);

    if (res) {
      setExpertDetails(res?.data?.user);
      setLoader(false);
    }
    if (err) {
      setLoader(false);
      toast.error(err);
    }
  };

  const diveActivities = async (filter) => {
    const { res, err } = await ListDiveActivitiesAPI(filter);
    if (res) return res?.data?.activities;
    if (err) toast.error(err);
  };

  const getDiveTrips = async () => {
    const res = await diveActivities({
      activity_type: "dive_trip",
      dive_expert_id: id,
    });
    setDiveTrips(res);
  };

  const getCourses = async () => {
    const res = await diveActivities({
      activity_type: "dive_course",
      dive_expert_id: id,
    });
    setCourses(res);
  };
  

  const getLiveaboards = async () => {
    const res = await diveActivities({
      activity_type: "live_aboard",
      dive_expert_id: id,
    });
    setLiveaboards(res);
  };

  const getReviews = async () => {
    const { res, err } = await ReviewsListAPI(
      {},
      {
        review: {
          reviewee_id: id,
          reviewee_type: "DiveExpert",
          reviewer_type: "Customer",
        },
      }
    );
    if (res) setReviews(res?.data);
    if (err) toast.error(err);
  };

  useEffect(() => {
    getExpertData();
    getDiveTrips();
    getCourses();
    getLiveaboards();
    getReviews();
    getLiveaboards()
  }, []);

  return (
    <>
      <Loader open={loader} />
      {!loader && (
        <ExpertDetailsTemplate
          t={t}
          expertDetails={expertDetails}
          diveTrips={diveTrips}
          courses={courses}
          liveaboards={liveaboards}
          reviews={reviews}
          getFavorites={getFavorites}
        />
      )}
    </>
  );
};

export default ExpertDetailsPage;
