import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import LoginTemplate from "../../Templates/Auth/Login.Template.jsx";
import { SignInAPI } from "../../Contexts/APIs/Auth/Signin";
import { Firebase } from "../../Contexts/Firebase/Firebase.js";
import { useEffect } from "react";
import { checkToken } from "../../Contexts/APIs/Auth/checkToken.js";
import Cookies from "js-cookie";

const LoginPage = ({ t, setIsAuth }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  // useEffect(() => {
  //   Firebase.getFCMToken();
  // }, []);
  const [resAccounts, setResAccounts] = useState([]);

  const LoginApi = async (data) => {
    console.log("fired");
    await Firebase.SignoutFirebase();
    const date = new Date();
    date.setTime(date.getTime() + 1 * 60 * 60 * 1000); // Multiply by milliseconds in a day
    let expires = date;
    const { res, err } = await SignInAPI(data);
    if (res) {
      // setResAccounts(res);
      let array = [];
      res.map((obj) => {
        const account = {
          token: obj.extra.access_token,
          userId: obj.user.id,
          avatar: obj.user.avatar,
          user_type: obj.user.user_type,
          email: obj.user.email,
          firstname: obj.user.firstname,
          lastname: obj.user.lastname,
          is_verified: obj.user.is_verified,
          firebase_auth_token: obj?.user?.firebase_auth_token,
          firebase_node_path: obj?.user?.firebase_node_path,
        };

        setResAccounts((prev) => [
          ...prev,
          {
            token: obj.extra.access_token,
            userId: obj.user.id,
            avatar: obj.user.avatar,
            user_type: obj.user.user_type,
            email: obj.user.email,
            firstname: obj.user.firstname,
            lastname: obj.user.lastname,
            is_verified: obj.user.is_verified,
            firebase_auth_token: obj?.user?.firebase_auth_token,
            firebase_node_path: obj?.user?.firebase_node_path,
          },
        ]);

        array.push(account);
      });
      if (localStorage.getItem("Accounts") === null) {
        localStorage.setItem("Accounts", JSON.stringify(array));
      } else {
        const accounts = JSON.parse(localStorage.getItem("Accounts"));
        // accounts.push(...array);

        // if condition to prevent dublicate
        array.forEach((item) => {
          if (accounts.filter((accountItem) => accountItem.userId === item.userId).length == 0) {
            accounts.push(item);
          }
        });

        localStorage.setItem("Accounts", JSON.stringify(accounts));
      }
      if (res[0]?.user?.user_type == "customer" || res[0]?.user?.user_type == "dive_club") {
        if (array.length === 1) {
          localStorage.removeItem("GoogleAccounts");
          setIsAuth(true);
          navigate("../", { replace: true });
        } else {
          localStorage.removeItem("GoogleAccounts");
          setStep(2);
        }
        localStorage.setItem("ActiveToken", res[0]?.extra?.access_token);
        localStorage.setItem("ActiveUserId", res[0]?.user?.id);
        localStorage.setItem("ActiveAvatar", res[0]?.user?.avatar);
        localStorage.setItem("ActiveUserType", res[0]?.user?.user_type);
        localStorage.setItem("ActiveEmail", res[0]?.user?.email);
        localStorage.setItem("ISVERIFIED", res[0]?.user?.is_verified);
        localStorage.setItem("firebase_auth_token", res[0]?.user?.firebase_auth_token);
        // console.log(res[0]?.user?.firebase_auth_token, "firebase_auth_token");
        localStorage.setItem("firebase_node_path", res[0]?.user?.firebase_node_path);
        if (!localStorage.getItem("fcm_token")) localStorage.setItem("fcm_token", window.navigator.userAgent);
        localStorage.setItem("USER_TYPE", "user");
      } else if (res[0]?.user?.user_type == "super_admin") {
        localStorage.setItem("ActiveToken", res[0]?.extra?.access_token);
        navigate("/choose-account-type", { replace: true });
      }

      // setTimeout( async () => {
      //   await checkToken().then((result) => {
      //     // console.log(result)
      //   }).catch((error) => {
      //     // console.log(error)
      //     window.location.replace("/login")
      //   })
      // }, 500);
    }
    if (err) toast.error(err);
  };

  // const LoginApi = async (data) => {
  //   // if (localStorage.getItem("ActiveToken")) {
  //     await checkToken().then((result) => {
  //       HandleLoginApi(data)
  //       }).catch((error) => {
  //         window.location.replace("/login")
  //       })
  //   // }
  // }

  return (
    <LoginTemplate t={t} LoginApi={LoginApi} step={step} setStep={setStep} setIsAuth={setIsAuth} resAccounts={resAccounts} />
  );
};

export default LoginPage;
